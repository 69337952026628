import React from "react";

import Typography from '@material-ui/core/Typography';
// import {ROOT_URL} from '../../../configs';
import { useHistory } from "react-router-dom";
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

export default function TermsAndConditions(params){

  const history = useHistory();

  return (
    <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <IconButton onClick={()=>history.goBack()} aria-label="back">
              <KeyboardBackspaceIcon style={{marginRight: 5}} />
            </IconButton>
            <Typography className="ml-2 mb-2" color="text-secondary" variant="h6">
            <b>Terms & Conditions</b>
            </Typography>
          </div>
        </div>

        
        <div className="row" style={{backgroundColor: '#dedede'}}>
          <div className="col-12">
          <br />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="mt-3 p-1 bg-white">
              <div style={{padding: 5, textAlign: 'justify'}}>
                <Typography variant="body1">
                  This website is owned and managed by PURE EATS. By accessing and using the
                  “www.pureeats.in” web site, you are legally bound by these Terms & conditions. The
                  terms “you” and “User” refer to anyone who accesses the web site.<br /><br />
                  As you browse through the website (www.pureeats.in) and PURE EATS sites you may
                  access other web sites that are subject to different terms of use. When you use those
                  sites, you will be legally bound by the specific terms of use posted on such sites. If there
                  is a conflict between these Terms & Conditions and the other terms and condition, the
                  other terms & conditions will govern with respect to use of such pages.<br /><br />
                  PURE EATS may change these Terms & Conditions at any time without giving any
                  notice. Changes will be posted on the website under “Terms & Condition”.<br /><br />
                  Your use of the web Site after any changes have been posted will constitute your
                  agreement to the modified Terms & Condition and all of the changes. Therefore, you
                  should read these Terms & Condition from time & time for changes.<br /><br />
                  
                  <b>Contractual Relationship</b><br /><br />

                  Please read these terms and conditions carefully. These terms and conditions constitute a
                  legally binding Agreement (together with the Enrollment Agreement, the “Agreement”) between
                  you whether personally or on behalf of an entity acting as a food service provider (the “Restaurant”
                  or “you”) and Pure Eats Co. (a company with its registered office at <b>Flat no 307 SAPUIPARA, near
                  Mota shiv Mandir ( Rakhi Factory) Bally, Howrah , Pin: -711227. State: -West Bengal,</b><br /><br />
                  . Email: - <a href="mail:help@pureeats.in">help@pureeats.in</a> Customer support number - 18001238508., India.<br /><br />
                  This Agreement govern your use of the Pure Eats application, website, call center and
                  technology platform which facilitates the listing, sale and delivery of Goods (as defined below)
                  to Customers (as defined below) (“Platform Services: - Pure Eats”).<br /><br />

                  IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS
                  AGREEMENT, YOU MAY NOT USE OR ACCESS THE PURE EATS WEBSITE OR APPS OR THE
                  RESTAURANT APPLICATION.<br /><br />

                  Your access and use of the Pure Eats and the Restaurant Application constitute your
                  agreement to be bound by this Agreement, which establishes a contractual relationship
                  between you and Pure Eats. PURE EATS may immediately terminate this Agreement with
                  respect to you, or generally case offering or deny access to the Pure Eats and the Restaurant
                  Application or any portion there of, at any time without reason and without any notice.
                  Pure Eats may amend this Agreement from time to time. Amendments will be effective upon.
                  Pure Eats posting of an updated Agreement at this location. Your continued access or use of
                  the Pure Eats and/or the Restaurant Application after such posting constitutes your consent
                  to be bound by this Agreement, as amended.<br /><br />

                  Pure Eats may provide to a claims processor or an insurer any necessary information
                  (including your contact information) if there is a complaint, dispute or conflict, which may
                  include a death or injury, involving you, a Customer and/or a third-party transport provider
                  and such information or data is relevant to the complaint, dispute or conflict.<br /><br /><br />

                  <b>REGISTRATION</b><br /><br />

                  As part of the registration process, you must select a username and password and
                  provide by website with accurate, complete and updated information.
                  Failure to do so constitutes a breach of this agreement, which may result in immediate
                  termination of your access.<br /><br />

                  <b>DEFINATION</b><br /><br />

                   “Applicable Law” means any national, state, local, municipal legislation, regulation,
                  statutes, by law, consents and/or other law and any other instrument having the force of
                  law as may be issued and in force from time to time (and any amendment or subordinate
                  provisions) relating to or connected with the activities contemplated under this
                  Agreement wherever so located or<br />
                  performed;<br />
                  “Captain” means an individual, validly registered on the PURE EATS , who is an
                  independent contractor and is carrying out the delivery of Orders to Customers;
                  “Commission Percentage” means the commission fee percentage with respect to each
                  Order calculated on a Net Sales basis payable by you to PURE EATS to the terms of the
                  Enrollment Agreement;<br />
                  “Customer” means a user who place Orders through the PURE EATS ;
                  “Device” means an internet enabled mobile or tablet device capable of accessing the
                  Restaurant Application;<br />
                  “Effective Date” means the execution date of the Enrollment Agreement;
                  “Electronic Payment Mechanism” means the online and mobile based payment
                  mechanisms including third party payment gateways, credit card/debit card/net banking
                  transfer and/or e-wallets and/ or PUR EATS credits that are available on the Platform for
                  the purposes of facilitating the payment of the Order Value by the Customer;
                  “Enrollment Agreement” means the Restaurant Enrollment Agreement signed by you and
                  PURE EATS by which you agree to be bound by the terms of this Agreement;
                  “Good Industry Practice” means the practices, methods and procedures and that degree of
                  skill, diligence, prudence, foresight and judgment which would be expected to be observed
                  by a skilled and experienced supplier engaged in the same or similar activities under the
                  same or similar circumstances;<br />
                  “Goods” means any food and beverages items being sold by you to Customers as listed
                  from time to time in a relevant Menu;<br />
                  “Group” means in relation to any person, that person and any company which is from time
                  to time a subsidiary or holding company of that person or a subsidiary of any such holding
                  company;<br />
                  “Information” means the information set out in, and to be provided in connection with, the
                  Enrollment Agreement including any information which is supplied by you to PURE EATS
                  under or with respect to this Agreement such as the Menu, the price lists underlying the
                  Menu, opening hours of the Restaurant, delivery areas serviced by the Restaurant and
                  related delivery terms, and any other specific information the Restaurant is requested by
                  PURE EATS to provide;<br />
                  “Menu” means any e-document or virtual page which sets out the Goods offered for sale by
                  you to Customers together with related pricing and which is made available on the PURE
                  EATS ;<br />
                  “Net Order Value” means the Order Value of a relevant Order, less the applicable
                  

                  Commission Percentage, any applicable payment gateway fees and any other taxes or
                  charges due under this Agreement or the Enrollment Agreement;
                  “Net Sales” means the net amount charged by the Restaurant to any Customer that is
                  attributable to an Order placed through the PURE EATS , excluding any GST tax payable on
                  the Goods and all discounts being offered by the Restaurant on the PURE EATS ;
                  “Order” means an order made by a Customer on the PURE EATS for the purchase of
                  Goods;<br />
                  “Order Value” means the total amount, which is payable by a Customer for the purchase of
                  Goods with respect to each relevant Order;<br />
                  “Parties” means PURE EATS and you;<br />
                  “Restaurant Application” means the PURE EATS application (web-based or otherwise)
                  which provides you with the ability to receive details and updates relating to Orders;<br />
                  “Service Operator” means a service operator working for PURE EATS who is managing
                  Orders received from Customers through the PURE EATS ;<br />
                  “Services” means the receipt of Orders from, and delivery of related Goods to, Customers
                  and related services; and<br />
                  “Technical Standards” means all internationally recognizable technical standards
                  applicable to the provision of the Services here under.<br /><br />

                  <b>PURE EATS Obligations</b>

                  * Pure Eats will display Information and your Menu on the Pure Eats at its description;<br /><br />
                   * Pure Eats will forward Order placed by Customers to you via a Pure eats provided URL
                  link and/or via the Restaurant Application on a real-time basis;<br /><br />
                   * Pure Eats will not be responsible or liable for the (a) quality, quantity, descriptive accuracy
                  and/or taste of the Goods advertised on the Platform; and/or (b) processing of the Orders
                  which have been placed by the Customers with the Restaurant on the Pure Eats ; and/or (c)
                  any failure on the part of the Restaurant to comply with the special requests / instructions of
                  a Customer where such requests and/or instructions have been communicated by the
                  Customer at the time of placing an Order;<br /><br />
                   * Pure Eats will use Captains to deliver Orders to Customers;<br /><br />
                   * Pure Eats will handle any Customer and Restaurant complaint related to the Pure Eats ,
                  the Restaurant Application and/or the Device (in the event Pure Eats has provided
                  Restaurant with that Device);<br /><br />
                  * Pure Eats will deactivate the Restaurant’s account if the Restaurant is found noncompliant with any Applicable Law;<br /><br />
                  
                  * Pure Eats will be responsible for the costs of any discounted promotions offered by Pure
                  Eats with respect to Platform Services.<br /><br />

                   <b>RESTAURANT OBLIGATION</b><br /><br />

                  * Restaurant shall treat Orders received from Customer ordering via the Pre Eats with the same
                  level of care, prep times, and attention, including providing the correct food items as per the
                  Customer order and providing the right packaging to avoid spillage or destruction of food items,
                  as it treats Customer orders received via its own sales channels or via any third party;<br /><br />
                  * Restaurant will ensure that the Information provided to Pure Eats is up to date and accurate at
                  all times ;<br /><br />
                  * Restaurant will use the Restaurant Application to make timely changes or updates to their
                  Information, including but not limited to Menu items, Menu prices, operating hours and any other
                  Information. If these changes cannot be made via the Restaurant Application, Restaurant will
                  notify Pure Eats by email of any changes and will provide 24 working hours’ notice for Pure Eats
                  to reflect such changes on the Pure Eats . If Restaurant fails to do so, Restaurant shall pay to Pure
                  Eats the Commission Percentage on any orders placed by Customers that could not be processed
                  due to outdated Restaurant Information<br /><br />
                   The Restaurant will not require a minimum value with respect to any Order;
                  * Restaurant will accept all Orders placed by Customers within two minutes of the Order
                  appearing on the Restaurant Application and, in the event that the Restaurant fails to do the same,
                  it will be liable to pay to Pure Eats the Commission Percentage on any Orders not accepted by the
                  Restaurant;<br /><br />
                  * If Customer contacts Restaurant to request any change or modification to an Order, Restaurant
                  will direct Customer to contact Pure Eats directly;<br /><br />
                  * The Restaurant warrants that the Goods provided to Customers are: (a) of high quality and fit
                  for human consumption; and (b) comply with all relevant local legislations, including all
                  applicable food industry regulations including all packaging and labeling requirements required
                  for Goods of that type;<br /><br />
                  * The Restaurant will contact the Pure Eats customer care if an Order cannot be processed as
                  requested or to clarify the details of an Order, if required, post confirmation of the Order;<br /><br />
                  * Restaurant will ensure that the preparation time of an Order will not exceed 40 minutes from
                  the acceptance time of the relevant Order;<br /><br />
                  * Restaurant will be responsible for any and all issues and costs associated with delayed delivery
                  resulting from its failure to comply with such 40-minute preparation time period including costs
                  associated with compensating the Customer;<br /><br />
                  * Restaurant will not engage in any fraudulent activity or misuse any benefits extended by Pure
                  Eats to it or to Customers;<br /><br />
                 

                  * Restaurant will be responsible for the costs of any discounted promotion offered by the
                  Restaurant with respect to Goods (a “Restaurant Promotion”), as agreed from time to time via
                  email;<br /><br />
                  * Restaurant will not at any time offer by way of any medium a price for any food and beverage
                  item which is lower than the then-current price of the applicable item set out in the Menu;<br /><br />
                  * If the Restaurant has not complied with the preparation instructions (as set forth in an Order)
                  or has supplied poor quality or inaccurate Goods to the Customer in the opinion of Pure Eats ,
                  whereby Pure Eats has (pursuant to the Customer’s complaint) refunded the Order Value to the
                  Customer (“Problem Order”), the Restaurant acknowledges and agrees that the Restaurant will
                  not be paid for such Problem Order and that if the Restaurant has already received the Order
                  Value from Pure Eats in respect of such Problem Order (subject to any appropriate reductions
                  hereunder), Pure Eats will have a right to deduct or offset such amount from or against any
                  monies that are owed by Pure Eats to the Restaurant from time to time under this Agreement.<br /><br />
                  * You shall disclose all relevant details pertaining to Problem Order(s) to Pure Eats upon
                  becoming aware of the same.<br /><br />
                  * Restaurant will ensure that prices for Goods displayed on a Menu are at all times inclusive of
                  applicable taxes and charges, including GST ; and<br /><br />
                  * Restaurant shall perform its obligations hereunder in accordance with: (a) Good Industry
                  Practice; (b) Technical Standards; (c) All applicable professional rules, code of conduct,
                  regulations and associated guidelines; (d) Any timescales set out herein; and (e) Applicable Law.
                  The Pure Eats is a digital delivery and transportation network which operates a software platform
                  matching, amongst other things, Customers seeking to place Orders with Restaurants via the Pure
                  Eats .<br /><br />
                  THE RESTAURANT ACKNOWLEDGES THAT NEITHER PURE EATS NOR ITS AFFILIATES PROVIDE DELIVERY
                  OR TRANSPORTATION OR FOOD SERVICES OR FUNCTION AS A TRANSPORTATION SERVICE PROVIDER OR
                  OWN ANY VEHICLES FOR DELIVERY AND THAT ALL DELIVERY SERVICES ARE PROVIDED BY INDEPENDENT
                  THIRD-PARTY CONTRACTORS WHO ARE NOT EMPLOYED BY PURE EATS OR ANY OF ITS AFFILIATES.<br /><br />

                  <b>LICENSE</b><br /><br />

                  Subject to your compliance with this Agreement, Pure Eats grants you a limited, non-exclusive, nonsub-licensable, revocable, non-transferrable license to: (a) access and use the Pure Eats and the
                  Restaurant Application solely with respect to the Services; and (b) access and use any content,
                  information and related materials that may be made available to the Restaurant through the Pure
                  Eats , in each case solely to provide Services to Customers. Any rights not expressly granted herein
                  are reserved by Pure Eats and Pure Eats licensors.<br /><br />
                  <b>You may not:</b><br /><br />
                  (i) remove any copyright, trademark or other proprietary notices from any portion of the
                  Pure Eats or the Restaurant Application;<br /><br />
                  (ii) reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell,
                  resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or
                  otherwise exploit the Pure Eats or the Restaurant Application except as expressly
                  permitted by Pure Eats;<br /><br />
                  (iii) decompile, reverse engineer or disassemble the Pure Eats or the Restaurant Application
                  except as may be permitted by Applicable Law;<br /><br />
                  (iv) link to, mirror or frame any portion of the Pure Eats or of the Restaurant Application;<br /><br />
                  (v) cause or launch any programs or scripts for the purpose of scraping, indexing, surveying,
                  or otherwise data mining any portion of the Pure Eats or of the Restaurant Application or
                  unduly burdening or hindering the operation and/or functionality of any aspect of the
                  Pure Eats or the Restaurant Application; or<br /><br />
                  (vi) attempt to gain unauthorized access to or impair any aspect of the Pure Eats or the
                  Restaurant Application or their related systems or networks.<br /><br />

                  <b>OWNERSHIP</b><br /><br />


                  The Pure Eats and the Restaurant Application and all rights therein are and shall remain Pure
                  Eats property or the property of Pure Eats licensors. Neither this Agreement nor your use of
                  the Pure Eats or of the Restaurant Application convey or grant to you any rights: (i) in or
                  related to the Pure Eats or the Restaurant Application, as applicable, except for the limited
                  license granted above; or (b) to use or reference in any manner Pure Eats names,logos,
                  product and service names, trademarks or services marks or those of Pure Eats licensors<br /><br />

                  <b>THIRD PARTY SERVICES & CONTENT</b><br /><br />


                  The Pure Eats and the Restaurant Application may be made available or accessed in
                  connection with third party services and content (including advertising) that Pure Eats does
                  not control. You acknowledge that different terms of use and privacy policies may apply to
                  your use of such third-party services and content. Pure Eats does not endorse such thirdparty services and content and in no event shall Pure Eats be responsible or liable for any
                  products or services of such third-party providers. Additionally, Apple Inc., Google, Inc.,
                  and/or their applicable international subsidiaries and affiliates will be third-party
                  

                  beneficiaries to this contract if you access the Pure Eats using applications developed for
                  Apple iOS or Android-powered mobile devices, respectively. These third-party beneficiaries
                  are not parties to this contract and are not responsible for the provision or support of the
                  Pure Eats in any manner. Your access to the Pure Eats using these services or applications is
                  subject to terms set forth in the applicable third-party beneficiary’s terms of service.<br /><br />

                  <b>EXCLUSIVITY</b><br /><br />

                  <b>Pursuant to the terms of the Enrollment Agreement, you may opt to work exclusively
                  with Pure Eats as your sole online ordering, take-away and/or delivery service
                  provider (a “Pure Eats Exclusive Partner”).</b><br /><br />

                  <b>USE OF PURE EATS</b><br /><br />

                  1. The Restaurant’s use of the Pure Eats and the Restaurant Application shall be subject to the
                  terms and conditions set forth in this Agreement and the Enrollment Agreement and the privacy
                  policy published from time to time on Pure Eats website.<br /><br />
                  2. In the event Pure Eats provides you with a Device, the following terms and conditions shall
                  apply:<br /><br />
                  3. The Device shall be used by the Restaurant solely for the purpose of<br />
                   (a) managing Orders;<br />
                   (b) reviewing the particulars underlying the Orders;<br />
                   (c) updating the Order status;<br />
                   (d) updating Restaurant Information;<br />
                   (e) communicating with Pure Eats;<br />
                   (f) reporting erroneous Orders;<br />
                   (g) reviewing Restaurant’s performance and financial accounts relating to its business with Pure
                   Eats and/or<br />
                  (h) any other use that may be prescribed in writing by Pure Eats in this<br /><br />

                  4. Pure Eats terms and conditions relating to Devices shall apply to any Device provided by Pure
                  Eats to the Restaurant;<br />
                  5. The Restaurant undertakes to use the Device in compliance with the instructions for use that
                  may be issued by Pure Eats in this regard;<br />
                  6. On the delivery of the Device by Pure Eats to the Restaurant, the Restaurant will be required to
                  acknowledge the receipt of the Device in writing by executing an Asset Handover Form in the
                  format annexed to the Agreement, and the cost of the Device will be charged to the Restaurant pursuant to the terms of the Enrollment Agreement;
                  7. Pure Eats grants a limited, non-exclusive, non-transferable, royalty free license to the
                  Restaurant for the use of the Device solely until the termination of this Agreement;<br />
                  8. The Restaurant undertakes to and shall use the Device only for the purposes prescribed under
                  this Agreement and that the Restaurant should not violate the intellectual property rights of Pure
                  Eats over and in respect of the Device at any point in time;<br />
                  9. The Restaurant undertakes to return the Device along with all accessories (in a full working
                  condition as determined by Pure Eats) to Pure Eats immediately upon the termination of this
                  Agreement or when requested by Pure Eats. The Device will be collected by Pure Eats from the
                  Restaurant at the sole expense of Pure Eats. Upon the return of the Device along with all
                  accessories by the Restaurant, Pure Eats and the Restaurant will be required to acknowledge the
                  transfer of the Device in writing as instructed by Pure Eats;<br />
                  10. The Restaurant acknowledges and agrees that any failure on part of the Restaurant in
                  returning the Device (whenever requisitioned by Pure Eats or on the termination of this
                  Agreement) will render the Restaurant liable to pay for such unreturned Device including for an
                  appropriate action under the Applicable Laws; and<br />
                  11.The Restaurant acknowledges and agrees that: <br />(I) Pure Eats is not the manufacturer of any
                  Device provided <br />(ii) Pure Eats cannot provide any warranties or guarantees with respect to the
                  quality or durability of the Device and <br />(iii) it uses the Device at its own risk.<br />
                  12. In the event that the Device ceases to function and the Restaurant is unable to access the
                  Restaurant Application through any other means, the Restaurant will immediately inform Pure
                  Eats and Pure Eats will disable the Restaurant on the Pure Eats until the Device is functional again.<br /><br />

                  <b>CUSTOMER IN ADVANCE</b><br /><br />

                  1. The Parties acknowledge and agree that the accumulated average delivery rating is
                  entirely under the control of the Customers and that it is calculated using the ratings which
                  are given by the Customer in respect of each Order which is placed by such Customer with a
                  particular Restaurant on the Pure Eats .<br /><br />
                  2.The Restaurant acknowledges and agrees that Pure Eats reserves the right to review and
                  modify the foregoing delivery rating system and the Commission Percentage periodically
                  upon sending an email notice to the Restaurant and that Pure Eats decision in this regard
                  shall be final and binding on the Restaurant.<br /><br />


                  <b>RESTRICTED ACTIVITIES</b><br /><br />
                  

                  1.The Restaurant must meet all of the following requirements when it receives an online payment
                  Order:<br /><br />
                  A.Ensure that it does not receive any additional payment from a Customer (including but not
                  limited to payment by cash) when payment has been made online by a Customer; and
                  Follow all special instructions contained on the Order receipt or as communicated by Service
                  Operator.<br /><br />
                  C.The Restaurant agrees that it will treat all information concerning this Agreement and the
                  Enrollment Agreement (including their respective terms), Pure Eats and its Group, the Pure Eats
                  and all information concerning Customers which has been provided to
                  it pursuant to the terms of this Agreement as confidential information (“Confidential
                  Information”) and will not, except as provided in this Agreement, disclose, use or permit the
                  disclosure or use of such information to any third party. The Restaurant further agrees that a
                  breach of this provision would cause Pure Eats to suffer irreparable harm and damage that could
                  not be adequately remedied by payment of monetary damages and therefore, in addition to
                  monetary damages and other legal remedies, the Restaurant agrees that Pure Eats will be entitled
                  to obtain equitable and injunctive relief as may be necessary to restrain any threatened,
                  continuing or further breach by the Restaurant. The restrictions referred to in this Clause will not
                  apply to any Confidential Information to the extent that such information:<br /><br />
                   (i) is already known to the Restaurant,<br />
                  (ii) is in or comes into the public domain otherwise than as a result of any breach of this
                  Agreement or<br />
                  (iii) is required to be disclosed by law.<br /><br />
                  D. Restaurant shall Process any Personal Information received from Pure Eats or any Customer
                  in accordance with Applicable Law and only for the purposes of discharging its obligations under
                  this Agreement. For the purposes of this Clause, the following definitions shall apply: (i) “Personal
                  Information” means any information relating to an identified or identifiable person who can be
                  identified, directly or indirectly, in particular by reference to an identification number or to one or
                  more factors specific to his physical, physiological, mental, economic, cultural or social identity,
                  and (ii) “Process” means to collect, hold, use, transfer, destroy and otherwise deal in any with
                  Personal Information.<br /><br />

                  <b>TERMS OF USE FOR CUSTOMER</b><br /><br />


                  1.The User shall request to order food and beverages from a Restaurant Partner by clicking on
                  ‘Place Order’ tab on the Restaurant Partner page on the FDP shall constitute an unconditional and
                  irrevocable authorization issued in favor of FDP to place online orders for food and beverages
                  against the Restaurant Partners on User’s behalf .An order, once placed on behalf of User by the FDP
                  and accepted by a Restaurant Partner constitutes a separate contract of sale of goods under the Sale
                  of Goods Act,1930 and the Consumer Protection Act, 2019 or any successor legislation between the
                  Restaurant Partner and the User, to which the Food Delivery Platform shall not be a party.<br /><br />
                  2. The User must warrant that while placing an order the details like contact number, delivery
                  address, etc. are accurate and correct. By providing these details, the User expresses his/her
                  acceptance of Pure Eats terms and conditions and privacy policies.<br /><br />
                  3.The User shall not resell food purchased via Pure Eats.<br /><br />
                  4.The User shall be charged a delivery fee for delivery of the order by the Delivery Partner or
                  Restaurant Partner as the Delivery Partner or Restaurant Partner may determine (“Delivery
                  Charges”). The User must agree that the Food Delivery Platform is authorized to collect, on behalf of
                  the Delivery Partner or Restaurant Partner, as the case may be. The Delivery Charges may vary from,
                  order to order, which may be determined on multiple factors such as order value, demand during
                  peak hours, etc.<br /><br />

                  <b>ACCOUNT REGISTRATION ON THE PLATFORM</b><br /><br />

                  1. One shall have the access to the Platform by registering to create an account for the(Food
                  Delivery Platform“) and become a member; or shall be able register to himself join by logging into
                  your account with certain third-party social networking sites (” SNS”) (including, but not limited
                  to, Facebook); each such account, a ” Third Party Account”, via our Platform.<br /><br />
                  2.The Membership is limited for the purpose and are subject to the terms, and strictly not
                  transferable. As part of the functionality of the Platform services, one shall be able to may link
                  Pure Eats Account with Third-Party Accounts, by either:<br /><br />
                  3.Providing your Third-Party Account login information to us through the Platform; or
                  4.Allowing us to access their Third-Party Account, as is permitted under the applicable terms
                  and conditions that govern your use of each Third-Party Account.<br /><br />
                  5.The user shall represent that you are entitled to disclose your Third Party Account login
                  information to us and/or grant the Pure Eats access to your Third-Party Account (including, but
                  not limited to, for use for the purposes described herein), without breach of any of the terms and
                  conditions that govern your use of the applicable Third-Party Account and without obligating
                  them to pay any fees or making us subject to any usage limitations imposed by such third-party
                  service providers.<br /><br />

                  6.By granting us access to any Third Party Accounts, the Food Delivery Platform shall be able to
                  access, make available and store (if applicable) any content or information that the user shall
                  provide to and stored in your Third-Party Account so that it is available on and through the
                  Platform via your Food Delivery Platform Account.<br /><br />
                  7. Depending on the Third-Party Accounts, the user shall choose, and subject to the privacy
                  settings that the user has set in such Third-Party Account shall be personally identifiable
                  information that you post to your Third-Party Accounts will be available on and through your
                  Food Delivery Platform Account on the Platform.<br /><br />
                  8.Every user shall have access to only one FDP account and shall not be permitted to create
                  multiple accounts. On finding multiple accounts of any user, the FDP reserves the right to suspend
                  such multiple accounts without being liable for any compensation.<br /><br />
                  9.Every user shall provide accurate, current and complete information during the registration
                  process and to update such information to keep it accurate, current and complete.
                  10.The Pure Eats shall reserve the right to suspend or terminate your Food Delivery Platform
                  Account and your access to the Services in the event;<br /><br />
                  (i) if any information provided during the registration process or thereafter proves to be
                  inaccurate, not current or incomplete;<br /><br />
                  (ii) if it is believed that a user’s actions may cause legal liability for the Food Delivery Platform or
                  the user; or for both.<br /><br />
                  (iii) if the user is found to be non- compliant with the Terms of Use.<br /><br />
                  11.Every user shall be responsible for safeguarding his/her password. The user shall not
                  disclose his/her password to any third party and it will be their sole responsibility for any
                  activities or actions under your Pure Eats Account, whether or not you have authorized such
                  activities or actions. Goods and services purchased from the Platform are intended for your
                  personal use and you represent that the same are not for resale or you are not acting as an agent
                  for other parties.<br /><br />

                  <b>LIMITATION OF LIABILITY</b><br /><br />

                  The Food Delivery Platform shall in no manner be liable in any way for any in-person
                  interactions with representatives or staff of the Restaurant Partners or for the member’s
                  experience at the Restaurant Partners. The Pure Eats in no manner is liable to the member if
                  any outlet of Restaurant Partners temporarily or permanently shuts down its operations. The
                  Pure Eats will only take care of claims/liabilities arising out of offers/membership plans
                  advertised by Food Delivery Platform solely.<br /><br />
                  1. Assignment: The Pure Eats may assign its right obligations under these terms and conditions
                  to any of its affiliates or any third party at any time.<br /><br />
                  2.Food hygiene audit of Restaurant Partners: The Pure Eats shall undertake to initiate food
                  hygiene auditing of Restaurant Partners in partner with certified auditors
                  You are entirely liable for activities conducted by you in connection with your browsing
                  and use of the web site. If you are dissatisfied with the content or the Web site or with
                  these terms of use your sole and exclusive remedy is to stop using the content and the
                  Web site. The website will not pay any damages in connection with your browsing or
                  use of the web.<br /><br />
                  Due to the number of sources from which the content is obtained and the potential
                  hazards of electronic distribution, there may be delays, omissions or inaccuracies in
                  such content and the website. The content and the web site are provided “AS IS”
                  without any warranties. Neither the website nor PURE EATS makes any guarantees or
                  warranties as to the accuracy completeness, timeliness or correctness of or results to be
                  obtained from accessing and using the website.<br />
                  Neither the website (www.pureeats.in) nor PURE EATS shall be liable to the users or
                  anyone else for any inaccuracy, delay, interruption in service, error or omission
                  regardless of cause or for any damages resulting there from. In no event will the
                  website, PURE EATS nor any their third party licensors be liable for any direct, indirect,
                  special or consequential damages including but not limited to, lost time, lost money, lost
                  profits or goodwill whether in contract, strict liability or otherwise and whether or not
                  such damages are foreseen or unforeseen with respect to any use of the websites.
                  Neither the website nor any of its affiliates agents or licensors will be liable to you or
                  anyone else for any loss or injury resulting from use of the website in whole or part,
                  whether caused by negligence, contingencies beyond its control in procuring, compiling
                  , interpreting, reporting or delivery the websites its affiliates agents or licensors be
                  liable to you or anyone else for any decision made or action taken by you in reliance on
                  such content or the websites. The website assumes no responsibility for use of thirdparty software on the website and shall have no liability whatsoever to any person or
                  entity for accuracy or completeness of any outcome generated by such software. <br /><br />

                  <b>DISCLAIMER OF WARRANTIES, LIMITATION
                  OF LIABILITY & INDEMNIFICATION</b><br /><br />

                  <b>Disclaimer of warranties</b><br /><br />

                   The User must acknowledge and agree that the Services are provided “as is” and “as available”
                  and that the use of the Services shall be at the risk of User. To the fullest extent permitted by
                  applicable law, the Food Delivery Platform, its affiliates and their respective officers, directors,
                  employees, agents, affiliates, branches, subsidiaries and licensor (“Pure Eats Parties”) disclaims
                  warranties, express or implied, in connection with the Services including mobile apps and
                  User’s use of them. To the fullest extent permitted by applicable law, the Food Delivery Platform
                  parties makes no warranties or representations that the Services have been or will be provided
                  with due skill, care and diligence or about the accuracy or completeness of the Services content
                  and assume no responsibility for any<br /><br />
                  (i) errors, mistakes, or inaccuracies of Content,<br />
                  (ii) personal injury of property damage, of any nature, whatsoever, resulting from User’s
                  access to and use of the services,<br />
                  (iii) any unauthorized access to or use of FDP’s server and/or any and all personal
                  information stored therein,<br />
                  (iv) any interruption or cessation of transmission to or from the Services,<br />
                  (v) any bugs, viruses, Trojan Horses, or the like which may be transmitted to or through
                  the Services, through the action of an third party,<br />
                  (vi) any loss of User’s data or Content from the Services and/or,<br />
                  (vii) an errors or omissions in any content or for any loss or damage of any kind incurred
                  as a result of the use of any content posted, emailed, transmitted or otherwise made
                  available via the Services.<br />
                  ⮚ Any material downloaded or otherwise obtained through the use of the Services is done
                  at User’s own discretion and risk and the User will be solely responsible for any damages to
                  User’s computer system or other device or loss of data that results from the download of any
                  such materials. The FDP Parties will not be a party to or in any way be responsible for
                  monitoring any transaction between the User and third-party providers of products or
                  services.<br /><br />
                  To the fullest extent permitted by applicable law, in no event shall the Food Delivery Platform
                  be liable to User for any damages resulting from aforementioned points no. (i) to (vii) including
                  but not limited to liability arising out of the (i) disclosure of information pursuant to these
                  terms or Pure Eats Privacy Policy; (ii) if the User fails to keep his/her password or account
                  details secure and confidential, and/or (iii) loss or damage which may be incurred by the User,
                  including but not limited to loss or damage as a result of reliance placed by the User on the
                  completeness, accuracy or existence of any advertising, or a result of any relationship or
                  transaction between the User and any advertiser or sponsor whose advertising appears on the
                  Services, and/or delay or failure in performance resulting from causes beyond Pure Eats
                  reasonable control. In no event shall the Pure Eats Parties be liable to the User for indirect,
                  incidental, special, punitive, exemplary or consequential damages whatsoever, however, caused
                  and under any theory of liability, including but not limited to, any loss of profit (whether
                  incurred directly or indirectly), any loss of goodwill or business reputation, any loss of data
                  suffered, cost of procurement of substitute goods or services, or other intangible loss.<br /><br />

                  THE RESTAURANT UNDERSTANDS AND ACCEPTS THAT PURE EATS DOES NOT PROVIDE
                  TRANSPORTATION, FOOD DELIVERY OR LOGISTICS SERVICES OR FUNCTION AS A TRANSPORTATION
                  OR GOODS CARRIER AND THAT ALL SUCH TRANSPORTATION, FOOD DELIVERY OR LOGISTICS SERVICES
                  ARE PROVIDED BY INDEPENDENT THIRD-PARTY CONTRACTORS WHO ARE NOT EMPLOYED BY
                  CAREEM OR ANY OF ITS AFFILIATES. PURE EATS LIABILITY FOR ANY LOSS SUFFERED BY THE
                  RESTAURANT ARISING OUT OF OR RELATING TO THIS AGREEMENT SHALL NOT EXCEED TEN
                  THOUSAND AED (AED 10,000) (OR THE EQUIVALENT AMOUNT IN THE RELEVANT LOCAL CURRENCY).
                  THE RESTAURANT AGREES THAT CAREEM HAS NO RESPONSIBILITY OR LIABILITY TO THE
                  RESTAURANT RELATED TO ANY DELIVERY SERVICES PROVIDED BY THE RESTAURANT TO CUSTOMERS
                  OTHER THAN AS EXPRESSLY SET FORTH IN THIS AGREEMENT.<br /><br />

                  <b>Advertisement</b><br /><br />

                  The User must agree to the advertisements that may be placed by the Food Delivery Platform on
                  the Service as some of the Services are supported by advertising revenue and may display
                  advertisements and promotions. The Food Delivery Platform will not be responsible or liable for
                  any errors or omission, inaccuracy in advertising material or any loss or damage of any sort
                  incurred as a result of any such dealings or a result of the presence of such other advertiser(s)
                  on the Pure Eats and mobile application.<br /><br />

                  <b>Indemnification</b><br /><br />


                  The User agree to indemnify, defend, and hold harmless the Food Delivery Platform Parties from
                  and against any third-party claims, damages (actual and/or consequential), actions,
                  proceedings, demands, losses, liabilities, costs, and expenses (including reasonable legal fees)
                  suffered or reasonably incurred by us arising as a result of or in connection with (i) User’s
                  Content (ii) User’s unauthorized use of the Services or products or services included or
                  advertised in the Services (iii) User’s access to Service (iv) User’s violation of any rights of
                  another (v) User’s breach of terms and conditions including but not limited to, any infringement
                  by the User of the copyright or intellectual property rights of any third party. The Pure Eats
                  retain the exclusive right to settle, compromise and pay, without your prior consent, any and all
                  claims or causes of action which are brought against the Pure Eats. The Pure Eats reserve the
                  right, at User’s expense, to assume the exclusive defense and control of any matter for which
                  User is required to indemnify Pure Eats and the User agree to cooperate with Pure Eats defense
                  

                  of these claims. Pure Eats will use reasonable efforts to notify the User of any such claim, action
                  or proceeding upon becoming aware of it.<br /><br />

                  The User can delete his/her account at any time contacting the Pure Eats via contact us link at
                  the bottom of every page. The Food Delivery Platform may terminate User’s use of the Services
                  and deny access to the Services in FDP’s sole discretion for any reason or no reason, including
                  User’s: (i) violation of terms and conditions of this Agreement (ii) lack of use of the Services. The
                  User must agree that any termination of User’s access to the Services may be affected without
                  prior notice and acknowledge and agree that Pure Eats may immediately deactivate or delete
                  the account and all related information and/or bar any further access to User’s account or the
                  Services. Further, the User must agree that Pure Eats shall not be liable to the User or any third
                  party for the discontinuation or termination of User’s access to the Services.<br /><br />
                  
                  <b>ADDENDUM</b><br /><br />

                  The Food Delivery Platform should be at the liberty vary or amend or change or update the
                  terms and conditions, from time to time entirely at its own discretion. The User shall be
                  responsible for checking the terms and conditions from time to time and ensure continued
                  compliance with the said terms and conditions. User’s usage of Food Delivery Platform shall be
                  deemed as express acceptance to such amended/changed terms and the User agrees to be
                  bound by such changed/amended Terms and Conditions. The User may be required to install
                  certain upgrades or updates to the software in order to continue access or use the Services, or
                  portions thereof (including upgrades or updates designed to correct issues with the Services).
                  Any updates or upgrades provided to you by us under the term and conditions shall be
                  considered part of the Services.<br /><br />


                  <b>THE USERS’S CONTENT</b><br /><br />

                  The user grants to PURE EATS the non-exclusive right to use all material
                  entered into the website by the user. You may not input or distribute any
                  material through the website that is promotional in nature including solicitations
                  for funds or business without the prior written authorization of the website.
                  The user agrees to indemnify the website and PURE EATS from all damages, liabilities,
                  costs, charges and expenses including reasonable attorneys’ fees that the websites
                  www.pureeats.in , their affiliates, employees, and authorized representative may inure
                  as a result of either <br /><br />

                  (i) The users breach of this agreement<br />
                  (ii) Material entered into the website with the use of users’ screen name & password.<br /><br />
                  
                  <b>DISCLAIMER</b><br /><br />

                  THE PURE EATS AND THE RESTAURANT APPLICATION IS PROVIDED “AS IS” AND “AS AVAILABLE.”
                  PURE EATS AND ITS AFFILIATES DISCLAIM ALL REPRESENTATIONS AND WARRANTIES, EXPRESS,
                  IMPLIED OR STATUTORY, NOT EXPRESSLY SET OUT IN THIS AGREEMENT, INCLUDING THE IMPLIED
                  WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN ADDITION, PURE EATS AND ITS AFFILIATES MAKES NO REPRESENTATION,
                  WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY OR
                  AVAILABILITY OF THE PURE EATS OR OF THE RESTAURANT APPLICATION OR ANY SERVICES OR
                  GOODS REQUESTED THROUGH THE USE OF THE PURE EATS OR THE RESTAURANT APPLICATION,
                  OR THAT THE PURE EATS OR RESTAURANT APPLICATION WILL BE UNINTERRUPTED OR ERRORFREE. NEITHER PURE EATS NOR ITS AFFILIATES GUARANTEE THE QUALITY, SUITABILITY, SAFETY
                  OR ABILITY OF CUSTOMERS. THE RESTAURANT AGREES THAT THE ENTIRE RISK ARISING OUT OF
                  THE RESTAURANT’S USE OF THE CAREEM PLATFORM AND RESTAURANT APPLICATION, AND ANY
                  SERVICE OR GOOD REQUESTED OR PROVIDED IN CONNECTION THEREWITH, REMAINS SOLELY
                  WITH THE RESTAURANT, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.<br /><br />
                  
                  <b>Indemnity</b><br /><br />
                  The Restaurant agree to indemnify and hold pure eats , its Affiliates and their officers,
                  directors, employees and agents harmless from any and all claims, demands, losses, liabilities,
                  and expenses (including attorneys’ fees) arising out of or in connection with: (a) the
                  Restaurant’s use of the Pure Eats and Restaurant Application and the sale of Goods and
                  delivery services obtained through the Restaurant’s use of the Pure Eats and the Restaurant
                  Application; (b) the Restaurant’s breach or violation of this Agreement or (c) the Restaurant’s
                  violation of the rights of any third party, including Captains.<br /><br />

                  <b>Indirect Damages</b><br /><br />

                  Pure Eats will not be liable to the Restaurant for any indirect or consequential losses (including
                  loss of profit, opportunity and goodwill).<br /><br />

                  
                  <b>Choice of Law</b><br /><br />

                  This Agreement is governed by and construed in accordance with the laws of the jurisdiction in
                  which Pure Eats is incorporated, without giving effect to any conflict of law principles, except as
                  may be otherwise provided in supplemental terms applicable to your region.<br /><br />

                  <b>Arbitration</b><br /><br />

                  You agree that any dispute, claim or controversy arising out of or relating to this Agreement or the
                  Enrollment Agreement or the breach, termination, enforcement, interpretation or validity thereof
                  or the use of the Pure Eats or the Restaurant Application or the Services (collectively, “Disputes”)
                  will be settled by binding arbitration between you and Pure Eats , except that each party retains
                  the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent
                  the actual or threatened infringement, misappropriation or violation of a party’s copyrights,
                  trademarks, trade secrets, patents or other intellectual property rights. You acknowledge and
                  agree that you and Pure Eats are each waiving the right to a trial by jury or to
                  participate as a plaintiff or class in any purported class action or representative proceeding.
                  Further, unless both you and Pure Eats otherwise agree in writing, the arbitrator may not
                  consolidate more than one person’s claims, and may not otherwise preside over any form of any
                  class or representative proceeding. If this specific paragraph is held unenforceable, then the
                  entirety of this “Arbitration” section and the “Arbitration Process and Rules” section will be
                  deemed void. Except as provided in the preceding sentence, this “Arbitration” section and the
                  “Arbitration Process and Rules” section will survive any termination of this Agreement. This
                  “Arbitration” section and the “Arbitration Process and Rules” section applies to all Disputes
                  between you and Pure Eats and/or any of its Affiliates.<br /><br />

                  <b>Arbitration Process and Rules</b><br /><br />

                  Any Dispute shall be first mandatorily submitted to mediation proceedings under the
                  International Chamber of Commerce Mediation Rules (“ICC Mediation Rules”). If such Dispute has
                  not been settled within sixty (60) days after a request for mediation has been submitted under
                  such ICC Mediation Rules, such Dispute can be referred to and shall be exclusively and finally
                  resolved by arbitration under the Rules of Arbitration of the International Chamber of Commerce
                  (“ICC Arbitration Rules”). The ICC Rules’ Emergency Arbitrator provisions are excluded. The
                  Dispute shall be resolved by one (1) arbitrator to be appointed in accordance with the ICC Rules.
                  The place of both mediation and arbitration shall be in the city in which the applicable Affiliate
                  with which you have a Dispute has its registered office. The language of the mediation and/or
                  arbitration shall be English, unless you do not speak English, in which case the mediation and/or
                  arbitration shall be conducted in both English and your native language. The existence and
                  content of the mediation and arbitration proceedings, including documents and briefs submitted
                  by the parties, correspondence from and to the International Chamber of Commerce,
                  correspondence from the mediator, and correspondence, orders and awards issued by the sole
                  arbitrator, shall remain strictly confidential and shall not be disclosed to any third party without
                  the express written consent from the other party unless: (i) the disclosure to the third party is
                  reasonably required in the context of conducting the mediation or arbitration proceedings; and
                  (ii) the third party agrees unconditionally in writing to be bound by the confidentiality obligation
                  stipulated herein.<br /><br />
                  
                  <b>ANTI- HACKING PROVISION</b><br /><br />


                  You expressly agree not to use this web site in any manner or for any purpose that is prohibited by
                  these terms & conditions. In addition, you expressly agree not to: -<br /><br />
                  (i) Use the website for any purpose that is prohibited by any law or regulation, or to
                  facilitate the violation of any law or regulate;<br />
                  (ii) Take or attempt any action that in the sole discretion of this website’s operators,
                  imposes or may impose an unreasonable or disproportionately large load or burden on
                  the websites or such operations infrastructure;<br />
                  (iii) In any way bypass or circumvent any other measure employed to limit or prevent access
                  to the website or its content;<br />
                  (iv) Violate the security of the website or attempt to gain unauthorized access to the
                  website, data, materials, information computer systems or network connected to any
                  server associated with this website through hacking, password mining or any other
                  means;<br />
                  (v) Interfere or attempt to interfere with the proper working of the website or any
                  activities conducted on or through the website including accessing any data, content or
                  other information prior to the time that it is intended to be available to the public on the
                  websites;<br />
                  (vi) Obtain or attempt to obtain through any means any materials or information on the web
                  site that have not been intentionally made policy available either by their public display
                  on the website or through their accessibility by a visible link on the website;
                  This agreement will continue until terminated by either the or you. Either party can terminate the
                  agreement by notifying the other party by telephone or electronic mail of the decision to terminate.
                  PURE EATS may discontinue or change the website or its availability to you at any time. This
                  agreement constitutes the entire agreement the parties relating to websites and supersedes any and
                  all other agreements, oral or in writing with respect to the websites. The failure of the website, to
                  insist upon strict compliance with any term of this agreement shall not be construed as a waiver with
                  regard to any subsequent failure to comply with such terms or provision. This agreement is personal
                  to you and you may not assign yours rights or obligation to anyone<br /><br />
                  
                  <b>OTHER TERMS & CONDITIONS</b><br /><br />

                  Such as Interpretation, Waiver, Entire Agreement, Severability, Partnership Agency, etc.
                  Governing Law, Jurisdiction, Dispute Resolution, etc. shall form part of this Agreement.<br /><br />
                  Statutory Compliances by Restaurant Partners: The User acknowledges that the Food Delivery
                  Platform bears no responsibility for the compliance with statutory rules, regulations, and
                  licenses by the Restaurant Partners. The User agrees that Pure Eats shall not be lia
                  manner if the User is unable to avail the offer(s) with a Restaurant Partners due to Restaurant
                  Partner’s violation of any statutory rule, regulation, and license<br /><br />
                  
                  <b>Send the written communication to:</b><br /><br />

                  <b>For users residing in the India:</b><br />
                  <b>Founded in 2020 by,</b><br />
                  <b>PURE EATS INDIA,</b><br />
                  <b>Flat no 307 SAPUIPARA, near Mota shiv Mandir ( Rakhi Factory)</b><br />
                  <b>Bally Howrah , Pin: 711227.</b><br />
                  <b>State: -West Bengal,</b><br />
                  <b>Email: - help@pureeats.in</b><br />
                  <b>Customer support number - 18001238508</b><br />

                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{backgroundColor: '#dedede'}}>
          <div className="col-12">
          <br />
          </div>
        </div>

    </div>
  );
}

