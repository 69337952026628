import React, {useState, useEffect} from "react";

import Typography from '@material-ui/core/Typography';
import {RESTAURANT_RATINGS} from '../../../configs';
import { useHistory } from "react-router-dom";
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Axios from 'axios';
import { useParams } from "react-router-dom";
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';
import Chip from '@material-ui/core/Chip';
import Rating from '@material-ui/lab/Rating';

export default function RestaurantRatings(params){

  const history = useHistory();
  const {restaurant_id} = useParams();

  const [loading, setLoading] = useState(true);
  const [ratings, setRatings] = useState({});

  useEffect(()=>{
      Axios.get(RESTAURANT_RATINGS+restaurant_id)
      .then(res=>{
        console.log(res)
        setRatings(res.data);
        setLoading(false);
      })
      .catch(e=>console.log(e));
  }, [restaurant_id]);

  return (
    <div className="container-fluid bg-app">

        {loading && (
        <div className="row">
            {[...Array(3)].map((i, index)=>{
                return <div key={index} className="col-md-4">
                    <div className="mb-3">
                      <Skeleton variant="rect" height={150} />
                      <Skeleton variant="text" style={{marginTop: 5}} />
                      <Skeleton variant="text" width={100} />
                    </div>
                </div>
            })}
        </div>
        )}

        <div className="row bg-white mb-3">
          <div className="col-12">
            <IconButton onClick={()=>history.goBack()} aria-label="back">
              <KeyboardBackspaceIcon style={{marginRight: 5}} />
            </IconButton>
            <Typography className="ml-2" color="text-secondary" variant="h6" component="span">
            <b>Ratings</b>
            </Typography>
          </div>
        </div>

        <div className="row bg-white">
          {ratings.length===0 && (
            <div className="col-12">
              <div className="p-3">
                <img alt="No Data" src="/assets/img/android/error_no_data.png" className="img-fluid" />
              </div>
            </div>
          )}

          {!loading && ratings.length > 0 && (
            <div className="col-12 p-0">
              {ratings.map((item)=>{
                return <div>
                  <Card style={{borderRadius: 0}} className="mb-3">
                    <CardContent> 
                      <div className="row">
                        <div className="col-2">
                          <img alt="avatar" src="/assets/img/logos/default.png" style={{width: 54, height: 54}} />
                        </div>
                        <div className="col-10">
                          <h4 className="mb-0 mt-2">{item.user_details.name}</h4>
                          <h6 className="mb-0">{ratings.filter(u=>u.user_id===item.user_id).length} reviews </h6>
                        </div>
                      </div>
                      <div><Rating style={{color: "#f90e30"}} name="read-only" value={item.rating} readOnly /> <span className="float-right">{(new Date(item.created_at)).toLocaleTimeString()}</span></div>

                      <div className="p-2">
                        {item.tags.split(",").map((t)=>{
                          return <Chip className="mr-2 mb-2" color="primary" label={t} />
                        })}
                      </div>
                      
                    </CardContent>
                  </Card>
                </div>
              })}
            </div>
          )}

        </div>

    </div>
  );
}

