import { GoogleApiWrapper, Map, Marker, Polyline } from "google-maps-react";
import React, { Component } from "react";

import ContentLoader from "react-content-loader";
import mapStyle from "../map-styles.json";
import {GET_ADDRESS_FROM_COORDINATES} from '../../../configs';
import Axios from 'axios';

function decode(encoded){
    var points=[ ]
    var index = 0, len = encoded.length;
    var lat = 0, lng = 0;
    while (index < len) {
        var b, shift = 0, result = 0;
        do {

    b = encoded.charAt(index++).charCodeAt(0) - 63;//finds ascii                                                                                    //and substract it by 63
              result |= (b & 0x1f) << shift;
              shift += 5;
             } while (b >= 0x20);

       var dlat = ((result & 1) !== 0 ? ~(result >> 1) : (result >> 1));
       lat += dlat;
      shift = 0;
      result = 0;
     do {
        b = encoded.charAt(index++).charCodeAt(0) - 63;
        result |= (b & 0x1f) << shift;
       shift += 5;
         } while (b >= 0x20);
     var dlng = ((result & 1) !== 0 ? ~(result >> 1) : (result >> 1));
     lng += dlng;
 
   points.push({lat:( lat / 1E5),lng:( lng / 1E5)})  
 
  }
  return points
}

class LiveGoogleMap extends Component {
    constructor(props){
        super(props);
        this.state={
            initialPosition: {
                lat: 22.5726,
                lng: 88.3639
            },
            zoom: 14,
            coords: [],
            loading: true,
            timeLeft: 0,
            countDownTarget: 0,
        };

        // this.showClock = this.showClock.bind(this);
    }

    componentDidMount() {
        // if (localStorage.getItem("geoLocationEdit") !== null) {
        //     const location = JSON.parse(localStorage.getItem("geoLocationEdit"));
        //     this.setState({ initialPosition: { lat: location.latitude, lng: location.longitude } });
        // }
        setTimeout(() => {
            this.setState({ zoom: 14 });
        }, 1 * 1000);

        this.getDirections();
        this.initcalculateTimeLeft();
    }

    initcalculateTimeLeft(){
        let ft =  Math.round((new Date(this.props.order.created_at).getTime()+(20*60000)+(parseInt(this.props.restaurant.base_delivery_charge)*60000)));
        this.setState({countDownTarget: ft});
        this.calculateTimeLeft();
    }

    async getDirections( ) {
        console.log(this.props.order);

        const mode = 'driving'; // 'walking';
        const origin = `${JSON.parse(this.props.order.location).lat},${JSON.parse(this.props.order.location).lng}`;
        const destination = `${this.props.restaurant.latitude},${this.props.restaurant.longitude}`;
        const APIKEY = 'AIzaSyCt_14My2CYghVw6eZFSYFlFPBOK29lkww';
        const url = `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}&key=${APIKEY}&mode=${mode}`;

        var proxy_url = 'https://thingproxy.freeboard.io/fetch/';
        var target_url = url

        let resp = await fetch(`${proxy_url}${target_url}`)
        let respJson = await resp.json();
        let points = await decode(respJson.routes[0].overview_polyline.points);
        this.setState({coords:points, loading: false})
    }


    reverseLookup = (lat, lng) => {
        // alert('Please Enable Your device Location to continue.');

        Axios.post(GET_ADDRESS_FROM_COORDINATES, {
            lat: lat,
            lng: lng
        })
            .then(response => {
                console.log('response');
                console.log(response);
                // this.props.address(response.data);
                this.setState({ location: response.data, dragging: false });
            })
            .catch(function(error) {
                // alert(error.response.data);
                // this.setState({locationModal: true});
                // alert('Please Enable Your device Location to continue.');
                console.warn(error.response.data);
            });
    };

    onMarkerDragEnd = map => {
        // console.log({ lat: map.center.lat(), lng: map.center.lng() });
        localStorage.setItem("userLat", map.center.lat());
        localStorage.setItem("userLng", map.center.lng());
        this.reverseLookup(map.center.lat(), map.center.lng());
    };

    handleDragging = () => {
        this.setState({ dragging: true });
    };

    fmtMSS(s){return(s-(s%=60))/60+(9<s?':':':0')+s}



    calculateTimeLeft(){
        // console.log(new Date(this.props.order.created_at).getTime());
        // let orderTime = new Date(this.props.order.created_at).getTime();
        // let now = new Date().getTime();
        // let diff = now - orderTime;

        // let endTime   = (orderTime) - now;
        // let remaning = this.fmtMSS(Math.round(endTime/1000))
      
        // this.setState({timeLeft: remaning});

        // setTimeout(()=>{
        //     this.calculateTimeLeft();
        // },1000)
        
        setTimeout(()=>{
            if(this.state.countDownTarget==='done') return;

            let t = showClock(this.state.countDownTarget);

            this.setState({timeLeft: t[0]+":"+t[1]})
            
            if (this.state.countDownTarget - new Date().getTime() < 0) {
                this.setState({countDownTarget: 'done', timeLeft: 'a few minutes'})
            }

            this.calculateTimeLeft();

        }, 1000);
    }


    render() {
        if (this.state.loading) {
            return(
                <span>Loading...</span>
            )
        }
        return (
            <div>
                <div style={{padding: 10, position: 'absolute', bottom: 15, right: 15, backgroundColor: 'rgb(31 31 31)', zIndex: 9,borderRadius: 6}}>
                    {this.props.order.orderstatus_id!==5 && (<h6 className="mb-0" style={{color: '#fff'}}>ARRIVING IN</h6>)}
                    <h6 className="mb-0" style={{color: '#fff'}}>{(this.props.order.orderstatus_id===5)?"FINISHED":this.state.timeLeft+" minutes"}</h6>
                </div>
                <div>
                    <Map
                        google={this.props.google}
                        style={{
                            width: "100%",
                            height: (this.props.order.orderstatus_id===7)?"100vh":"55vh",
                            position: 'inherit',
                        }}
                        initialCenter={{
                            lat: this.props.restaurant.latitude, 
                            lng: this.props.restaurant.longitude
                        }}
                        // onDragend={(t, map, coord) => this.onMarkerDragEnd(map)}
                        zoom={this.state.zoom}
                        styles={mapStyle}
                        zoomControl={false}
                        mapTypeControl={false}
                        scaleControl={true}
                        streetViewControl={false}
                        showsMyLocationButton={true}
                        fullscreenControl={false}
                        onReady={(mapProps, map) => {
                            this.reverseLookup(this.state.initialPosition.lat, this.state.initialPosition.lng);
                            localStorage.setItem("userLat", map.center.lat());
                            localStorage.setItem("userLng", map.center.lng());
                        }}
                        onDragstart={() => {
                            this.handleDragging(true);
                        }}
                    >
                        <Polyline
                          path={this.state.coords}
                          strokeColor="#0000FF"
                          strokeOpacity={0.8}
                          strokeWeight={3} />

                        <Marker 
                            icon={{
                                url: "/assets/img/various/marker-home.png",
                                // anchor: new this.props.google.maps.Point(32, 32),
                                scaledSize: new this.props.google.maps.Size(34, 54)
                            }} 
                            position={this.state.initialPosition}>
                        </Marker>
                        <Marker 
                            icon={{
                                url: "/assets/img/various/marker-restaurant.png",
                                // anchor: new this.props.google.maps.Point(32, 32),
                                scaledSize: new this.props.google.maps.Size(34, 54)
                            }} 
                            position={{lat: this.props.restaurant.latitude, lng: this.props.restaurant.longitude}}>
                        </Marker>
                    </Map>
                </div>
            </div>
        );
    }
}

const MapLoadingContainer = () => (
    <ContentLoader height={400} width={window.innerWidth} speed={1.2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
        <rect x="0" y="0" rx="0" ry="0" width={window.innerWidth} height="400" />
    </ContentLoader>
);

export default GoogleApiWrapper({
    apiKey: 'AIzaSyCt_14My2CYghVw6eZFSYFlFPBOK29lkww',
    LoadingContainer: MapLoadingContainer
})(LiveGoogleMap);


function showClock(target){
    const distance = target - new Date().getTime();
    const mins = distance < 0 ? 0: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const secs = distance < 0 ? 0: Math.floor((distance % (1000 * 60)) / 1000);        

    return [mins, secs];
}