import React from "react";

import Header from '../../../components/Layouts/Header';
import Typography from '@material-ui/core/Typography';

export default function PrivacyPolicy(){

    return (
        <div className="container-fluid p-0">

          <Header />
            <div className="row" style={{padding: '40px 50px', backgroundColor: '#eb4b60'}}>
              <div className="col-md-12">
                <h3 style={{color: 'white', marginBottom: 5}}>Privacy Policy</h3>
              </div>
            </div>

            <div className="row" style={{padding: '0px 50px', backgroundColor: '#eb4b60'}}>
                <div className="col-md-12" style={{backgroundColor: '#fff', padding: 45}}>
                  <Typography variant="body1">
                  Pure eats is a online food delivery service .By visiting our website www.pureeats.in and our app "Pure Eats
                  App" jointly referred to as the "Platform", you provide us with some personal data.
                  We Pure eats are fully committed to respecting your privacy, protecting your personal information and ensuring
                  it is safe with us. This privacy policy sets out the information practices, with respect to the use of our website
                  including the type of information collected, how the information is collected, how the information is used and
                  with whom it is shared.<br /><br />

                  References to "you" or "your" in this Privacy Policy refer to the users of this Platform whether or not you access
                  the services available on the Platform or consummate a transaction on the Platform. References to "we", "our"
                  or "us" shall mean PURE EATS.<br /><br />


                  "Personal Information" refers to any information that identifies or can be used to identify, contact or locate the
                  person, to whom such information pertains including, but not limited to, name, address, phone number, email
                  address and payment information disclosed by you in relation to the services available on the Platform.
                  "Third Party" refers to any person or entity other than you or us.
                  By using or accessing this Platform, you agree to the terms and conditions of this Privacy Policy. You also
                  expressly consent to our use and disclosure of your Personal Information (as defined below) in any manner as
                  described in this Privacy Policy and further signify your agreement to this Privacy Policy and the Terms of Use
                  (being incorporated by reference herein).<br /><br />

                  <br /><br />

                  <b>What information do we collect from you?</b>
                  We collect your personal information from you only when you order goods or services on our website,
                  when you complete any customer survey, or through cookies. In order for us to successfully deliver
                  your order to you, we collect your personal Information as may be required for the purposes
                  connected thereto when you place an order on the Platform. This information is collected, stored and
                  used on secured servers.<br /><br />

                  <b>How do we use the information we collect from you?</b>
                  We use the details provided by you during check out to process your order:<br />
                  (A) ensure that orders are successfully delivered on time,<br />
                  (B)to keep you informed on the status of your order and to manage your account.
                  In connection with facilitating this service, we receive call data, including the date and time of the call or SMS
                  message, the parties' phone numbers, and the content of the SMS message, etc.<br />
                  We may also collect information to enable us to understand you better so that we can improve<br />
                  (i) your user experience;<br />
                  (ii) to assist our customer service and technical support personnel; and<br />
                  (iii) put processes in place to prevent fraud and unlawful use.<br />
                  In an effort to make the Platform effective and improve the Platform, certain information may be
                  collected each time you access the Platform. Such information may be saved in server logs in an encrypted form
                  which may not identify you personally. Such information or data may include, but shall not be limited to, IP
                  address, your server details, duration of your visit, date, time or purpose of your visit.
                  To the extent necessary to provide you the services on the Platform, we may be required to disclose your Personal
                  Information to Third Parties solely in connection with purchase, sale and delivery of your order in accordance with
                  the terms and conditions stipulated by such Third Party. We may also provide your Personal Information to Third
                  Party contractors who work on behalf of or with us to help us communicate with you or to maintain the Platform.
                  Cookies are small pieces of information saved by your browser while visiting websites. They are necessary to
                  have websites work more efficiently or to provide information to the website’s owners. We collect cookies to
                  record various aspects of your visit and assist us in providing you with uninterrupted service. This
                  <b>Cookies</b>
                  information is maintained distinctly and is not linked to the Personal Information that you submit to us. We
                  use cookies collected to:<br /><br />

                  (i) authenticate your login information;<br />
                  (ii) enable our security features;<br />
                  (iii) show you advertising; and<br />
                  (iv) improve and develop the features of the Website.<br />
                  We also use Google Analytics, a web analytics service provided by Google, Inc. ("Google"). Google Analytics
                  uses "cookies". The information generated by the cookie about your use of the website will be transmitted to
                  and stored by Google on its servers. Google will use this information for the purpose of evaluating your use of
                  the website, compiling reports on website activity for website operators and providing other services relating to
                  website activity and internet usage. Google will not associate your IP address with any other data held by
                  Google. By using this website, you consent to the processing of data about you by Google in the manner and for
                  the purposes set out above.<br />
                  If you do not wish to accept or use cookies, feel free to change your browser settings, however please note that
                  if you do this you may not be able to use the full functionality of this website which will affect your experience
                  on the Platform.<br />
                  We may offer email, App notifications, SMS, multimedia message service (MMS) or other forms of
                  communication to share information with you about certain promotions or features the Platform may choose to
                  offer or about our affiliates, subsidiaries, business partners, advertisers and sponsors. You may receive such
                  communication when you have registered as a user in order to provide advertisements about goods and services
                  of interest to you.<br />
                  The Platform and any communication sent to you may also contain/display Third Party advertisements and links
                  to other websites or products and services. You agree and acknowledge that these Apps and websites are
                  operated by Third Parties and are not controlled by, or affiliated to, or associated with us unless expressly
                  specified. This privacy policy only applies to this website. You should therefore read the privacy policies of the
                  other websites when using those sites.<br /><br />

                  <b>Link to Third Party apps and websites</b><br />
                  <b>Communication</b><br />
                  We strive to ensure the security, integrity and privacy of your Personal Information and to protect your Personal
                  Information against unauthorized access or unauthorized alteration, disclosure or destruction. We are not
                  responsible for any breach of security or for any actions of any Third Parties that receive your Personal
                  Information. The Services are also linked to many other sites and we are not/shall be not responsible for their
                  privacy policies or practices as it is beyond our control. Notwithstanding anything contained in this Privacy
                  Policy or elsewhere, we shall not be held responsible for any loss, damage or misuse of your Personal
                  Information, if such loss, damage or misuse is attributable to a Force Majeure Event.
                  We reserve all rights to modify this Privacy Policy at any time according to our need. Our Privacy Policy may
                  changes from time to time and all updates will be posted on this Website. Our Privacy Policy may be change
                  according to changes in law, our features and services, advances in technology. Please visit our Privacy Policy
                  page with periods of time for update in Privacy Policy. Please review our Privacy Policy carefully. Our continued
                  use of the Services following the posting of changes to Privacy Policy will constitute your consent and
                  acceptance of those changes.<br /><br />

                  <b>Changes in Privacy Policy</b><br />
                  <b>Security</b><br />
                  If you have any Query, Feedback or Concern about this Privacy Policy, you can write to us or reach out to us
                  using following contact details.<br /><br />

                  <b>Send the written communication to:-</b><br />
                  For users residing in the India: -<br />
                  Founded in 2020 by,<br />

                  Flat no 307 SAPUIPARA, near Mota shiv Mandir ( Rakhi Factory)<br />
                  Bally Howrah , Pin: -711227.<br />
                  State: -West Bengal,<br />
                  Email: - help@pureeats.in<br />
                  Customer support number - 18001238508<br />
                  </Typography>
                </div>
            </div>
        </div>
    );
}


