import React, {useState, useEffect} from "react";

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Axios from 'axios';
import {GET_DELIVERY_RESTAURANTS_URL, ROOT_URL} from '../../../configs';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Header from '../../../components/Layouts/Header';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));


export default function Search(){

    const classes = useStyles();

    const [search, setSearch] = useState('');
    const [restaurants, setRestaurants] = useState([]);
    // const [banners, setBanners] = useState([]);
    const [arrayHolder, setArrayHolder] = useState([]);

    useEffect(()=>{
        Axios.post(GET_DELIVERY_RESTAURANTS_URL, {
            latitude: '22.5726',
            longitude: '88.3639'
        })
        .then(res=>{
            console.log(res.data)
            setRestaurants(res.data)
            setArrayHolder(res.data)
        })
        .catch((e)=>console.log(e))
    }, []);

    const searchItems=(text)=>{
      const newData = arrayHolder.filter(item => {      
        const itemData = `${String(item.name).toUpperCase()}`;
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;    
      });
      
      setRestaurants(newData);
      setSearch(text);
    }



    return (
        <div className="container-fluid p-0">

          <Header />

            <div className="row">
                <div className="col-md-12">

                  <div style={{padding: 35, textAlign: 'center'}}>
                    <Card style={{width: '50%', margin: 'auto'}}>
                      <TextField 
                        style={{width: '95%', borderTopWidth: 1, fontSize: 28, marginTop: 10, marginBottom: 10}}
                        id="search" 
                        value={search}
                        autoFocus={true}
                        onChange={(e)=>searchItems(e.target.value)}
                        placeholder="Search for Restaurants Or Dishes" 
                        variant="standard" 
                        InputProps={{
                                  startAdornment: <InputAdornment position="start"><SearchIcon style={{fontSize: 28}} /></InputAdornment>,
                                }}
                      />

                      {search !== '' && (
                      <List className={classes.root}>
                        {restaurants.map((item)=>{
                          return <ListItem button key={item.id} onClick={()=>console.log('TODO')}>
                            <ListItemAvatar>
                              <Avatar>
                                <img alt="logo" src={ROOT_URL+item.image} />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={item.name} secondary={item.description} />
                          </ListItem>
                        })}
                      </List>
                      )}
                    </Card>

                  </div>
                    
                </div>
            </div>

        </div>
    );
}


