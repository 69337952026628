import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {REGISTER_USER_URL,NEW_REGISTER_OTP} from '../../configs';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function SignupDrawer(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();


  const [signup, setSignupDrawer] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mobile, setMobile] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');

  const registerOtp=()=>{
    setLoading(true);
    Axios.get(`${NEW_REGISTER_OTP}/${mobile}`)
    .then(res=>{
      console.log(res.data)
      if (res.data.success) {
        enqueueSnackbar('OTP sent to your mobile no.', {variant: 'success'});
        setLoading(false);
        setOtpSent(true)
      }else{
        enqueueSnackbar('Invalid Mobile No.!', {variant: 'error'});
        setLoading(false);
      }
    })
    .catch((e)=>console.log(e))
  }

  const doSignup=()=>{

    if (name==='' || mobile.length<10 || email==='') {
      enqueueSnackbar('Please enter all details.', {variant: 'error'});
    }

    if(!otpSent){
      registerOtp()
      return;
    }

    let raw = {
      phone:mobile,
      name,
      email,
      otp
    }

    setLoading(true)

    Axios.post(REGISTER_USER_URL, raw)
    .then(res=>{
      console.log(res)
      if (res.data.success) {
        localStorage.setItem('user', JSON.stringify(res.data.data));
        setTimeout(()=>{
          window.location.reload();
        }, 800);
        enqueueSnackbar('Account created.', {variant: 'success'});
      }else{
        setLoading(false)
        enqueueSnackbar(res.data.message, {variant: 'error'});
      }
    })
    .catch(e=>console.log(e));
  }

  return (
        <>
            <Button onClick={()=>setSignupDrawer(true)} style={{float: 'right', marginLeft: 10, backgroundColor: '#bb1229', borderRadius: 0, marginTop: (props.margin)?13:0}} variant="contained" color="primary">
              Sign Up
            </Button>

            <Drawer anchor={'right'} open={signup} onClose={()=>setSignupDrawer(false)}>
              <div style={{padding: 1}}>
                <IconButton onClick={()=>setSignupDrawer(false)} aria-label="close" className={classes.margin}>
                  <CloseIcon />
                </IconButton>
              </div>

              <div style={{padding: 25}}>
                <Typography variant="h5" gutterBottom>
                  Sign Up
                </Typography>
                <Typography variant="caption" gutterBottom>
                  or {' '}
                </Typography>
                <a href="/">
                  <Typography style={{color: '#bb1229'}} variant="caption" gutterBottom>
                    login to your account
                  </Typography>
                </a>

                <br />

                <div>
                  <TextField
                    className="filled-input-white"
                    style={{marginTop: 5, backgroundColor: 'white', borderColor: 'black', width: '100%'}}
                    label="Phone Number "
                    id="phone"
                    onChange={(e)=>setMobile(e.target.value)}
                    disabled={otpSent}
                    defaultValue=""
                    variant="filled"
                  />
                </div>
                <div>
                  <TextField
                    className="filled-input-white"
                    style={{marginTop: 5, backgroundColor: 'white', borderColor: 'black', width: '100%'}}
                    label="Name "
                    id="name"
                    onChange={(e)=>setName(e.target.value)}
                    disabled={otpSent}
                    defaultValue=""
                    variant="filled"
                  />
                <div>
                </div>
                  <TextField
                    className="filled-input-white"
                    style={{marginTop: 5, backgroundColor: 'white', borderColor: 'black', width: '100%'}}
                    label="Email "
                    id="email"
                    onChange={(e)=>setEmail(e.target.value)}
                    disabled={otpSent}
                    defaultValue=""
                    variant="filled"
                  />
                <div>
                </div>

                {otpSent && (
                  <TextField
                    className="filled-input-white"
                    style={{marginTop: 5, backgroundColor: 'white', borderColor: 'black', width: '100%'}}
                    label="OTP"
                    id="OTP"
                    onChange={(e)=>setOtp(e.target.value)}
                    defaultValue=""
                    variant="filled"
                  />
                )}

                </div>
                  <Button 
                    style={{marginTop: 25, width: '100%', backgroundColor: '#bb1229', borderRadius: 0, color: 'white'}} 
                    variant="contained" 
                    size="large" 
                    disabled={loading}
                    onClick={()=>doSignup()}
                    color="primary">
                      SIGNUP
                  </Button>
              </div>
            </Drawer>

        </>
    
  );
}

