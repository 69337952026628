import React, {useState, useEffect, useRef} from "react";

// import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
// import 'pure-react-carousel/dist/react-carousel.es.css';
import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';
// import { useHistory } from "react-router-dom";
// import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Axios from 'axios';
import {GET_DELIVERY_RESTAURANTS_URL, ROOT_URL, GET_PROMO_SLIDER_URL} from '../../../configs';
import Skeleton from '@material-ui/lab/Skeleton';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import Avatar from '@material-ui/core/Avatar';
// import ArrowDownwardOutlined from '@material-ui/icons/ArrowDownwardTwoTone';
// import FastfoodOutlined from '@material-ui/icons/FastfoodTwoTone';
// import RestaurantOutlined from '@material-ui/icons/RestaurantTwoTone';
// import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderTwoTone';
// import WhatshotTwoToneIcon from '@material-ui/icons/WhatshotTwoTone';
import Header from '../../../components/Layouts/Header';
import { useHistory } from "react-router-dom";
// import Grow from '@material-ui/core/Grow';
import Slider from "react-slick";
import EmptyResult from '../../../components/Loading/EmptyResult';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
// import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


const SliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: false,
  button: false,
  autoplay: true,
  autoplaySpeed: 3000,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Restaurants(){

    const history = useHistory();

    const classes = useStyles();
    // const history = useHistory();
    // const onlyPureeats = useRef(null)
    // const offerNear = useRef(null)
    const seeAll = useRef(null)

    const [loading, setLoading] = useState(true);
    // const [filter, setFilter] = useState('TopPicks');
    const [restaurants, setRestaurants] = useState([]);
    const [banners, setBanners] = useState([]);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    useEffect(()=>{
        Axios.post(GET_PROMO_SLIDER_URL)
        .then(res=>{
            console.log(res.data)
            setBanners(res.data.mainSlides)
        })
        .catch((e)=>console.log(e))
    }, []);

    useEffect(()=>{
        Axios.post(GET_DELIVERY_RESTAURANTS_URL, {
            latitude: localStorage.getItem('userLat'),
            longitude: localStorage.getItem('userLng')
        })
        .then(res=>{
            console.log(res.data)
            setRestaurants(res.data)
            setLoading(false);
        })
        .catch((e)=>console.log(e))
    }, []);


    return (
        <div className="container-fluid p-0">

          <Header />

          <div className="container p-0">

            <div className="row">
              <div className="col-md-12 p-3">
                {banners.length>2 && (
                  <Slider {...SliderSettings}>
                    {banners.map((i, index)=>{
                      return <div key={index}>
                        <img style={{width: '100%',height: 160, borderRadius: 10, padding: 2}} src={ROOT_URL+i.image} alt="slide" />
                      </div>
                    })}
                  </Slider>
                )}
              </div>
            </div>

            <div className="row">
                
                <div className="col-md-12">

                  {!loading && restaurants.length === 0 && (
                  <div className="row">
                      <div className="col-md-12">
                          <div style={{padding: 50, textAlign: 'center'}}>
                              <EmptyResult />
                              <h6 className={'text-muted'}>No restaurants are available in your selected location.</h6>
                          </div>
                      </div>
                  </div>
                  )}

                  {loading && (
                  <div className="row">
                      {[...Array(16)].map((i)=>{
                          return <div key={i} className="col-md-3">
                              <div className="mb-3">
                                <Skeleton variant="rect" height={150} />
                                <Skeleton variant="text" style={{marginTop: 5}} />
                                <Skeleton variant="text" width={100} />
                              </div>
                          </div>
                      })}
                  </div>
                  )}

                  {!loading && restaurants.length > 0 && (

                    <div>
                      <div className="row mb-3"> 
                        <div className="col-md-5 p-0">
                          <div style={{borderBottom: '1px solid #d8d8d8'}}>
                            <h4 ref={seeAll} style={{marginTop: 2}}><b>{restaurants.length} restaurants</b></h4>
                          </div>
                        </div>
                        <div className="col-md-7 p-0">
                          <Tabs
                            value={value}
                            style={{borderBottom: '1px solid #d8d8d8'}}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                          >
                            <Tab label="Relevance" />
                            <Tab label="Cost For Two" />
                            <Tab label="Delivery Time" />
                            <Tab label="Rating" />
                          </Tabs>
                        </div>
                      </div>

                        {restaurants.length > 0 && (
                        <div className="row dashed-bottom">
                            {restaurants.map((item)=>{
                              return <div key={item.id} className="col-md-3">
                                  <div className="mb-3">
                                      <div className={classes.root} style={{padding: 10}}>
                                          <CardActionArea onClick={()=>history.push('restaurantDetail/'+item.slug)}>
                                              {item.is_pureveg===1 && (
                                              <div className="ribbon ribbon-bookmark ribbon-success">
                                                <div className="ribbon-box" style={{position: 'absolute', top: 0, zIndex:9}}>Pure Veg</div>
                                              </div>
                                              )}
                                              {item.is_featured===1 && (
                                              <div className="ribbon ribbon-bookmark ribbon-warning">
                                                <div className="ribbon-box" style={{position: 'absolute', top: 0, zIndex:9}}>Featured</div>
                                              </div>
                                              )}

                                              <img className={(item.is_open===0 || item.is_active===0)?'page-inactive':''} src={ROOT_URL+item.image} alt="PureEats" style={{width: '100%', maxHeight: 180}} />

                                              <CardContent>
                                                <Typography gutterBottom variant="body1" component="h2">
                                                  {item.name}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                  {item.description}
                                                </Typography>
                                                <Typography variant="caption" color="textSecondary" component="p">
                                                  {item.msg}
                                                </Typography>

                                                <div className="mt-2">
                                                  <span style={{marginRight: 2, fontSize: 13, backgroundColor: 'green', padding: '3px 6px', borderRadius: 2, color: 'white'}}>
                                                      <StarIcon style={{fontSize: 12}} /> {item.rating}</span>
                                                      <span style={{color:'#6f6f6f', padding: '0 6px'}}>•</span> 
                                                      <span style={{fontSize: 11}}>{item.delivery_time} MINS</span>
                                                      <span style={{color:'#6f6f6f', padding: '0 6px'}}>•</span> 
                                                      <span style={{fontSize: 11}}>{item.price_range} FOR TWO</span>
                                                </div>

                                                {(item.coupons !== undefined) && (
                                                  <>
                                                    {item.coupons[0] !== undefined && (
                                                      <div style={{borderTop: '1px solid grey',padding: 5,color: '#c77800',}} className="mt-2">
                                                        <LocalOfferOutlinedIcon style={{marginTop: 5}} />  <span>{item.coupons[0].name} </span>
                                                      </div>
                                                    )}
                                                  </>
                                                )}


                                              </CardContent>
                                          </CardActionArea>
                                      </div>
                                  </div>
                              </div>
                            })}
                        </div>
                        )}

                    </div>

                  )}

                </div>
            </div>

          </div>
        </div>
    );
}


