import React, {useState} from "react";

import 'pure-react-carousel/dist/react-carousel.es.css';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import {KeyboardArrowDown, SearchOutlined, LocalOfferOutlined, HelpOutlineOutlined, ShoppingBasketOutlined, AccountCircleOutlined} from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import Flip from "react-reveal/Flip";
import PlacesAutocomplete from 'react-places-autocomplete';
import TextField from '@material-ui/core/TextField';
import RoomOutlined from '@material-ui/icons/RoomOutlined';
import { useHistory, useLocation } from "react-router-dom";
import Popover from '@material-ui/core/Popover';
import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import LoginDrawer from '../../components/Auth/LoginDrawer';
import SignupDrawer from '../../components/Auth/SignupDrawer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Header(){

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const [locationDrawer, setLocationDrawer] = useState(false);
    // const [google_script_loaded, setgoogle_script_loaded] = useState(true);
    const [address, setAddress] = useState('');

    const [anchorEl, setAnchorEl] = useState(null);
    // const handlePopoverOpen = (event) => {
    //   setAnchorEl(event.currentTarget);
    // };
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const handleChange = address => {
      setAddress(address);
    };
   
    const handleSelect = address => {
      setAddress(address);
      geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(latLng => {
          console.log('Success', latLng)
          localStorage.setItem("userLat", latLng.lat);
          localStorage.setItem("userLng", latLng.lng);
          localStorage.setItem("address", address);
          window.location.reload();
        })
        .catch(error => console.error('Error', error));
    };

    // useEffect(()=>{
    //   const existingScript = document.getElementById("googleMaps");
    //   if (!existingScript) {
    //     const script = document.createElement("script");
    //     script.src =
    //       "https://maps.googleapis.com/maps/api/js?key=AIzaSyCt_14My2CYghVw6eZFSYFlFPBOK29lkww&libraries=places";
    //     script.id = "googleMaps";
    //     document.body.appendChild(script);
    //     script.onload = () => {
    //       setgoogle_script_loaded(true)
    //     };
    //   }
    // },[location.pathname])


    return (
          <div className="row">
              <div className="col-md-12">
                  <div className={classes.root}>
                    <AppBar style={{backgroundColor: 'white'}}  position="static">
                      <Toolbar>
                        {/*<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                          <Menu />
                        </IconButton>*/}
                        <img onClick={()=>history.push('/restaurants')} style={{height: '3.5rem', cursor: 'pointer'}} src="/assets/img/logos/logo.png" alt="PureEats" className="footer-logo mr-2" />
                        
                        <Typography style={{minWidth: 183, cursor: 'pointer'}} onClick={()=>setLocationDrawer(true)} variant="body2" className="eclipse text-muted">
                          {localStorage.getItem('address')} <KeyboardArrowDown style={{paddingTop: 10}} />
                        </Typography>

                        <div style={{width: '100%'}}>

                          {(localStorage.getItem('user') !== null && localStorage.getItem('user') !== "[object Object]") && (
                            <Button 
                              onClick={()=>history.push('/account')} 
                              style={{float:'right', padding: 20, color: (location.pathname==='/account')?'#f01d3b':''}} 
                              startIcon={<AccountCircleOutlined />} >
                              {JSON.parse(localStorage.getItem('user')).name}
                            </Button>
                          )}
                          {(localStorage.getItem('user') === undefined || localStorage.getItem('user') === null) && (
                            <>
                              <LoginDrawer margin={true} />
                              <SignupDrawer margin={true} />
                            </>
                          )}
                          
                          {/*<Button style={{float:'right', padding: 20}} startIcon={<AccountCircleOutlined />} >Sign In</Button>
                          <Button  
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            style={{color: (location.pathname==='/cart')?'#f01d3b':''}}
                            onMouseEnter={handlePopoverOpen}
                            onClick={()=>history.push('/cart')}
                            onMouseLeave={handlePopoverClose}
                            style={{float:'right', padding: 20}} 
                            startIcon={<ShoppingBasketOutlined />} 
                          >
                            Cart
                          </Button>*/}
                          <Button onClick={()=>history.push('/cart')} style={{float:'right', padding: 20, color: (location.pathname==='/cart')?'#f01d3b':''}} startIcon={<ShoppingBasketOutlined />} >Cart</Button>
                          <Button onClick={()=>history.push('/help')} style={{float:'right', padding: 20, color: (location.pathname==='/help')?'#f01d3b':''}} startIcon={<HelpOutlineOutlined />} >Help</Button>
                          <Button onClick={()=>history.push('/offers')} style={{float:'right', padding: 20, color: (location.pathname==='/offers')?'#f01d3b':''}} startIcon={<LocalOfferOutlined />} >Offers</Button>
                          <Button onClick={()=>history.push('/search')} style={{float:'right', padding: 20, color: (location.pathname==='/search')?'#f01d3b':''}} startIcon={<SearchOutlined />} >Search</Button>
                        </div>

                      </Toolbar>
                    </AppBar>
                  </div>
              </div>

              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography>Cart</Typography>
              </Popover>

              <Drawer style={{width: '45%'}} anchor={'left'} open={locationDrawer} onClose={()=>setLocationDrawer(false)}>
                <div style={{padding: 1}}>
                  <IconButton onClick={()=>setLocationDrawer(false)} aria-label="close" className={classes.margin}>
                    <CloseIcon />
                  </IconButton>
                </div>

                <div style={{padding: 15}}>

                  <PlacesAutocomplete
                    onChange={(val)=>handleChange(val)}
                    onSelect={(val)=>handleSelect(val)}
                    value={address}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        <TextField
                          {...getInputProps({
                            placeholder: 'Search Places ...',
                          })}
                          style={{width: '100%', borderTopWidth: 1, backgroundColor: 'white', borderColor: 'black'}}
                          label="Enter your delivery location "
                          id="search"
                          size="small"
                        />

                        <div className="autocomplete-dropdown-container">
                          <div className="MuiPaper-root MuiCard-root jss178 MuiPaper-elevation1 MuiPaper-rounded" style={{background: 'white', position: 'absolute', zIndex: 9999, padding: 5}}>
                            {loading && 
                              <div>
                                <img
                                  src="/assets/img/various/spinner.svg"
                                  className="location-loading-spinner"
                                  alt="loading"
                                />
                              </div>
                            }

                            {suggestions.map((suggestion, index) => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <Flip top delay={index * 50} key={suggestion.id}>
                                    <div
                                      className="location-suggestion"
                                      style={{padding: 10, borderBottomWidth: 1, borderBottom: 'daashed'}}
                                    >
                                      <RoomOutlined style={{marginRight: 5}} />
                                      <span className="location-main-name">
                                        {suggestion.description}
                                      </span>
                                    </div>
                                  </Flip>
                                </div>
                              );
                            })}
                          </div>

                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>

                </div>
              </Drawer>

          </div>
    );
}


