import React, {useState, useEffect, useRef} from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {POST_USER_PROFILE} from '../../../configs';
// import Axios from 'axios';
// import { Link } from "react-router-dom";
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import { useSnackbar } from 'notistack';
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import IconButton from '@material-ui/core/IconButton';

export default function EditAccount() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const avatar = useRef();

  const [loading, setLoading] = useState(false);
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [photo, setPhoto] = useState(null);

  useEffect(()=>{
    let user = JSON.parse(localStorage.getItem('user'));
    setEmail(user.email)
    setMobile(user.phone)
    setName(user.name)
    setPhoto('http://admin.pureeats.in'+user.photo)
  },[])

  const saveAccount=()=>{
    if (name==='') {
      enqueueSnackbar('Please enter your full name.', {variant: 'error'});
    }

    setLoading(true);

    let user = JSON.parse(localStorage.getItem("user"));

    let formData = new FormData();
    formData.append('user_id', user.id);
    formData.append('token', user.auth_token);
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', mobile);
    formData.append('photo', avatar.current.files[0]);

    console.log(formData)
    console.log(POST_USER_PROFILE)

    fetch(POST_USER_PROFILE, {
      method: "POST",
      body: formData
    })
    .then(response => response.json())
    .then(response => {
        console.log(response);

        let user = JSON.parse(localStorage.getItem('user'));
        user.name = name;
        user.photo = response.photo;
        localStorage.setItem('user', JSON.stringify(user));

        enqueueSnackbar('Profile Updated.', {variant: 'success'});
        setLoading(false);
    })
    .catch(function(error) {
        enqueueSnackbar('Unable to update Profile.', {variant: 'error'});
        console.log(error);
        setLoading(false);
    });
  }

  // handleFile=()=>{
    // avatar.current.click();
  // }

  const renderImagePreview=()=>{
    if (avatar.current.value !== null && avatar.current.value !== "") {
      // console.log(avatar.current.files[0]);
      
      // let reader = new FileReader();
      // reader.onload = function(e) {
      //   setPhoto(e.target.result);
      // }
      // reader.readAsDataURL(avatar.current.files[0]);
      
      // console.log(URL.createObjectURL(avatar.current.files[0]));
      setPhoto(URL.createObjectURL(avatar.current.files[0]));
    }
  }

  if(localStorage.getItem('user') === null ){
    return(<Redirect to="login" />)
  }

  return (
        <div className="container">

            <div className="row bg-app">

              <div className="col-7">
                <div className="p-1">
                    <IconButton onClick={()=>history.goBack()} aria-label="back">
                    <KeyboardBackspaceIcon style={{marginRight: 5}} />
                  </IconButton>
                </div>
                <div style={{paddingBottom: 10}}>
                  <Typography style={{marginTop: '2rem'}} variant="h4" gutterBottom>
                    <b>PURE EATS</b>
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Edit Profile
                  </Typography>
                </div>
              </div>
              <div className="col-5">
                <div style={{padding: 5, marginTop: '30%'}}>
                  {photo==='http://admin.pureeats.innull' && (
                    <img alt="avatar" onClick={()=>avatar.current.click()} src='/assets/img/logos/default.png' className="img-fluid" />
                  )}
                  {photo!=='http://admin.pureeats.innull' && (
                    <img alt="avatar" onClick={()=>avatar.current.click()} src={photo} className="img-fluid" />
                  )}

                  
                </div>
              </div>
            </div>

            <div className="row">
                <div className="col-sm-12 p-0">
                  <div style={{padding: 25}}>
                    <div className="row" style={{marginTop: 40, marginBottom: '1rem'}}>
                      <div className="col-sm-12">
                        <TextField disabled={true} value={name} onChange={(e)=>setName(e.target.value)} style={{marginBottom: 15, width: '100%'}} label="Display Name" />
                        <TextField disabled={true} value={email} onChange={(e)=>setEmail(e.target.value)} style={{marginBottom: 15, width: '100%'}} label="Email" />
                        <TextField disabled={true} value={mobile} onChange={(e)=>setMobile(e.target.value)} style={{marginBottom: 15, width: '100%'}} label="Mobile No." />

                        <input 
                            type="file" 
                            id="avatar" 
                            onChange={renderImagePreview}
                            ref={avatar} 
                            style={{display: "none"}}/>

                      </div>
                      <div className="col-sm-12">
                        <div style={{textAlign: 'center', width: '100%', marginBottom: 5}}>
                          <Button disabled={loading} onClick={()=>saveAccount()} style={{marginBottom: 20, marginTop: 40, fontSize: 11, height: 45, width: '100%', backgroundColor: '#bb1229', borderRadius: 0, color: 'white'}} variant="contained" color="primary">
                            {(loading)?"Please Wait...":"SAVE"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                
            </div>


        </div>
    
  );
}
