import React, {useState, useEffect, useRef} from "react";
 
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';
// import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Axios from 'axios';
import {GET_RESTAURANT_ITEMS_URL, GET_RESTAURANT_INFO_URL, ROOT_URL} from '../../../configs';
import Skeleton from '@material-ui/lab/Skeleton';
import Header from '../../../components/Layouts/Header';
import { useParams } from "react-router-dom";
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Grow from '@material-ui/core/Grow';
import EmptyCart from '../../../components/Loading/EmptyCart'
import { useHistory } from "react-router-dom";
import DirectionsOutlinedIcon from '@material-ui/icons/DirectionsOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default function RestaurantDetail(params){

    const { resSlug } = useParams();
    const history = useHistory();


    const topPicks = useRef(null)

    const [loading, setLoading] = useState(true);
    const [restaurant, setRestaurant] = useState({})
    const [recommended, setRecommended] = useState([]);
    const [arrayHolderRecommended, setArrayHolderRecommended] = useState([]);
    const [items, setItems] = useState([]);
    const [arrayHolderItems, setArrayHolderItems] = useState([]);
    const [scrolled, setScrolled] = useState(false);
    // const [vegOnly, setVegOnly] = useState(false);
    const [cart, setCart] = useState([]);
    const [search, setSearch] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(()=>{
      if(localStorage.getItem('cart') !== null){
        setCart(JSON.parse(localStorage.getItem('cart')))
      }
    },[])

    useEffect(()=>{
        Axios.post(GET_RESTAURANT_INFO_URL+'/'+resSlug)
        .then(res=>{
            console.log(res.data)
            setRestaurant(res.data)
        })
        .catch((e)=>console.log(e))
    }, [resSlug]);

    useEffect(()=>{
        Axios.post(GET_RESTAURANT_ITEMS_URL+'/'+resSlug)
        .then(res=>{
            // console.log(res.data)
            setItems(res.data.items)
            setRecommended(res.data.recommended)
            setArrayHolderItems(res.data.items)
            setArrayHolderRecommended(res.data.recommended)
            setLoading(false);
        })
        .catch((e)=>console.log(e))
    }, [resSlug]);

    useEffect(()=>{
      window.addEventListener('scroll', handleScroll, true);
      return function(){
        window.removeEventListener('scroll', handleScroll, true);
      }
    },[])

    // useEffect(()=>{
    //   if(vegOnly){
    //     setRecommended(arrayHolderRecommended.filter(i=>i.is_veg===1))

    //     let newItems = {};

    //     Object.keys(arrayHolderItems).map((keys)=>{
    //       return newItems[keys]=arrayHolderItems[keys].filter(i=>i.is_veg===1);
    //     })

    //     setItems(newItems)

    //   }else{
    //     setRecommended(arrayHolderRecommended)
    //     setItems(arrayHolderItems)
    //   }
    // },[arrayHolderItems, arrayHolderRecommended])

    const handleScroll = () => {
      let lastScrollY = window.scrollY;
      if(lastScrollY > 25){
        setScrolled(true);
      }
      if(lastScrollY === 0){
        setScrolled(false);
      }
    };

    // const addToCart=(item)=>{
    //   setCart([...cart, {...item, quantity: 1, cartPrice: item.price}])
    // }

    // // const removeFromCart=(item)=>{
    // //   let newItems = cart.filter((i)=>i.id !== item.id)
    // //   setCart([newItems])
    // // }

    // const incrementCart=(citem)=>{
    //   console.log(citem)
    //   let item = cart.filter((i)=>i.id === citem.id)[0]
    //   let newItems = cart.filter((i)=>i.id !== citem.id)
    //   item.quantity=item.quantity+1;
    //   item.cartPrice=parseInt(item.price)*(item.quantity+1)
    //   newItems.push(item)
    //   console.log(item)
    //   console.log(cart)
    //   setCart(newItems)
    // }

    // const decrementCart=(citem)=>{
    //   console.log(citem)
    //   let item = cart.filter((i)=>i.id === citem.id)[0]
    //   if (item.quantity === 1) {
    //     let newItems = cart.filter((i)=>i.id !== citem.id)
    //     setCart(newItems)
    //   }else{
    //     let newItems = cart.filter((i)=>i.id !== citem.id)
    //     item.quantity = item.quantity-1
    //     item.cartPrice=parseInt(item.price)*(item.quantity-1)
    //     newItems.push(item)
    //     setCart(newItems)
    //   }
    // }


    const addToCart=(item)=>{
      if(localStorage.getItem("cartRestaurant") !== null){
        if(JSON.parse(localStorage.getItem("cartRestaurant")).id !== restaurant.id){
          localStorage.removeItem('cart');
          localStorage.removeItem('cartRestaurant');
          setCart([{...item, quantity: 1, cartPrice: item.price}])
          localStorage.setItem('cartRestaurant', JSON.stringify(restaurant));
          localStorage.setItem('cart', JSON.stringify([{...item, quantity: 1, cartPrice: item.price}]));
          return;
        }
      }
      setCart([...cart, {...item, quantity: 1, cartPrice: item.price}])
      localStorage.setItem('cartRestaurant', JSON.stringify(restaurant));
      localStorage.setItem('cart', JSON.stringify([...cart, {...item, quantity: 1, cartPrice: item.price}]));
    }

    const incrementCart=(citem)=>{
      console.log(citem)
      let item = cart.filter((i)=>i.id === citem.id)[0]
      let newItems = cart.filter((i)=>i.id !== citem.id)
      item.quantity=item.quantity+1;
      item.cartPrice=parseInt(item.price)*(item.quantity)
      newItems.push(item)
      setCart(newItems)
      localStorage.setItem('cart', JSON.stringify(newItems));
    }

    const decrementCart=(citem)=>{
      console.log(citem)
      let item = cart.filter((i)=>i.id === citem.id)[0]
      if (item.quantity === 1) {
        let newItems = cart.filter((i)=>i.id !== citem.id)
        item.cartPrice=item.cartPrice - parseInt(item.price)
        setCart(newItems)
        localStorage.setItem('cart', JSON.stringify(newItems));
        if(newItems.length===0){
          localStorage.removeItem('cart');
        }
      }else{
        let newItems = cart.filter((i)=>i.id !== citem.id)
        item.quantity = item.quantity-1
        item.cartPrice=parseInt(item.price)*(item.quantity)
        newItems.push(item)
        setCart(newItems)
        localStorage.setItem('cart', JSON.stringify(newItems));
      }
    }

    function subTotal(){
      let total = 0;
      cart.map((i)=>total+=parseInt(i.cartPrice))
      console.log(total)
      return String(total).replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    }

    const searchItems=(text)=>{
      setSearch(text);

      let newData = arrayHolderRecommended.filter(item => {      
        let itemData = `${String(item.name).toUpperCase()}`;
        let textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;    
      });
      setRecommended(newData);

      let newItems = {};
      Object.keys(arrayHolderItems).map((keys)=>{
        let newData2 = newItems[keys]=arrayHolderItems[keys].filter(item=>{
          let itemData = `${String(item.name).toUpperCase()}`;
          let textData = text.toUpperCase();
          return itemData.indexOf(textData) > -1;    
        });
        return newItems[keys]=newData2;
      });
      setItems(newItems);
    }

    const copyText=()=>{
      navigator.clipboard.writeText(restaurant.address)
      alert("Address Copied.");
    }


    return (
        <div className="container-fluid p-0">

          <Header />

            <div className="row" style={{transition: 'all 0.5s cubic-bezier(0.4, 0, 1, 1) 0s', position: 'sticky', top: 0,zIndex: 9, padding: (!scrolled)?20:10, backgroundColor: 'rgb(240 29 59)'}}>
                <div className="col-md-3">
                  <div style={{textAlign: 'center', padding: (!scrolled)?10:0}}>
                    <img style={{width: (!scrolled)?'80%':'140px'}} alt="logo" src={ROOT_URL+restaurant.image} />
                  </div>
                </div>
                <div className="col-md-9">
                  <div style={{padding: 10, color: 'white'}}>
                    <Typography style={{color: 'white'}} variant={(!scrolled)?'h3':'h5'}>
                      {restaurant.name}
                    </Typography>

                    {!scrolled && (
                    <><Typography variant="body2">
                      {restaurant.description}
                    </Typography>
                    <Typography variant="body2">
                      {restaurant.address}
                    </Typography></>
                    )}

                    <div className="row" style={{marginTop: (!scrolled)?24:8}}>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-12">
                            <input 
                              className="form-control" variant="standard" 
                              value={search}
                              onChange={(e)=>searchItems(e.target.value)}
                              style={{display: 'inline', width: '100%', backgroundColor: 'white', borderRadius: 0}} 
                              placeholder="Search for dishes..."
                              label="Search for dishes..." />
                          </div>
                          <div className="col-md-12 mt-3">
                            <Button
                              style={{backgroundColor: 'white', borderRadius: 0}}
                              variant="contained"
                              color="default"
                              size="small"
                              className="ml-1 mr-1"
                              onClick={()=>copyText()}
                              startIcon={<CheckBoxOutlineBlank />}
                            >
                              Copy Address
                            </Button>
                            <Button
                              style={{backgroundColor: 'white', borderRadius: 0}}
                              variant="contained"
                              color="default"
                              size="small"
                              className="ml-1 mr-1"
                              onClick={()=>window.location = (`http://maps.google.com/maps?saddr=${restaurant.latitude},${restaurant.longitude}&daddr=${JSON.parse(localStorage.getItem('userLat'))},${JSON.parse(localStorage.getItem('userLng'))}`)}
                              startIcon={<DirectionsOutlinedIcon />}
                            >
                              Direction
                            </Button>
                            <Button
                              style={{backgroundColor: 'white', borderRadius: 0}}
                              variant="contained"
                              color="default"
                              size="small"
                              className="ml-1 mr-1"
                              onClick={()=>{window.location = (`tel:${restaurant.contact_number}`)}}
                              startIcon={<CallOutlinedIcon />}
                            >
                              Call
                            </Button>
                          </div>
                        </div>

                      </div>

                      <div className="col-md-2">
                        <div>
                          <Typography style={{color: 'white'}} variant='h6'><StarIcon /> {restaurant.rating}</Typography>
                          <Typography style={{color: '#ffffffba'}} variant="caption">Ratings</Typography>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div>
                          <Typography style={{color: 'white'}} variant='h6'>₹ {restaurant.price_range}</Typography>
                          <Typography style={{color: '#ffffffba'}} variant="caption">For Two</Typography>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div>
                          <Typography style={{color: 'white'}} variant='h6'>{restaurant.msg}</Typography>
                          <Typography style={{color: '#ffffffba'}} variant="caption">Delivery Time</Typography>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
                <div className="col-md-3">
                </div>
            </div>

            <div className={(restaurant.is_open===0 || restaurant.is_active===0)?'row page-inactive':'row'}>
                
                <div className="col-md-8">
                    <div style={{padding: 25}}>
                        <h4 ref={topPicks} className={'mb-3'}><b>Recommended</b></h4>

                        {loading && (
                        <div className="row">
                            {[...Array(4)].map((i)=>{
                                return <div className="col-md-6">
                                    <div className="mb-3">
                                      <Skeleton variant="rect" height={150} />
                                      <Skeleton variant="text" style={{marginTop: 5}} />
                                      <Skeleton variant="text" width={100} />
                                    </div>
                                </div>
                            })}
                        </div>
                        )}

                        {recommended.length > 0 && (
                        <div className="row">
                            {/*eslint-disable-next-line*/}
                            {recommended.map((item)=>{
                              return <Grow in={true}><div key={item.id} className={item.is_active!==1?'col-md-3 page-inactive':'col-md-3'}>
                                  <div className="p-3">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <CardActionArea>
                                            {item.image===null && (
                                              <img className="img-fluid page-inactive" src="/assets/img/logos/default.png" alt="PureEats" style={{width: '100%', maxHeight: 180, borderRadius: 8}} />
                                            )}
                                            {item.image!==null && (
                                              <img src={ROOT_URL+item.image} alt="PureEats" style={{width: '100%', maxHeight: 180, borderRadius: 8}} />
                                            )}

                                            {item.is_veg===1 && (
                                              <img alt="..." src="/assets/img/various/veg-icon.png" style={{width: 21, height: 21, position: 'absolute'}} />
                                            )}
                                            {item.is_veg!==1 && (
                                              <img alt="..." src="/assets/img/various/non-veg-icon.png" style={{width: 21, height: 21, position: 'absolute'}} />
                                            )}
                                            <Typography gutterBottom variant="body1">
                                              {item.name}
                                            </Typography>
                                          
                                          </CardActionArea>

                                          <CardContent>
                                            <div className="row">
                                              <div className="col-6">
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                  ₹ {item.price}
                                                </Typography>
                                              </div>
                                              <div className="col-6">
                                                {cart.length === 0 && (
                                                  <Button onClick={()=>addToCart(item)} style={{
                                                    width: 80,
                                                    marginLeft: 'auto',
                                                    background: 'white',
                                                    color: 'green',
                                                    borderRadius: 0,
                                                  }} variant="contained">ADD</Button>
                                                )}

                                                {cart.filter(i => i.id !== item.id).length !== 0 && (
                                                  <Button onClick={()=>addToCart(item)} style={{
                                                    width: 80,
                                                    marginLeft: 'auto',
                                                    background: 'white',
                                                    color: 'green',
                                                    borderRadius: 0,
                                                  }} variant="contained">ADD</Button>
                                                )}

                                                {cart.some(i => i.id === item.id) && (
                                                  <div className="MuiButton-contained" style={{
                                                    width: 80,
                                                    marginLeft: 'auto',
                                                    background: 'white',
                                                    color: 'green',
                                                    borderRadius: 0,
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    padding: 3,
                                                  }}>
                                                    <IconButton onClick={()=>decrementCart(item)} style={{float: 'left'}} size="small" aria-label="delete">
                                                      <RemoveIcon />
                                                    </IconButton>
                                                    <p style={{paddingLeft: 7,marginTop: 3,display: 'inline-block',marginBottom: 0}}>{cart.filter(a=>a.id===item.id)[0].quantity}</p>
                                                    <IconButton onClick={()=>incrementCart(item)} style={{float: 'right'}} size="small" aria-label="plus">
                                                      <AddIcon />
                                                    </IconButton>
                                                  </div>
                                                )}

                                            </div>

                                            </div>
                                            

                                          </CardContent>

                                        </div>
                                      </div>
                                  </div>
                              </div></Grow>
                            })}
                        </div>
                        )}

                        {recommended.length > 0 && (
                        <>
                          {Object.keys(items).map((keys)=>{
                            return (<div className="row"><div className="col-12"><h4 className={'m-3 pt-3'}><b>{keys}</b></h4></div>
                              {items[keys].map((i)=>{
                                return (<div key={i.id} className={i.is_active!==1?'col-md-3 page-inactive':'col-md-3'}>
                                    <div className="p-3 dashed-bottom">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <CardActionArea>
                                              {i.image===null && (
                                                <img className="img-fluid page-inactive" src="/assets/img/logos/default.png" alt="PureEats" style={{width: '100%', maxHeight: 180, borderRadius: 8}} />
                                              )}
                                              {i.image!==null && (
                                                <img src={ROOT_URL+i.image} alt="PureEats" style={{width: '100%', maxHeight: 180, borderRadius: 8}} />
                                              )}

                                              {cart.length === 0 && (
                                                <Button onClick={()=>addToCart(i)} style={{
                                                  width: 80,
                                                  marginLeft: 'auto',
                                                  position: 'absolute',
                                                  bottom: 5,
                                                  left: '24%',
                                                  background: 'white',
                                                  color: 'green',
                                                  borderRadius: 0,
                                                }} variant="contained">ADD</Button>
                                              )}
                                              {cart.filter(a => a.id !== i.id).length !== 0 && (
                                                <Button onClick={()=>addToCart(i)} style={{
                                                  width: 80,
                                                  marginLeft: 'auto',
                                                  position: 'absolute',
                                                  bottom: 5,
                                                  left: '24%',
                                                  background: 'white',
                                                  color: 'green',
                                                  borderRadius: 0,
                                                }} variant="contained">ADD</Button>
                                              )}

                                              {cart.some(a => a.id === i.id) && (
                                              <div className="MuiButton-contained" style={{
                                                width: 80,
                                                marginLeft: 'auto',
                                                position: 'absolute',
                                                bottom: 5,
                                                left: '24%',
                                                background: 'white',
                                                color: 'green',
                                                borderRadius: 0,
                                                padding: 3,
                                              }}>
                                                <IconButton onClick={()=>decrementCart(i)} style={{float: 'left'}} size="small" aria-label="delete">
                                                  <RemoveIcon />
                                                </IconButton>
                                                  <p style={{paddingLeft: 7,marginTop: 3,display: 'inline-block',marginBottom: 0}}>{cart.filter(a=>a.id===i.id)[0].quantity}</p>
                                                <IconButton onClick={()=>incrementCart(i)} style={{float: 'right'}} size="small" aria-label="plus">
                                                  <AddIcon />
                                                </IconButton>
                                              </div>
                                              )}
                                            </CardActionArea>

                                            <CardContent>
                                              {i.is_veg===1 && (
                                                <img alt="..." src="/assets/img/various/veg-icon.png" style={{width: 21, height: 21}} />
                                              )}
                                              {i.is_veg!==1 && (
                                                <img alt="..." src="/assets/img/various/non-veg-icon.png" style={{width: 21, height: 21}} />
                                              )}
                                              <Typography gutterBottom variant="h5" component="h2">
                                                {i.name}
                                              </Typography>
                                              <Typography variant="body2" color="textSecondary" component="p">
                                                ₹ {i.price}
                                              </Typography>
                                            </CardContent>

                                          </div>
                                        </div>
                                    </div>
                                </div>)
                            })}</div>)
                          })}
                        </>
                        )}

                    </div>
                </div>

                <div className="col-md-4" style={{borderLeft: '1px solid #b7b7b7', minHeight: 540, position: 'sticky', top: 138,zIndex: 9,height: 'calc(100vh - 197px)'}}> 
                  <div style={{padding: 20}}>
                    <h4 className={'mb-0'}><b>Cart</b></h4>
                    <Typography variant="caption" color="textSecondary">
                      {cart.length} ITEM
                    </Typography>

                    {cart.length!==0 && (
                      <><div style={{maxHeight: 210,overflow: 'scroll'}}>{cart.map((item)=>{
                        return <>
                        <div className="row" style={{marginTop: 15}}>
                          <div className="col-md-5">
                            {item.is_veg===1&&(
                              <img alt="..." className="mr-2" src="/assets/img/various/veg-icon.png" style={{width: 18, height: 18, display: 'inline'}} />
                            )}
                            {item.is_veg!==1&&(
                              <img alt="..." className="mr-2" src="/assets/img/various/non-veg-icon.png" style={{width: 18, height: 18, display: 'inline'}} />
                            )}
                            <Typography variant="caption" color="textSecondary">
                              {item.name}
                            </Typography>
                          </div>
                          <div className="col-md-4">
                            <div className="MuiButton-contained" style={{
                              width: 80,
                              background: 'white',
                              color: 'green',
                              borderRadius: 0,
                              padding: 3,
                            }}>
                              <IconButton onClick={()=>decrementCart(item)} style={{float: 'left'}} size="small" aria-label="delete">
                                <RemoveIcon />
                              </IconButton>
                                <p style={{paddingLeft: 7,marginTop: 3,display: 'inline-block',marginBottom: 0}}>{cart.filter(a=>a.id===item.id)[0].quantity}</p>
                              <IconButton onClick={()=>incrementCart(item)} style={{float: 'right'}} size="small" aria-label="plus">
                                <AddIcon />
                              </IconButton>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <Typography variant="caption" color="textSecondary">
                              ₹ {String(item.cartPrice).replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")}
                            </Typography>
                          </div>
                        </div>
                        </>
                      })}

                      </div>

                      <div className="row" style={{marginTop: 20}}>
                        <div className="col-md-8">
                          <Typography variant="h6" style={{marginBottom: 0}}>
                            Subtotal
                          </Typography>
                          <Typography className="mt-0" variant="caption" color="textSecondary">
                            Extra charges may apply
                          </Typography>
                        </div>
                        <div className="col-md-4">
                          <Typography style={{textAlign: 'center'}} variant="h6">
                            ₹ {subTotal()}
                          </Typography>
                        </div>
                      </div>
                      

                      <div className="row">
                        <div className="col-md-12">
                          <Button 
                            style={{width:'100%', height: 45, backgroundColor: 'green', color: 'white', borderRadius: 0, marginTop: 15}} 
                            variant="contained"
                            onClick={()=>history.push('/cart')}
                            >Checkout</Button>
                        </div>
                      </div></>
                    )}

                    {cart.length===0 && (
                      <div className="text-center page-inactive">
                        <EmptyCart />
                        <Typography variant="caption">Good food is always cooking! <br/>Go ahead, order some yummy items from the menu.</Typography>
                      </div>
                    )}


                  </div>

                </div>
            </div>

            <Fab aria-controls="restaurant-menu" aria-haspopup="true" onClick={(e)=>setAnchorEl(e.currentTarget)} style={{position: 'fixed',left: '40%',height: '30px', bottom:10}} variant="extended" color="secondary" aria-label="Menu">
              Menu
            </Fab>
            <Menu
              id="restaurant-menu"
              anchorEl={anchorEl}
              keepMounted
              style={{left: '0px'}}
              open={Boolean(anchorEl)}
              onClose={(e)=>setAnchorEl(null)}
            >
              {Object.keys(items).map((keys)=>{
                return (<MenuItem style={{borderBottom: '1px dashed grey'}} onClick={(e)=>setAnchorEl(null)}>{keys}</MenuItem>)
              })}
            </Menu>

            {!loading && restaurant.length === 0 && (
            <div className="row">
                <div className="col-md-12">
                    <div style={{padding: 50, textAlign: 'center'}}>
                        <img alt="slider" style={{width: 345}} src="https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/connection_error_bsppck" />
                        <h4 className={'mb-2'}>Oh! All restaurants are <br /> currently unserviceable</h4>
                        <p className={'text-muted'}>All restaurants are unserviceable, check back in some time.</p>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
}


