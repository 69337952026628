import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory, Redirect } from "react-router-dom";
// import GoogleMap from "../../../components/GeoLocation/Map";
// import {GET_ADDRESS_FROM_COORDINATES} from '../../../configs';
// import Axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Landing() {
  const classes = useStyles();
  const history = useHistory();

  // const [google_script_loaded, setgoogle_script_loaded] = useState(true);
  // const [address, setAddress] = useState('');
  // const [selectedAddress, setSelectedAddress] = useState('');
  // const [gpsLoading, setGpsLoading] = useState(false);


  // useEffect(()=>{
  //   const existingScript = document.getElementById("googleMaps");
  //   if (!existingScript) {
  //     const script = document.createElement("script");
  //     script.src =
  //       "https://maps.googleapis.com/maps/api/js?key=AIzaSyCt_14My2CYghVw6eZFSYFlFPBOK29lkww&libraries=places";
  //     script.id = "googleMaps";
  //     document.body.appendChild(script);
  //     script.onload = () => {
  //       setgoogle_script_loaded(true)
  //     };
  //   }
  // },[])

  // const getMyLocation = () => {
  //   const location = navigator && navigator.geolocation;
  //   console.log("LOCATION", location);
  //   setGpsLoading(true);
  //   if (location) {
  //     location.getCurrentPosition(
  //       position => {
  //         reverseLookup(position.coords.latitude, position.coords.longitude);
  //       },
  //       error => {
  //         setGpsLoading(false);
  //         console.log(error);
  //         // this.setState({locationModal: true});
  //         // alert(localStorage.getItem("gpsAccessNotGrantedMsg"));
  //       }
  //     );
  //   }
  // };

  // const reverseLookup = (lat, lng) => {
  //   Axios.post(GET_ADDRESS_FROM_COORDINATES, {
  //     lat: lat,
  //     lng: lng
  //   })
  //     .then(response => {
  //       alert(response.data);
  //       const myLocation = [
  //         {
  //           formatted_address: response.data,
  //           geometry: {
  //             location: {
  //               lat: lat,
  //               lng: lng
  //             }
  //           }
  //         }
  //       ];
  //       console.log(myLocation)
  //       setGpsLoading(false);
  //       // this.handleGeoLocationClick(myLocation);
  //     })
  //     .catch(function(error) {
  //       console.warn(error.response.data);
  //     });
  // };
  
  if(localStorage.getItem('userLng') !== null ){
    return(<Redirect to="restaurants" />)
  }

  return (
        <div className="container-fluid">
            <div className="row">
                
                <div className="col-sm-12 col-md-6">
                  <div className={classes.root}>
                    <img style={{width: '90%', height: 'auto'}} src="/assets/img/android/bg_setup_location.jpg" alt="PureEats" className="footer-logo m-3" />
                  </div>
                </div>

                <div className="col-sm-12 col-md-6" style={{marginTop: '20%'}}>

                  <div style={{padding: 10, textAlign: 'center'}}>
                    <Typography style={{fontSize: '2rem', marginBottom: 0, lineHeight: '28px'}} variant="h6" gutterBottom>
                      Hi, nice to meet you!!
                    </Typography>
                    <Typography style={{fontSize: '0.89rem'}} variant="caption" gutterBottom>
                      {' '}set your location to start exploring
                      restaurants near you
                    </Typography>
                      <Button onClick={()=>history.push('/setLocation')} style={{marginTop: 10, fontSize: 11, height: 45, width: '100%', backgroundColor: '#bb1229', borderRadius: 0, color: 'white'}} variant="contained" color="primary">
                        Set Your Location Manually
                      </Button>
                  </div>

                  <div style={{padding: 10, textAlign: 'center'}}>
                    <Typography style={{color: '#9a9a9a', fontSize: 10}} variant="caption" gutterBottom>
                      We onlyaccept your location while you are yousing the website to improve your experience
                    </Typography>
                  </div>

                </div>
                
            </div>


        </div>
    
  );
}

