import React, {useState, useEffect} from "react";

import Typography from '@material-ui/core/Typography';
import {GET_ADDRESSES_URL,DELETE_ADDRESS_URL} from '../../../../configs';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Axios from 'axios';
// import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MyLocationOutlinedIcon from '@material-ui/icons/MyLocationOutlined';
// import TextField from '@material-ui/core/TextField';
import Flip from "react-reveal/Flip";
import PlacesAutocomplete from 'react-places-autocomplete';
import RoomOutlined from '@material-ui/icons/RoomOutlined';
// import MapMarkerIcon from '@material-ui/icons/MyLocation';
import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function MyAddress(params){

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [myAddress, setMyAddress] = useState([]);
  const [address, setAddress] = useState('');
  // const [gpsLoading, setGpsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [manageAddr, setManageAddr] = useState({});

  useEffect(()=>{
      loadAddress()
  }, []);

  function loadAddress(){
    let user = JSON.parse(localStorage.getItem('user'))
    Axios.post(GET_ADDRESSES_URL, {token:user.auth_token, user_id: user.id})
    .then(res=>{
      console.log(res)
      setMyAddress(res.data);
      setLoading(false);
    })
    .catch(e=>console.log(e));
  } 

  const getMyLocation = () => {
    history.push('setLocation');
    return;
    // const location = navigator && navigator.geolocation;
    // console.log("LOCATION", location);
    // setGpsLoading(true);
    // if (location) {
    //   location.getCurrentPosition(
    //     position => {
    //       reverseLookup(position.coords.latitude, position.coords.longitude);
    //     },
    //     error => {
    //       setGpsLoading(false);
    //       console.log(error);
    //       // this.setState({locationModal: true});
    //       // alert(localStorage.getItem("gpsAccessNotGrantedMsg"));
    //     }
    //   );
    // }
  };

  // const reverseLookup = (lat, lng) => {
  //   Axios.post(GET_ADDRESS_FROM_COORDINATES, {
  //     lat: lat,
  //     lng: lng
  //   })
  //     .then(response => {
  //       alert(response.data);
  //       const myLocation = [
  //         {
  //           formatted_address: response.data,
  //           geometry: {
  //             location: {
  //               lat: lat,
  //               lng: lng
  //             }
  //           }
  //         }
  //       ];
  //       console.log(myLocation)
  //       setGpsLoading(false);
  //       // this.handleGeoLocationClick(myLocation);
  //     })
  //     .catch(function(error) {
  //       console.warn(error.response.data);
  //     });
  // };



  const handleChange = address => {
    setAddress(address);
  };
 
  const handleSelect = address => {
    setAddress(address);
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        console.log('Success', latLng)
        // localStorage.setItem("userLat", latLng.lat);
        // localStorage.setItem("userLng", latLng.lng);
        // localStorage.setItem("address", address);
        history.push('setLocation', {place: true, address, lat:latLng.lat, lng:latLng.lng});
        // history.goBack();
        // window.location.reload();
      })
      .catch(error => console.error('Error', error));
  };

  const deleteAddress=()=>{
    let user = JSON.parse(localStorage.getItem('user'))
    Axios.post(DELETE_ADDRESS_URL, {token:user.auth_token, user_id: user.id, address_id:manageAddr.id})
    .then(res=>{
      loadAddress();
      setOpen(false);
    })
    .catch(e=>console.log(e));
  }

  const initManageAddr=(type, item)=>{
    item.type=type;
    setManageAddr(item);
    setOpen(true);
  }

  const proceedManageAddr=()=>{
    if(manageAddr.type==='delete'){
      deleteAddress()
    }

    if(manageAddr.type==='replace'){
      let user = JSON.parse(localStorage.getItem('user'))
      
      localStorage.setItem('userLat', manageAddr.latitude)
      localStorage.setItem('userLng', manageAddr.longitude)
      localStorage.setItem('address', manageAddr.address)

      user.default_address = manageAddr;
      user.default_address_id = manageAddr.id;
      localStorage.setItem("user", user);

      history.goBack();
    }

    if(manageAddr.type==='edit'){
      // localStorage.setItem('userLat', manageAddr.latitude)
      // localStorage.setItem('userLng', manageAddr.longitude)
      // localStorage.setItem('address', manageAddr.address)
      // history.goBack();
      history.push('setLocation', {edit:true, manageAddr});
    }

  }

  return (
    <div className="container-fluid">

        {loading && (
        <div className="row">
            {[...Array(5)].map((i)=>{
                return <div className="col-md-12">
                    <div className="mb-3 bg-white p-3">
                      <Skeleton variant="rect" height={64} />
                      <Skeleton variant="text" style={{marginTop: 5}} />
                      <Skeleton variant="text" width={100} />
                      <Skeleton variant="text" width={50} />
                    </div>
                </div>
            })}
        </div>
        )}

        <div className="row bg-white">
          <div className="col-12 p-0">
            <PlacesAutocomplete
              onChange={(val)=>handleChange(val)}
              onSelect={(val)=>handleSelect(val)}
              value={address}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <input 
                    className="form-control" 
                    style={{display: 'inline', width: '100%', border: 'none', height: 41}} 
                    {...getInputProps({
                      placeholder: "Search for area, street name...",
                    })}
                    id="search"
                    size="small"
                    name="search" />

                  <div style={{padding: 1, width:'100%'}} className="autocomplete-dropdown-container">
                    <div className="MuiPaper-root MuiCard-root jss178 MuiPaper-rounded" style={{background: 'white', position: 'absolute', zIndex: 9999, padding: 5}}>
                      {loading && 
                        <div>
                          <img
                            src="/assets/img/various/spinner.svg"
                            className="location-loading-spinner"
                            alt="loading"
                          />
                        </div>
                      }

                      {suggestions.map((suggestion, index) => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <Flip top delay={index * 50} key={suggestion.id}>
                              <div
                                className="location-suggestion"
                                style={{padding: 10, borderBottomWidth: 1, borderBottom: 'daashed'}}
                              >
                                <RoomOutlined style={{marginRight: 5}} />
                                <span className="location-main-name">
                                  {suggestion.description}
                                </span>
                              </div>
                            </Flip>
                          </div>
                        );
                      })}
                    </div>

                  </div>
                </div>
              )}
            </PlacesAutocomplete>

            <List component="nav" aria-label="...">
              <ListItem onClick={()=>getMyLocation()} style={{borderTop: '1px solid #afafaf',borderBottom: '1px solid'}} button>
                <ListItemIcon>
                  <MyLocationOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Current Location" secondary="Enable location services" />
              </ListItem>
            </List>

          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <Typography className="ml-2 mt-3 mb-3 text-dark" variant="body1">
              <b>Saved Address</b>
            </Typography>
          </div>

          {myAddress.map((item)=>{
            return <div className="col-6 mb-3">
            <div className="bg-white" style={{borderBottom: '1px solid #bfbfbf',borderRadius: 0}}>
              <CardContent>
                <div className="row" >
                  <div className="col-12">
                    <Typography variant="h6" component="h2">
                      {item.tag}
                      <span style={{fontSize: 12}} className="float-right">{item.house}</span>
                    </Typography>
                    <Typography variant="caption" style={{color: '#585858'}}>
                      {item.address}
                    </Typography>
                  </div>
                </div>

              </CardContent>
              <CardActions>
                <Button onClick={()=>initManageAddr('delete', item)} style={{color: 'red'}} size="small">Delete</Button>
                <Button onClick={()=>initManageAddr('edit', item)} style={{color: 'rebeccapurple'}} size="small">Edit</Button>
                <Button onClick={()=>initManageAddr('replace', item)} style={{color: 'green'}} size="small">Replace</Button>
              </CardActions>
            </div>
            </div>
          })}
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-12">
            <Button onClick={()=>history.push('setLocation')} color="secondary" size="small">+ Add New Address</Button>
          </div>
        </div>


        <Dialog
          open={open}
          onClose={()=>setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Are You Sure !</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {manageAddr.type==='delete' && (
                <>You are about to delete the saved address. Do you really want to proceed ?</>
              )}
              {manageAddr.type==='replace' && (
                <>You are about to change the default address. Do you really want to proceed ?</>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>setOpen(false)} color="primary">
              No
            </Button>
            <Button onClick={()=>proceedManageAddr()} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

    </div>
  );
}

