import React from "react";

import Typography from '@material-ui/core/Typography';
// import {ROOT_URL} from '../../../configs';
import { useHistory } from "react-router-dom";
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
// import StarOutlineOutlinedIcon from '@material-ui/icons/StarOutlineOutlined';
// import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

export default function AboutUs(params){

  const history = useHistory();

  return (
    <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <IconButton onClick={()=>history.goBack()} aria-label="back">
              <KeyboardBackspaceIcon style={{marginRight: 5}} />
            </IconButton>
            <Typography className="ml-2 mb-2" color="text-secondary" variant="h6">
            <b>About Us</b>
            </Typography>
          </div>
        </div>

        
        <div className="row" style={{backgroundColor: '#dedede'}}>
          <div className="col-12">
          <br />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="mt-3 p-1 bg-white">
              <div style={{padding: 5, textAlign: 'justify'}}>
                <Typography variant="body1">
                Welcome to PURE EATS, one source for all things & 
                all items of online food delivery. We're dedicated to
                 giving you the very best of foods, with a focus on three 
                 characteristics, i.e.<br />
                 </Typography>

                 <div class="row col-12 text-center mt-3">
                    <div class="row">
                       <div class="col-4 p-0">
                          <div style={{padding: '0.5rem', boxShadow: '0 10px 20px 0 rgba(0,0,0,.05)'}}>
                             <img src="/assets/img/dish.png" alt="Dependability" style={{width:'54px',height:'54px'}} />
                             <small className="text-muted">Dependability</small>
                          </div>
                       </div>
                       <div class="col-4 p-0">
                          <div style={{padding: '0.5rem', boxShadow: '0 10px 20px 0 rgba(0,0,0,.05)'}}>
                             <img src="/assets/img/leaf.png" alt="Customer" style={{width:'54px',height:'54px'}} />
                             <small className="text-muted">Customer Service</small>
                          </div>
                       </div>
                       <div class="col-4 p-0">
                          <div style={{padding: '0.5rem', boxShadow: '0 10px 20px 0 rgba(0,0,0,.05)'}}>
                             <img src="/assets/img/shop.png" alt="Uniqueness" style={{width:'54px',height:'54px'}} />
                             <small className="text-muted">Uniqueness</small>
                          </div>
                       </div>
                    </div>
                 </div>

                 <br /><br />

                <Typography variant="body1">

                When MINTU KUMAR JHA first started out, his passion for 
                helping Customers to be more eco-friendly, providing the 
                best equipment for his fellow company drove him to to do 
                intense research, quit his day job, and gave him the impetus to 
                turn hard work and inspiration into to a booming online food 
                delivery, We now serve customers all over in Howrah District & 
                some part of Hooghly district i.e.: Howrah, Liluah ,  Belur , 
                Bally, Uttarpara, Hindmotor, Konnagar, Rishra etc. 
                and  they are thrilled to be a part of the  quirky, eco-friendly, fair trade.<br /><br />

                <br />We hope you enjoy our foods as much as we enjoy offering 
                them to you. If you have any questions or comments, please 
                contact us to.<br /><br />

                <b>Send the written communication to:-</b><br />
                For users residing in the India: -<br />
                Founded in 2020 by,<br /><br />

                Flat no 307 SAPUIPARA, near Mota shiv Mandir ( Rakhi Factory)<br />
                Bally Howrah , Pin: -711227.<br />
                State: -West Bengal,<br />
                Email: - help@pureeats.in<br />
                Customer support number - 18001238508<br />

                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{backgroundColor: '#dedede'}}>
          <div className="col-12">
          <br />
          </div>
        </div>

    </div>
  );
}

