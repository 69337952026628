import React, {useState, useEffect} from "react";

import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';
// import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Axios from 'axios';
import {SEARCH_RESTAURANTS_URL, UPDATE_USER_INFO_URL, GET_DELIVERY_RESTAURANTS_URL, GET_PROMO_SLIDER_URL, GET_SELFPICKUP_RESTAURANTS_URL, ROOT_URL} from '../../../configs';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from "react-router-dom";
import Grow from '@material-ui/core/Grow';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasketOutlined';
import MoreHorizontalIcon from '@material-ui/icons/MoreHorizOutlined';
import RoomOutlined from '@material-ui/icons/RoomOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import TuneOutlined from '@material-ui/icons/TuneOutlined';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Modal from '@material-ui/core/Modal';
// import Fade from '@material-ui/core/Fade'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Slider from "react-slick";
// import "react-slick/slick/slick.css";
// import "react-slick/slick/slick-theme.css";

const SliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: false,
  button: false,
  autoplay: true,
  autoplaySpeed: 3000,
};

export default function Restaurants(){

    const history = useHistory();

    const [loading, setLoading] = useState(true);
    // const [user, setUser] = useState({});
    const [restaurants, setRestaurants] = useState([]);
    const [arrayHolder, setArrayHolder] = useState([]);
    const [sliders, setSliders] = useState([]);
    const [search, setSearch] = useState('');
    const [filterModal, setFilterModal] = useState(false);
    const [sort, setSort] = useState('');
    const [type, setType] = useState('delivery');
    // const [matrixTimes, setMatrixTimes] = useState([]);
    const [searchResult, setSearchResult] = useState({});
    const [value, setValue] = useState(0);

    const findRestaurants = (type='delivery') => {
      let u = (type==='delivery')?GET_DELIVERY_RESTAURANTS_URL:(type==='self')?GET_SELFPICKUP_RESTAURANTS_URL:'';
      
      setLoading(true);
        
      let userLat = localStorage.getItem('userLat')
      let userLng = localStorage.getItem('userLng')
      
      Axios.post(u, {
        latitude: userLat,
        longitude: userLng
      })
      .then(res=>{
        setLoading(false);
        
        if(res.data.length===0){
          return;
        }
        setRestaurants(res.data)
        setArrayHolder(res.data)

        localStorage.setItem('delivery_type', type)

        setType(type)
      })
      .catch((e)=>console.log(e))
    }

    useEffect(() => {
      console.log('useEffect---', localStorage.getItem('delivery_type'))

      Axios.post(GET_PROMO_SLIDER_URL)
      .then(res=>{
        setSliders(res.data.mainSlides)
      })
      .catch(e => console.log(e));

      let userl = JSON.parse(localStorage.getItem('user'))
      
      findRestaurants((localStorage.getItem('delivery_type') || 'delivery'));

      if(userl!==null && userl!=="[object Object]"){
        Axios.post(UPDATE_USER_INFO_URL, {token:userl.auth_token, user_id: userl.id})
        .then(res=>{
          // setUser(res.data.data);
          userl.wallet_balance=res.data.data.wallet_balance;
          localStorage.setItem('user', JSON.stringify(userl))

        })
        .catch(e=>console.log(e));
      }

    }, [])
    
    useEffect(()=>{
      // async function calculateTime(){
      //   let userLat = localStorage.getItem("userLat"); 
      //   let userLng = localStorage.getItem("userLng");
      //   let i = 0;
      //   let urllist = [];
      //   for(i;i< restaurants.length;i++){
      //     if(restaurants[i].is_open===1){
      //       if(type === 'delivery') {
      //         var target_url = `https://admin.pureeats.in/googleDirectionCall?olat=${userLat}&olng=${userLng}&dlat=${restaurants[i].latitude}&dlng=${restaurants[i].longitude}`;
      //         const response = await fetch(`${target_url}`)
      //         const json = await response.json()
      //         // urllist.push(json)

      //         let dt = Math.round(Number(restaurants[i].delivery_time)+(json.rows[0].elements[0].duration.value / 60));
      //         urllist.push({...restaurants[i], delivery_time: String(dt)+' min'})
      //       }

      //     }else{
      //       urllist.push({...restaurants[i], delivery_time: 'n/a min'})
      //     }
      //     // console.log(urllist);
      //     if(urllist.length===restaurants.length){
      //       // console.log('------------urllist');
      //       // console.log(urllist);
      //       setRestaurants(urllist);
      //       // setArrayHolder(urllist);
      //     }
      //   }
      //   setLoading(false);
      // }
      // if (arrayHolder.length!==0) {
      //   calculateTime()
      // }
    // eslint-disable-next-line 
    },[arrayHolder])

    const searchItems=(text)=>{
      setSearch(text);

      if(text==='') return;
      
      Axios.post(SEARCH_RESTAURANTS_URL, {q:text,latitude: localStorage.getItem('userLat'), longitude: localStorage.getItem('userLng'),})
      .then(res=>{
        let newData = {};
        newData.items = res.data.items.filter(i=>i.restaurant.is_active===1)
        newData.restaurants = res.data.restaurants.filter(i=>i.is_active===1)
        setSearchResult(newData)
      })

    }

    const sortRestaurants=()=>{
      if (sort==='sortRelevance') {
        setRestaurants(arrayHolder);
      }
      if(sort==='sortCost'){
        let newData = arrayHolder.sort((a, b) => parseInt(a.price_range) > parseInt(b.price_range) ? 1 : -1)
        setRestaurants(newData);
      }
      if(sort==='sortDelivery'){
        // let newData = arrayHolder.sort((a, b) => parseInt(a.price_range) > parseInt(b.price_range) ? 1 : -1)
        setRestaurants(arrayHolder);
      }
      if(sort==='sortRating'){
        let newData = arrayHolder.sort((a, b) => parseInt(a.rating) > parseInt(b.rating) ? 1 : -1)
        setRestaurants(newData);
      }
      setFilterModal(false)
    }


    return (
        <div className="container-fluid">

            <div className="row" style={{marginBottom: '5rem'}}>
                
                <div className="col-md-9">
                    <div>
                        <div className="row" style={{padding: 10}}>
                          <div className="col-1 p-0">
                            <RoomOutlined className="mt-1" />
                          </div>
                          <div className="col-11 p-0" onClick={()=>(localStorage.getItem('user')===null || localStorage.getItem('user')==="null")?history.push('setLocation'):history.push('manageAddress')}>
                            <Typography style={{color: '#2e4db3', display: 'inline'}} variant="h6" className={'mb-0'}><b>HOME</b></Typography>
                            <img alt="..." src="/assets/img/android/arrow.png" style={{width: 16, marginLeft: 8}} /><br />
                            <Typography variant="caption" className={'mb-3'}>{localStorage.getItem("address")}</Typography>
                          </div>
                        </div>

                        <FormControl style={{width: '100%', margin: 5, border: '1px solid #b5b5b5'}}>
                          <Input
                            style={{padding: 9}}
                            id="search"
                            placeholder="Search for restaurants, cusines..."
                            value={search}
                            onChange={(e)=>searchItems(e.target.value)}
                            endAdornment={
                              <InputAdornment position="end">
                                <SearchOutlined style={{marginRight: 13}} />
                              </InputAdornment>
                            }
                          />
                        </FormControl>

                        {search!=='' && (
                          <>
                            {searchResult.restaurants!==undefined && (
                              <>{searchResult.restaurants.map((item, index)=>{
                                return <Grow key={index} in={true}><div key={item.id} className="col-12">
                                      <div className="mb-3">
                                        <div style={{borderBottom: '1px solid #bbbbbb'}}>

                                            <div style={{padding: 6}} onClick={()=>history.push('restaurantDetail/'+item.slug)}>
                                                <img className={(item.is_open===0 || item.is_active===0)?'page-inactive-clickable':''} src={ROOT_URL+item.image} alt="PureEats" 
                                                  style={{width: '20%',verticalAlign: 'top', height: 64}} 
                                                />

                                                <CardContent style={{display: 'inline-block',width: '80%',padding: 0}}>
                                                  {item.is_featured===1 && (
                                                    <div className="ribbon ribbon-bookmark ribbon-warning">
                                                      <div className="ribbon-box" style={{position: 'absolute', top: '-11px'}}>Featured</div>
                                                    </div>
                                                  )}
                                                  {item.is_featured!==1 && item.is_pureveg===1 && (
                                                    <div className="ribbon ribbon-bookmark ribbon-success">
                                                      <div className="ribbon-box" style={{position: 'absolute', top: '-11px'}}>Pure Veg</div>
                                                    </div>
                                                  )}
                                                  <div className={(item.is_open===0 || item.is_active===0)?'page-inactive-clickable':''}>
                                                    <Typography gutterBottom style={{paddingLeft: 6, lineHeight: 0.9, fontWeight: 'bold', fontSize: 17, maxWidth: '80%'}} variant="h6" component="h2">
                                                      {item.name}
                                                    </Typography>
                                                    <Typography style={{paddingLeft: 6, maxWidth: 190}} variant="caption" color="textSecondary" component="p">
                                                      {item.description}
                                                    </Typography>
                                                  </div>

                                                  <div style={{marginLeft: 6}}>
                                                    <Typography style={{paddingLeft: 6}} variant="body1" color="textSecondary" component="p">
                                                      {(item.is_active===0 && item.msg==='open now')?'open in few minutes':item.msg}
                                                    </Typography>
                                                    {/*<Typography style={{paddingLeft: 6}} variant="body1" color="textSecondary" component="p">
                                                      {`Delivers in ${(item.delivery_time==="0")?'...':item.delivery_time}`}
                                                    </Typography>*/}
                                                    <span style={{    position: 'absolute',right: 21,bottom: 24,fontSize: 13, padding: '3px 7px', color: 'white',background: '#1fc31f',borderRadius: '14px',float: 'right'}}>
                                                      <StarIcon style={{fontSize: 12}} /> {item.rating}
                                                    </span>
                                                  </div>
                                                </CardContent>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div></Grow>
                              })}</>
                            )}

                            {searchResult.items!==undefined && (
                              <>{searchResult.items.map((item, index)=>{
                                return <Grow key={index} in={true}><div key={item.id} className="col-12">
                                      <div className="mb-3">
                                        <div style={{borderBottom: '1px solid #bbbbbb'}}>

                                            <div style={{padding: 6}} onClick={()=>history.push('restaurantDetail/'+item.slug)}>
                                                <div style={{width: '20%',verticalAlign: 'top', maxHeight: 64, backgroundColor: '#f7f7f7'}}>
                                                  {item.image!==null && (
                                                    <img className="img-fluid" src={ROOT_URL+item.image} alt="PureEats" />
                                                  )}
                                                </div>

                                                <CardContent style={{display: 'inline-block',width: '80%',padding: 0}}>
                                                  <div className={(item.is_open===0 || item.is_active===0)?'page-inactive-clickable':''}>
                                                    <Typography gutterBottom style={{paddingLeft: 6, lineHeight: 0.9, fontWeight: 'bold', fontSize: 17, maxWidth: '80%'}} variant="h6" component="h2">
                                                      {item.name}
                                                    </Typography>
                                                    <Typography style={{paddingLeft: 6, minHeight: 36}} variant="caption" color="textSecondary" component="p">
                                                      <b>₹ {item.price}</b>
                                                    </Typography>
                                                  </div>
                                                </CardContent>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div></Grow>
                              })}</>
                            )}
                          </>
                        )}

                        {search==='' && (
                        <div className="container-fluid mb-3 p-0">
                          <div className="row p-0">
                            <div className="col-md-12 mb-3">
                              {sliders.length>0 && (
                                <Slider {...SliderSettings}>
                                  {sliders.map((i, index)=>{
                                    return <div key={index}>
                                      <img style={{width: '100%',height: 160, borderRadius: 10, padding: 2}} src={ROOT_URL+i.image} alt="slide" />
                                    </div>
                                  })}
                                </Slider>
                              )}
                            </div>
                          </div>
                        </div>
                        )}

                        {search==='' && (
                          <><div style={{marginTop: 10, marginBottom: 10}}>
                            <Button 
                              onClick={()=>findRestaurants('delivery')} 
                              style={{fontSize: (type==='delivery')?15:12, fontWeight: 'bold', border: type==='delivery' ? '2px dashed' : '', padding: type==='delivery' ? '0px 13px' : ''}} size="medium" color={(type==='delivery')?'primary':'secondary'}>
                              <img alt="..." style={{height: 22, marginRight: 10}} src="/assets/img/delivery.png" />
                              Delivery
                            </Button>
                            <Button 
                              onClick={()=>findRestaurants('self')} 
                              style={{fontSize: (type==='self')?15:12, fontWeight: 'bold', border: type==='self' ? '2px dashed' : '', padding: type==='self' ? '0px 13px' : ''}} size="medium" color={(type==='self')?'primary':'secondary'}>
                              <img alt="..." style={{height: 22, marginRight: 10}} src="/assets/img/takeout.jpg" />
                              Self Pickup
                            </Button>
                          </div>

                          <div style={{padding: 5, marginBottom: 10}}>
                            <Typography style={{display: 'inline'}} variant="body1" className={'mb-3'}><b>{restaurants.length > 0 && `Top ${restaurants.length} Restaurants near you`}</b></Typography>
                            <Button onClick={()=>setFilterModal(true)} style={{fontSize: 12, fontWeight: 'bold', position: 'absolute', right: 0, marginRight: 18}} size="small" color="primary"><TuneOutlined style={{fontSize: 16, marginRight: 4}} /> {" "} Filter</Button>
                          </div></>
                        )}

                        {!loading && restaurants.length === 0 && (
                        <div className="row">
                            <div className="col-md-12">
                                <div style={{textAlign: 'center'}}>
                                    <img alt="slider" style={{width: '80%'}} src="/assets/img/android/error_no_data.png" />
                                    <h4 className={'mt-3 mb-2'}>No store found</h4>
                                </div>
                            </div>
                        </div>
                        )}

                        {loading && (
                        <div className="row">
                            {[...Array(6)].map((i, index)=>{
                                return <div key={index} className="col-md-4">
                                    <div className="mb-3">
                                      <Skeleton variant="rect" height={150} />
                                      <Skeleton variant="text" style={{marginTop: 5}} />
                                      <Skeleton variant="text" width={100} />
                                    </div>
                                </div>
                            })}
                        </div>
                        )}


                        {!loading && search==='' && restaurants.length > 0 && (
                        <div className="row dashed-bottom">
                            {/*eslint-disable-next-line*/}
                            {restaurants.map((item, index)=>{
                              return <Grow key={index} in={true}><div key={item.id} className="col-12">
                                    <div className="mb-3">
                                      <div style={{borderBottom: '1px solid #bbbbbb'}}>

                                          <div style={{padding: 6}} onClick={()=>history.push('restaurantDetail/'+item.slug)}>
                                              <img className={(item.is_open===0 || item.is_active===0)?'page-inactive-clickable':''} src={ROOT_URL+item.image} alt="PureEats" 
                                                style={{width: '20%',verticalAlign: 'top', height: 64}} 
                                              />
                                              {(item.coupons !== undefined) && (
                                                <>
                                                  {(item.coupons.filter(i => i.is_active === 1)?.length > 0) && (
                                                    <>
                                                      <span style={{position: 'absolute',top: 56,left: 27,background: '#002fb5',color: 'white',padding: '2px 6px',fontSize: 12}}>
                                                        {(item.coupons.filter(i => i.is_active === 1)[0].discount_type==='PERCENTAGE')?item.coupons.filter(i => i.is_active === 1)[0].discount+" %":"₹ "+item.coupons.filter(i => i.is_active === 1)[0].discount} Off
                                                      </span>
                                                    </>
                                                  )}
                                                </>
                                              )}

                                              <CardContent style={{display: 'inline-block',width: '80%',padding: 0}}>
                                                {item.is_featured===1 && (
                                                  <div className="ribbon ribbon-bookmark ribbon-warning">
                                                    <div className="ribbon-box" style={{position: 'absolute', top: '-11px'}}>Featured</div>
                                                  </div>
                                                )}
                                                {item.is_featured!==1 && item.is_pureveg===1 && (
                                                  <div className="ribbon ribbon-bookmark ribbon-success">
                                                    <div className="ribbon-box" style={{position: 'absolute', top: '-11px'}}>Pure Veg</div>
                                                  </div>
                                                )}
                                                <div className={(item.is_open===0 || item.is_active===0)?'page-inactive-clickable':''}>
                                                  <Typography gutterBottom style={{paddingLeft: 11, lineHeight: 0.9, fontWeight: 'bold', fontSize: 17, maxWidth: '80%'}} variant="h6" component="h2">
                                                    {item.name}
                                                  </Typography>
                                                  <Typography style={{paddingLeft: 11, maxWidth: 190}} variant="caption" color="textSecondary" component="p">
                                                    {item.description}
                                                  </Typography>
                                                </div>

                                                <div style={{marginLeft: 6}}>
                                                  <Typography style={{paddingLeft: 6}} variant="body1" color="textSecondary" component="p">
                                                    {(item.is_active===0 && item.msg==='open now')?'open in few minutes':item.msg}
                                                  </Typography>

                                                  {(item.is_active===1) && (
                                                    <>
                                                      {item.delivery_time_text && (
                                                      <Typography style={{paddingLeft: 6}} variant="body1" color="textSecondary" component="p">
                                                        {`Delivers in ${item.delivery_time_text}`}
                                                      </Typography>
                                                      )}

                                                      {!item.delivery_time_text && (
                                                        <Typography style={{paddingLeft: 6}} variant="body1" color="textSecondary" component="p">
                                                          {`Pickup in ${item.delivery_time} min`}
                                                        </Typography>
                                                      )}

                                                    </>
                                                  )}

                                                  <span style={{    position: 'absolute',right: 21,bottom: 24,fontSize: 13, padding: '3px 7px', color: 'white',background: '#1fc31f',borderRadius: '14px',float: 'right'}}>
                                                    <StarIcon style={{fontSize: 12}} /> {item.rating}
                                                  </span>
                                                </div>
                                              </CardContent>
                                          </div>
                                          
                                      </div>
                                  </div>
                              </div></Grow>
                            })}
                        </div>
                        )}

                    </div>
                </div>
            </div>

            <Modal
              open={filterModal}
              style={{top: 'unset'}}
              onClose={()=>setFilterModal(false)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <Grow in={filterModal}>
                <div className="row bg-white">
                  <div className="col-sm-12">
                    <div className="p-2" style={{borderBottom: '1px solid #c3c3c3'}}>
                      <IconButton onClick={()=>setFilterModal(false)} aria-label="Close">
                        <CloseOutlined />
                      </IconButton>

                      <Typography style={{display: 'inline', marginLeft: 4}} variant="body1">Sort/Filter</Typography>
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 p-0" style={{borderRight: '1px solid #c3c3c3', backgroundColor: 'aliceblue'}}>
                    <div className="ml-3 p-3" style={{borderBottom: '1px solid #c3c3c3'}}>
                      <Typography variant="body1">Sort</Typography>
                    </div>
                  </div>
                  <div className="col-8 col-sm-8">
                    <div className="p-3">
                      <FormControl component="fieldset">
                          <RadioGroup aria-label="sort" name="sort" value={sort} onChange={(e)=>setSort(e.target.value)}>
                             <FormControlLabel value="sortRelevance" control={<Radio />} label="Relevance" />
                             <FormControlLabel value="sortCost" control={<Radio />} label="Cost For Two Low To High" />
                             <FormControlLabel value="sortDelivery" control={<Radio />} label="Delivery Time Low To High" />
                             <FormControlLabel value="sortRating" control={<Radio />} label="Rating High To Low" />
                          </RadioGroup>
                        </FormControl>

                      <Button style={{marginTop: '4rem',width: '100%'}} onClick={()=>sortRestaurants()} variant="contained" color="secondary">Apply</Button>

                    </div>
                  </div>
                </div>
              </Grow>
            </Modal>

            <BottomNavigation
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              showLabels
              className="row"
              style={{width: '100%', position: 'fixed', bottom: 0}}
            >
              <BottomNavigationAction label="Home" icon={<HomeIcon />} />
              <BottomNavigationAction onClick={()=>(localStorage.getItem('user')===null || localStorage.getItem('user')==="null")?history.push('login'):history.push('notifications')} label="Alerts" icon={
                  <NotificationsIcon />
              } />
              <BottomNavigationAction onClick={()=>history.push('cart')} label="Cart" icon={
                <Badge badgeContent={(localStorage.getItem('cart')!==null)?JSON.parse(localStorage.getItem('cart')).length:'0'} color="error">
                  <ShoppingBasketIcon />
                </Badge>  
              } />
              <BottomNavigationAction onClick={()=>history.push('account')} label="More" icon={<MoreHorizontalIcon />} />
            </BottomNavigation>
        </div>
    );
}


