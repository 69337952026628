import React from "react";

import Typography from '@material-ui/core/Typography';
// import {ROOT_URL} from '../../../configs';
import { useHistory } from "react-router-dom";
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

export default function PaymentGateway(params){

  const history = useHistory();

  return (
    <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <IconButton onClick={()=>history.goBack()} aria-label="back">
              <KeyboardBackspaceIcon style={{marginRight: 5}} />
            </IconButton>
            <Typography className="ml-2 mb-2" color="text-secondary" variant="h6">
            <b>Payment Gateway</b>
            </Typography>
          </div>
        </div>

        
        <div className="row" style={{backgroundColor: '#dedede'}}>
          <div className="col-12">
          <br />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="mt-3 p-1 bg-white">
              <div style={{padding: 5, textAlign: 'justify'}}>
                <Typography variant="body1">
                                  
                  This document is an electronic record in terms of Information Technology Act, 2000 and
                  rules there under as applicable and the amended provisions pertaining to electronic
                  records in various statutes as amended by the Information Technology Act, 2000. This
                  document is published in accordance with the provisions of Rule 3 (1) of the Information
                  Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules
                  and regulations, privacy policy and Terms of Use for access or usage of www.pureeats.in
                  website and PURE EATS application for mobile and handheld devices.
                  These terms of use govern your use of our website www.pureeats.in and our “PURE
                  EATS” application for mobile and handheld devices. The Website and the App are jointly
                  referred to as the “Platform”. Please read these Terms of Use carefully before you use the
                  services. If you do not agree to these Terms of Use, you may not use the services on the
                  Platform, and we request you to uninstall the App. By installing, downloading or even
                  merely using the Platform, you shall be contracting with “PURE EATS” and you signify
                  your acceptance to the Terms of Use and other “PURE EATS” policies (including but not
                  limited to the Cancellation & Refund Policy, Privacy Policy and Take Down Policy) as
                  posted on the Platform from time to time, which takes effect on the date on which you
                  download, install or use the Services, and create a legally binding arrangement to abide
                  by the same.<br /><br />
                  
                  The following terms and conditions will apply to the user of PURE EATS online payment
                  gateway service offered through the PURE EATS website. Please go through the
                  conditions carefully and if acceptable, you may register and transact for e-payment on
                  PURE EATS Website. The records of PURE EATS and/or the Payment Service Provider,
                  generated by the transactions arising out of the use of the Service, including the time the
                  transaction is recorded shall be conclusive proof of the genuineness and accuracy of the
                  transaction.<br /><br />
                  Payment Gateway for making payment towards services requested is being provided by
                  PURE EATS to its members on its www.pureeats.in website. Payments for the PURE EATS
                  services may be made through an electronic and automated collection and remittance
                  service (the “Payment Gateway”) hosted through pay u money. The Payment Gateway
                  service is provided to you in order to facilitate access to make quick payments online.
                  PURE EATS make no representation of any kind, express or implied, as to the operation of
                  the Payment Gateway. You expressly agree that your use of this online payment service is
                  entirely at your own risk.<br /><br />
                  1. Once a User has accepted these Terms and Conditions, he/ she may register and avail
                  the Services.<br />
                  2. In these Terms and Conditions, the term “Charge Back” shall mean, approved and
                  settled credit card or net banking purchase transaction(s) which are at any time refused,
                  debited or charged back to merchant account (and shall also include similar debits to
                  Payment Service Provider’s accounts, if any) by the acquiring bank or credit card
                  company for any reason whatsoever, together with the bank fees, penalties and other
                  charges incidental thereto.<br />
                  3. Server Slow Down/Session Timeout: - In case the Website or Payment Service
                  Provider’s webpage, that is linked to the Website, is experiencing any server related
                  issues like ‘slow down’ or ‘failure’ or ‘session timeout’, the User shall, before initiating the
                  second payment,, check whether his/her Bank Account has been debited or not and
                  accordingly resort to one of the following options:
                  In case the Bank Account appears to be debited, ensure that he/ she does not make the
                  payment twice and immediately thereafter contact the Institute via e-mail or any other
                  mode of contact as provided by the Institute to confirm payment.
                  In case the Bank Account is not debited, the User may initiate a fresh transaction to make
                  payment.<br /><br />
                  
                  <b>General Terms & Conditions for Online Payments</b><br /><br />
                  However, the User agrees that under no circumstances the Payment Gateway Service
                  Provider shall be held responsible for such fraudulent/duplicate transactions and hence
                  no claims should be raised to Payment Gateway Service Provider. No communication
                  received by the Payment Service Provider(s) in this regards shall be entertained by the
                  Payment Service Provider(s).<br />
                  4. The Institute and the Payment Service Provider(s) assume no liability whatsoever for
                  any monetary or other damage suffered by the User on account of:
                  The delay, failure, interruption, or corruption of any data or other information
                  transmitted in connection with use of the Payment Gateway or Services in connection
                  thereto; and/ or<br />
                  Any interruption or errors in the operation of the Payment Gateway.
                  5. The User agrees, understands and confirms that his/ her personal data including
                  without limitation details relating to debit card/ credit card transmitted over the
                  Internet may be susceptible to misuse, hacking, theft and/ or fraud and that the Institute
                  or the Payment Service Provider(s) have no control over such matters.<br />
                  6. Although all reasonable care has been taken towards guarding against unauthorized
                  use of any information transmitted by the User, the Institute does not represent or
                  guarantee that the use of the Services provided by/ through it will not result in theft
                  and/or unauthorized use of data over the Internet.<br />
                  7. The PURE EATS , the Payment Service Provider(s) and its affiliates and associates shall
                  not be liable, at any time, for any failure of performance, error, omission, interruption,
                  deletion, defect, delay in operation or transmission, computer virus, communications
                  line failure, theft or destruction or unauthorized access to, alteration of, or use of
                  information contained on the Website.<br />
                  8. The User will be required to login his/ her own User ID and Password, given by the
                  PURE EATS in order to register and/ or use the Services provided by Institute on the
                  Website. By accepting these Terms and Conditions the User aggress that his/ her User ID
                  and Password are very important pieces of information and it shall be the User’s own
                  responsibility to keep them secure and confidential. In furtherance hereof, the User
                  agrees to;<br />
                  Choose a new password, whenever required for security reasons.<br />
                  Keep his/ her User ID & Password strictly confidential.<br />
                  Be responsible for any transactions made by User under such User ID and Password.<br />
                  Debit/Credit Card, Bank Account Details<br />
                  9. The User agrees that the debit/credit card details provided by him/ her for use of the
                  aforesaid Service(s) must be correct and accurate and that the User shall not use a debit/
                  credit card, that is not lawfully owned by him/ her or the use of which is not authorized
                  by the lawful owner thereof. The User further agrees and undertakes to provide correct
                  and valid debit/credit card details.<br />
                  10. The User may pay his/ her fees to the PURE EATS by using a debit/credit card or
                  through online banking account. The User warrants, agrees and confirms that when he/
                  she initiates a payment transaction and/or issues an online payment instruction and
                  provides his/ her card / bank details:<br />
                  The User is fully and lawfully entitled to use such credit / debit card, bank account for
                  such transactions;<br />
                  The User is responsible to ensure that the card/ bank account details provided by him/
                  her are accurate;<br />
                  The User is authorizing debit of the nominated card/ bank account for the payment of
                  fees selected by such User along with the applicable Fees.<br />
                  The User is responsible to ensure sufficient credit is available on the nominated card/
                  bank account at the time of making the payment to permit the payment of the dues
                  payable or the bill(s) selected by the User inclusive of the applicable Fee.
                  All payments made against the purchases/services on the Platform by you shall be
                  compulsorily in Indian Rupees acceptable in the Republic of India. The Platform will not
                  facilitate transactions with respect to any other form of currency with respect to the
                  purchases made on Platform. You can pay by<br />
                  (i) credit card or debit card or net banking;<br />
                  (ii) any other RBI approved payment method at the time of booking an order; or<br />
                  (iii) credit or debit card or cash at the time of delivery.<br />
                  You understand, accept and agree that the payment facility provided by PURE
                  EATS is neither a banking nor financial service but is merely a facilitator
                  providing an electronic, automated online electronic payment, receiving payment
                  on delivery, collection and remittance facility for the transactions on the Platform
                  using the existing authorized banking infrastructure and credit card payment
                  gateway networks. Further, by providing payment facility, PURE EATS is neither 
                  acting as trustees nor acting in a fiduciary capacity with respect to the transaction
                  or the transaction price.<br />
                  The final tax bill will be issued by the Merchant to the Buyer along with the order and
                  PURE EATS is merely collecting the payment on behalf of such Merchant. All applicable
                  taxes and levies, the rates thereof and the manner of applicability of such taxes on the bill
                  are being charged and determined by the Merchant. PURE EATS hold no responsibility for
                  the legal correctness/validity of the levy of such taxes. The sole responsibility for any
                  legal issue arising on the taxes shall reside with the Merchant.<br />
                  The Merchant shall be solely responsible for any warrantee/guarantee of the food
                  products sold to the Buyers and in no event shall be the responsibility of PURE EATS.
                  The transaction is bilateral between the Merchant and Buyer and therefore, PURE EATS is
                  not liable to charge or deposit any taxes applicable on such transaction.
                  All prices listed on the Website for Food Delivery by the Participating Restaurant reflect
                  the price the Participating Restaurant charges at the time of listing. In case the price
                  listed is not current and the restaurant informs us immediately after placing the order,
                  we will put our best effort to contact you to inform you about the price difference and you
                  can choose to opt-out of the order at that time<br />
                  If you choose online payment, you must pay for your order before it is delivered. To
                  ensure that shopping online is secure, your debit/credit card details will be encrypted to
                  prevent the possibility of someone being able to read them as they are sent over the
                  internet. Your credit card company may also conduct security checks to confirm it is you
                  placing the order.<br />
                  PURE EATS enable transactions between participant restaurants/merchants and buyers,
                  dealing in prepared food and beverages (“Platform Services”). The buyers (Buyer(s)) can
                  choose and place orders (Order(s)) from variety of products listed and offered for sale by
                  various neighborhood merchants including but not limited to the restaurants and
                  eateries (Merchant(s)), on the Platform and PURE EATS enables delivery of such orders
                  at select localities of serviceable cities across India (“Delivery Services”). The Platform
                  Services and Delivery Services are collectively referred to as “Services”.
                  The debit/credit card details provided by you for use of the Payment Gateway will be
                  correct and accurate and you shall not use a debit/credit card, which is not lawfully
                  owned by you. You further agree and undertake to provide correct and valid debit/credit
                  card details. You may make your payments to PURE EATS by using a Debit/Credit Card or
                  through using your Online Banking Account. When You initiate a payment transaction
                  and/or issue a online payment Instruction and provide Your Card / Bank details:<br /><br />
                  1. You warrant that You are fully and lawfully entitled to use such Credit / Debit Card,
                  Bank Account for such transactions,<br />
                  2. You are responsible to ensure that the Card, Bank Account details provided by You are
                  accurate,<br />
                  3. You are authorizing the debit to the nominated Card, Bank account for the settlement
                  of payment of services selected by you along with the Applicable charges and taxes.
                  You are responsible to ensure that sufficient credit is available on your nominated Card,
                  Bank Account at the time of making the payment selected by you inclusive of the
                  applicable taxes.<br />
                  What is Cash on Delivery?<br />
                  Cash-on-Delivery (CoD) is an alternative to paying by credit card or Net Banking. When
                  you use our CoD service, you pay at the time of delivery, rather than paying at the time of
                  purchase.<br />
                  Are there any hidden terms and conditions for a COD purchase?
                  The maximum order value for a CoD payment is Rs. 400. It’s strictly a cash-only payment
                  method; above 400 you have to pay on online payments i.e: all types of online payment
                  services. Payment has to be made using Indian Rupees only. Please keep exact change.
                  PURE EATS is pleased to now offer Cash on Delivery (COD).<br /><br />
                  <b>Debit/Credit card, Bank Account details</b><br />
                  <b>CASH ON DELIVERY</b><br /><br />
                  HOW DOES IT WORK?<br />
                  COD orders are available for orders that do not exceed 500.<br />
                  Place orders at www.pureeats.in and upon check out you will be prompted to selected
                  payment method. Choose Cash on Delivery (COD).<br />
                  The availability of the Cash on Delivery option is subject to the Cash on Delivery terms
                  and conditions. Cash on Delivery can be used as payment method only for orders less
                  than Rs. 500. … Customers are requested to check the ordered items at delivery, before
                  making cash on delivery payment.<br />
                  Only cash payments will be accepted while delivering the order under the CoD format.
                  Demand Draft/ Cheques will not be accepted for orders booked under the CoD method of
                  payment. It is strictly a cash-only payment method. Foreign currency cannot be used to
                  make a C-o-D payment.<br />
                  All of our prices are in Indian Rupees (INR) for Indian Customers, and in US Dollars (USD)
                  for overseas customers. The applicable tax is charged separately as per prevailing rates.
                  Customer Service Policy: -<br />
                  We endeavor to make sure that all products and services listed on our website are
                  currently available and pricing is true and correct.
                  The Service is provided in order to facilitate access to view and pay Fees online. The
                  Institute or the Payment Service Provider(s) do not make any representation of any kind,
                  express or implied, as to the operation of the Payment Gateway other than what is
                  specified in the Website for this purpose. By accepting/ agreeing to these Terms and
                  Conditions, the User expressly agrees that his/ her use of the aforesaid online payment
                  Service is entirely at own risk and responsibility of the User.<br /><br />
                  -:Pricing Policy: -<br />
                  -:Payment Gateway Disclaimer: -<br /><br />
                  Once the User will be registered and paid the Fee through online Payment Gateway. He/She will
                  not be able to cancel the transaction in any circumstances. No Payment will be refunded to the
                  User. As a general rule you shall not be entitled to cancel your order once you have received
                  confirmation of the same. If you cancel your order after it has been confirmed, restaurant and
                  delivery partners. PURE EATS shall also have right to charge you cancellation fee for the orders
                  cancelled by PURE EATS for the reasons specified under of this cancellation and refunds policy.
                  In case of cancellations for the reasons attributable to PURE EATS or its restaurant and delivery
                  partners, PURE EATS shall not charge you any cancellation fee.
                  However, in the unlikely event of an item on your order being unavailable, we will contact you
                  on the phone number provided to us at the time of placing the order and inform you of such
                  unavailability. In such an event you will be entitled to cancel the entire order and shall be
                  entitled to a refund in accordance with our refund policy.
                  We reserve the sole right to cancel your order in the following circumstance:
                  In the event of the designated address falls outside the delivery zone offered by us;
                  Failure to contact you by phone or email at the time of confirming the order booking;
                  Failure to deliver your order due to lack of information, direction or authorization from you at
                  the time of delivery; or<br />
                  Unavailability of all the items ordered by you at the time of booking the order; or
                  Unavailability of all the items ordered by you at the time of booking the order; or
                  Refunds :-<br />
                  You shall be entitled to a refund only if you pre-pay for your order at the time of placing
                  your order on the Platform and only in the event of any of the following circumstances:
                  Your order packaging has been tampered or damaged at the time of delivery;
                  Us cancelling your order due to
                  (A) your delivery location following outside our designated delivery zones;<br />
                  (B) failure to contact you by phone or email at the time of confirming the order booking;<br />
                  (C) failure to contact you by phone or email at the time of confirming the order booking;<br />
                  (D) You cancelling the order at the time of confirmation due to unavailability of the items<br />
                  you ordered for at the time of booking.<br /><br />
                  
                  <b>Cancellation Policy</b><br /><br />
                  Our decision on refunds shall be at our sole discretion and shall be final and binding.
                  All refund amounts shall be credited to your account within 3-4 business days in
                  accordance with the terms that may be stipulated by the bank which has issued the credit
                  / debit card.<br />
                  In case of payment at the time of delivery, you will not be required to pay for:
                  Orders where the packaging has been tampered or damaged by us;<br />
                  Wrong order being delivered; or<br />
                  Items missing from your order at the time of delivery.<br />
                  Refund for Charge Back Transaction: -<br />
                  In the event there is any claim for/ of charge back by the User for any reason whatsoever,
                  such User shall immediately approach the PURE EATS with his/ her claim details and
                  claim refund from the Institute alone. Such refund (if any) shall be affected only by the
                  PURE EATS via payment gateway or any other means as the Institute deems appropriate.
                  No claims for refund/ charge back shall be made by any User to the Payment Service
                  Provider(s) and in the event such claim is made it shall not be entertained.
                  Refund for fraudulent/duplicate transaction(s): -<br />
                  The User shall directly contact the Institute for any fraudulent transaction(s) on account
                  of misuse of Card/ Bank details by a fraudulent individual/party and such issues shall be
                  suitably addressed by the Institute alone in line with their policies and rules.
                  -:Payment Refund Policy for Online Payments:-
                  THE PLATFORM MAY BE UNDER CONSTANT UPGRADES, AND SOME FUNCTIONS AND
                  FEATURES MAY NOT BE FULLY OPERATIONAL.<br />
                  DUE TO THE VAGARIES THAT CAN OCCUR IN THE ELECTRONIC DISTRIBUTION OF
                  INFORMATION AND DUE TO THE LIMITATIONS INHERENT IN PROVIDING INFORMATION
                  OBTAINED FROM MULTIPLE SOURCES, THERE MAY BE DELAYS, OMISSIONS, OR
                  INACCURACIES IN THE CONTENT PROVIDED ON THE PLATFORM OR DELAY OR ERRORS IN
                  FUNCTIONALITY OF THE PLATFORM. AS A RESULT, WE DO NOT REPRESENT THAT THE
                  INFORMATION POSTED IS CORRECT IN EVERY CASE.<br />
                  WE EXPRESSLY DISCLAIM ALL LIABILITIES THAT MAY ARISE AS A CONSEQUENCE OF ANY
                  UNAUTHORIZED USE OF CREDIT/ DEBIT CARDS.<br />
                  YOU ACKNOWLEDGE THAT THIRD PARTY SERVICES ARE AVAILABLE ON THE PLATFORM. WE
                  MAY HAVE FORMED PARTNERSHIPS OR ALLIANCES WITH SOME OF THESE THIRD PARTIES
                  FROM TIME TO TIME IN ORDER TO FACILITATE THE PROVISION OF CERTAIN SERVICES TO
                  YOU. HOWEVER, YOU ACKNOWLEDGE AND AGREE THAT AT NO TIME ARE WE MAKING ANY
                  REPRESENTATION OR WARRANTY REGARDING ANY THIRD PARTY’S SERVICES NOR WILL WE
                  BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY CONSEQUENCES OR CLAIMS ARISING
                  FROM OR IN CONNECTION WITH SUCH THIRD PARTY INCLUDING, AND NOT LIMITED TO, ANY
                  LIABILITY OR RESPONSIBILITY FOR, DEATH, INJURY OR IMPAIRMENT EXPERIENCED BY YOU
                  OR ANY THIRD PARTY. YOU HEREBY DISCLAIM AND WAIVE ANY RIGHTS AND CLAIMS YOU
                  MAY HAVE AGAINST US WITH RESPECT TO THIRD PARTY’S / MERCHANTS SERVICES.
                  PURE EATS DISCLAIMS AND ALL LIABILITY THAT MAY ARISE DUE TO ANY VIOLATION OF THE
                  FOOD SAFETY AND STANDARDS ACT, 2006 AND APPLICABLE RULES AND REGULATIONS
                  MADE THEREUNDER AND SUCH LIABILITY SHALL BE ATTRIBUTABLE TO THE MERCHANT.
                  WHILE THE MATERIALS PROVIDED ON THE PLATFORM WERE PREPARED TO PROVIDE
                  ACCURATE INFORMATION REGARDING THE SUBJECT DISCUSSED, THE INFORMATION
                  CONTAINED IN THESE MATERIALS IS BEING MADE AVAILABLE WITH THE UNDERSTANDING
                  THAT WE MAKE NO GUARANTEES, REPRESENTATIONS OR WARRANTIES WHATSOEVER,
                  WHETHER EXPRESSED OR IMPLIED, WITH RESPECT TO PROFESSIONAL QUALIFICATIONS,
                  EXPERTISE, QUALITY OF WORK OR OTHER INFORMATION HEREIN. FURTHER, WE DO NOT, IN
                  ANY WAY, ENDORSE ANY SERVICE OFFERED OR DESCRIBED HEREIN. IN NO EVENT SHALL WE
                  BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DECISION MADE OR ACTION TAKEN IN
                  RELIANCE ON SUCH INFORMATION.<br /><br />
                  -:Disclaimers:-<br /><br />
                  THE INFORMATION PROVIDED HEREUNDER IS PROVIDED “AS IS”. WE AND / OR OUR
                  EMPLOYEES MAKE NO WARRANTY OR REPRESENTATION REGARDING THE TIMELINESS,
                  CONTENT, SEQUENCE, ACCURACY, EFFECTIVENESS OR COMPLETENESS OF ANY
                  INFORMATION OR DATA FURNISHED HEREUNDER OR THAT THE INFORMATION OR DATA
                  PROVIDED HEREUNDER MAY BE RELIED UPON. MULTIPLE RESPONSES MAY USUALLY BE
                  MADE AVAILABLE FROM DIFFERENT SOURCES AND IT IS LEFT TO THE JUDGEMENT OF USERS
                  BASED ON THEIR SPECIFIC CIRCUMSTANCES TO USE, ADAPT, MODIFY OR ALTER
                  SUGGESTIONS OR USE THEM IN CONJUNCTION WITH ANY OTHER SOURCES THEY MAY HAVE,
                  THEREBY ABSOLVING US AS WELL AS OUR CONSULTANTS, BUSINESS ASSOCIATES,
                  AFFILIATES, BUSINESS PARTNERS AND EMPLOYEES FROM ANY KIND OF PROFESSIONAL
                  LIABILITY.<br />
                  WE SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSSES OR INJURY ARISING
                  OUT OF OR RELATING TO THE INFORMATION PROVIDED ON THE PLATFORM. IN NO EVENT
                  WILL WE OR OUR EMPLOYEES, AFFILIATES, AUTHORS OR AGENTS BE LIABLE TO YOU OR ANY
                  THIRD PARTY FOR ANY DECISION MADE OR ACTION TAKEN BY YOUR RELIANCE ON THE
                  CONTENT CONTAINED HEREIN.<br />
                  IN NO EVENT WILL WE BE LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION,
                  DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
                  DAMAGES ARISING FROM PERSONAL INJURY/WRONGFUL DEATH, AND DAMAGES RESULTING
                  FROM LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION), RESULTING FROM ANY
                  SERVICES PROVIDED BY ANY THIRD PARTY OR MERCHANT ACCESSED THROUGH THE
                  PLATFORM, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL
                  THEORY AND WHETHER OR NOT WE ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                  Governing law and dispute resolution:-<br />
                  These Terms of Use are governed by the laws of India. Any action, suit, or other legal
                  proceeding, which is commenced to resolve any matter arising under or relating to this
                  Platform, shall be subject to the jurisdiction of the courts at West Bengal, India.
                  I HEREBY CONFIRM THAT I HAVE READ AND UNDERSTOOD THESE
                  TERMS AND CONDITIONS AND ACCORDINGLY I AM FULLY AWARE
                  OF MY OBLIGATIONS AND RESPONSIBILITIES AS A CLIENT ARISING
                  HEREFROM, INCLUDING PROVISIONS ON LIABILITY AND INDEMNITY
                  WHICH HAVE SPECIFICALLY BEEN BROUGHT TO MY ATTENTION.<br />
                  I FURTHERMORE WARRANT TO BE DULY AUTHORISED TO ACCEPT,
                  SIGN AND REPRESENT THE CLIENT ON THIS AGREMEENT AND
                  CONFIRM ACCEPTANCE OF THE TERMS AND CONDITIONS SET OUT
                  ABOVE.<br /><br />
                  Thank you for your cooperation.<br /><br />

                  <b>Send the written communication to:-</b><br />
                  For users residing in the India: -<br />
                  Founded in 2020 by,<br />

                  Flat no 307 SAPUIPARA, near Mota shiv Mandir ( Rakhi Factory)<br />
                  Bally Howrah , Pin: -711227.<br />
                  State: -West Bengal,<br />
                  Email: - help@pureeats.in<br />
                  Customer support number - 18001238508<br />

                  </Typography>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{backgroundColor: '#dedede'}}>
          <div className="col-12">
          <br />
          </div>
        </div>

    </div>
  );
}

