import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {LOGIN_USER_MOBILE_URL, LOGIN_USER_MOBILE_OTP_URL} from '../../configs';
import Axios from 'axios';
import { useHistory } from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function LoginDrawer(props) {
  const classes = useStyles();
  const history = useHistory();

  const [login, setLoginDrawer] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [mobile, setMobile] = useState('');
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const doLogin=()=>{
    if (otpSent) {
      verifyOtp();
      return;
    }

    if (mobile.length < 10){
      setMobileError(true);
      return;
    }

    setMobileError(false);

    Axios.get(`${LOGIN_USER_MOBILE_URL}/${mobile}`)
    .then(res=>{
      console.log(res.data)
      if (res.data.success) {
        setOtpSent(true)
        setLoading(false);
        // window.location.reload()
      }else{
        setMessage(res.data.message);
        setShowMessage(true);
      }
    })
    .catch((e)=>console.log(e))
  }

  const verifyOtp=()=>{
    if (mobile.length < 10 || otp.length < 4) {
      return;
    }

    setLoading(true);

    Axios.post(LOGIN_USER_MOBILE_OTP_URL, {phone: mobile, otp})
    .then(res=>{
      console.log(res.data)
      if (res.data.success) {
        localStorage.setItem('user', JSON.stringify(res.data.data));
        setTimeout(()=>{
          history.push('/restaurants')
        }, 800);
        setLoading(false);
      }else{
        setMessage(res.data.message);
        setLoading(false);
      }
    })
    .catch((e)=>console.log(e))
  }

  return (
        <>
          <Button onClick={()=>setLoginDrawer(true)} style={{float: 'right', marginTop: (props.margin)?13:0}} >
            Sign in
          </Button>

          <Drawer anchor={'right'} open={login} onClose={()=>setLoginDrawer(false)}>
            <div style={{padding: 1}}>
              <IconButton onClick={()=>setLoginDrawer(false)} aria-label="close" className={classes.margin}>
                <CloseIcon />
              </IconButton>
            </div>

            <div style={{padding: 25}}>
              <Typography variant="h5" gutterBottom>
                Login
              </Typography>
              <Typography variant="caption" gutterBottom>
                or {' '}
              </Typography>
              <a href="/">
                <Typography style={{color: '#bb1229'}} variant="caption" gutterBottom>
                  create an account
                </Typography>
              </a>

              <br />

              <div>
                <TextField
                  className="filled-input-white"
                  style={{marginBottom: 30, marginTop: 20, backgroundColor: 'white', borderColor: 'black', width: '100%'}}
                  label="Phone Number "
                  id="filled-size-small"
                  defaultValue=""
                  onChange={e=>setMobile(e.target.value)}
                  error={mobileError}
                  variant="filled"
                />

                {otpSent && (
                  <div className="col-sm-12">
                    <TextField 
                      onChange={(e)=>setOtp(e.target.value)} 
                      style={{marginBottom: 15, width: '100%'}} 
                      label="OTP" />
                  </div>
                )}

                <Button
                  disabled={loading} 
                  loading={loading} 
                  onClick={()=>doLogin()} 
                  style={{marginTop: 20, width: '100%', backgroundColor: '#bb1229', borderRadius: 0, color: 'white'}} 
                  variant="contained" size="large" color="primary">
                    {loading?'Please Wait...':otpSent?'LOGIN':'VERIFY'}
                </Button>
              </div>
            </div>
          </Drawer>

          <Snackbar open={showMessage} autoHideDuration={6000} onClose={()=>setShowMessage(false)}>
            <MuiAlert 
              elevation={6} 
              variant="filled" 
              onClose={()=>setShowMessage(false)} 
              severity="error">
              {message}
            </MuiAlert>
          </Snackbar>
        </>
    
  );
}

