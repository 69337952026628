import React from "react";

import Typography from '@material-ui/core/Typography';
// import {ROOT_URL} from '../../../configs';
import { useHistory } from "react-router-dom";
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

export default function TermsOfUse(params){

  const history = useHistory();

  return (
    <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <IconButton onClick={()=>history.goBack()} aria-label="back">
              <KeyboardBackspaceIcon style={{marginRight: 5}} />
            </IconButton>
            <Typography className="ml-2 mb-2" color="text-secondary" variant="h6">
            <b>Terms Of Use</b>
            </Typography>
          </div>
        </div>

        
        <div className="row" style={{backgroundColor: '#dedede'}}>
          <div className="col-12">
          <br />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="mt-3 p-1 bg-white">
              <div style={{padding: 5, textAlign: 'justify'}}>
                <Typography variant="body1">
                PURE EATS hereby grant you a nonexclusive, non-transferable, limited license to access
                and the use the Web site for the fees, if applicable and under the terms set forth below.
                The Web site and the content, including, but not limited to, text, data, reports, opinions,
                images, photo, graphics, graphs, charts, animation and video displayed on the Web site
                may be used only for personal and non-commercial use.<br /><br />
                Except as otherwise permitted under these Terms & conditions you agree not to copy ,
                reproduce, modify, create, derivative work from, or store any content in whole or in
                part from the Web site or to display, perform ,publish ,distribute, transmit, broadcast or
                circulate any content to anyone , or for any commercial purpose without the express
                prior written consent of PURE EATS.<br /><br />
                The content is the exclusive property of PURE EATS or its licensors, and is protected by
                copyright and other intellectual property laws. All trade names, trademarks, service
                marks and other product and service names and logos on the web sites and within the
                content are proprietary to their respective owners and are protected by applicable
                trademarks and copyrights laws. Any of the trademarks, service marks or logos
                displayed on the Web sites may be registered or unregistered marks of PURE EATS or
                others. Nothing contained on these Web sites should be constructed as granting any
                license or right to use any of the marks displayed on the Web sites without the express
                written permission of PURE EATS or a third-party owner of such Marks. Any
                unauthorised uses of the marks or any other content are strictly prohibited. To request
                <br />
                permission to use any content or other PURE EATS material please contact PURE EATS
                at www.pureeats.in
                You may not use the web sites for any unlawful purpose. You shall honour all
                reasonable requests by the websites to protest PURE EATS proprietary interests in
                the website.<br />
                1.The Restaurant’s use of the Pure Eats and the Restaurant Application shall be subject
                to the terms and conditions set forth in this Agreement and the Enrolment Agreement
                and the privacy policy published from time to time on Pure Eats website.<br />
                2. In the event Pure Eats provides you with a Device, the following terms and
                conditions shall apply:<br />
                3.The Device shall be used by the Restaurant solely for the purpose of<br />
                 (a) managing Orders;<br />
                (b) reviewing the particulars underlying the Orders;<br />
                (c) updating the Order status;<br />
                (d) updating Restaurant Information;<br />
                (e) communicating with Pure Eats;<br />
                (f) reporting erroneous Orders;<br />
                (g) reviewing Restaurant’s performance and financial accounts relating
                to its business with Pure Eats and/or<br />
                (h) any other use that may be prescribed in writing by Pure Eats in this<br />
                4. Pure Eats terms and conditions relating to Devices shall apply to any Device provided
                by Pure Eats to the Restaurant;<br />
                

                5. The Restaurant undertakes to use the Device in compliance with the instructions for
                use that may be issued by Pure Eats in this regard;<br />
                6. On the delivery of the Device by Pure Eats to the Restaurant, the Restaurant will be
                required to acknowledge the receipt of the Device in writing by executing an Asset
                Handover Form in the format annexed to the Agreement, and the cost of the Device will be
                charged to the Restaurant pursuant to the terms of the Enrolment Agreement;<br />
                7. Pure Eats grants a limited, non-exclusive, non-transferable, royalty free license to the
                Restaurant for the use of the Device solely until the termination of this Agreement;
                8. The Restaurant undertakes to and shall use the Device only for the purposes prescribed
                under this Agreement and that the Restaurant should not violate the intellectual property
                rights of Pure Eats over and in respect of the Device at any point in time;<br />
                9. The Restaurant undertakes to return the Device along with all accessories (in a full
                working condition as determined by Pure Eats) to Pure Eats immediately upon the
                termination of this Agreement or when requested by Pure Eats. The Device will be
                collected by Pure Eats from the Restaurant at the sole expense of Pure Eats. Upon the
                return of the Device along with all accessories by the Restaurant, Pure Eats and the
                Restaurant will be required to acknowledge the transfer of the Device in writing as
                instructed by Pure Eats;<br />
                10. The Restaurant acknowledges and agrees that any failure on part of the Restaurant in
                returning the Device (whenever requisitioned by Pure Eats or on the termination of this
                Agreement) will render the Restaurant liable to pay for such unreturned Device including
                for an appropriate action under the Applicable Laws; and<br />
                11.The Restaurant acknowledges and agrees that:<br />
                (i) Pure Eats is not the manufacturer of any Device provided<br />
                (ii) Pure Eats cannot provide any warranties or guarantees with respect to the quality or
                durability of the Device and<br />
                (iii) it uses the Device at its own risk.<br />
                12. In the event that the Device ceases to function and the Restaurant is unable to access
                the Restaurant Application through any other means, the Restaurant will immediately
                inform Pure Eats and Pure Eats will disable the Restaurant on the Pure Eats until the
                Device is functional again.<br /><br />


                <b>Send the written communication to:-</b><br />
                For users residing in the India: -<br />
                Founded in 2020 by,<br />

                Flat no 307 SAPUIPARA, near Mota shiv Mandir ( Rakhi Factory)<br />
                Bally Howrah , Pin: -711227.<br />
                State: -West Bengal,<br />
                Email: - help@pureeats.in<br />
                Customer support number - 18001238508<br />

                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{backgroundColor: '#dedede'}}>
          <div className="col-12">
          <br />
          </div>
        </div>

    </div>
  );
}

