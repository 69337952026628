import React, {useEffect, useState} from "react";

import Typography from '@material-ui/core/Typography';
import {GET_WALLET_TRANSACTIONS_URL, UPDATE_USER_INFO_URL} from '../../../configs';
import { useHistory } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Axios from 'axios';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Skeleton from '@material-ui/lab/Skeleton';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';

export default function Wallet(params){

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [user, setUser] = useState({});

  useEffect(()=>{
    let userl = JSON.parse(localStorage.getItem('user'))

    Axios.post(UPDATE_USER_INFO_URL, {token:userl.auth_token, user_id: userl.id})
    .then(res=>{
      setUser(res.data.data);
      userl.wallet_balance=res.data.data.wallet_balance;
      localStorage.setItem('user', JSON.stringify(userl))
    })
    .catch(e=>console.log(e));


    Axios.post(GET_WALLET_TRANSACTIONS_URL, {token:userl.auth_token, user_id: userl.id})
    .then(res=>{
      setTransactions(res.data.transactions);
      setLoading(false);
    })
    .catch(e=>console.log(e));

  }, []);

  return (
    <div className="container-fluid">

        <div className="row bg-white">
          <div className="col-12">
            <IconButton onClick={()=>history.goBack()} aria-label="back">
              <KeyboardBackspaceIcon style={{marginRight: 5}} />
            </IconButton>
            <span>My Wallet</span>
          </div>
        </div>

        <div className="row bg-white mt-4">
          <div className="col-12">
              <div style={{border: '1px solid grey',padding: 10, textAlign: 'center'}}>
                  <b>Wallet <span style={{color: 'orange'}}>Rs. {user.wallet_balance}</span></b>
              </div>
          </div>
        </div>

        {loading && (
          <div className="row">
              {[...Array(10)].map((i)=>{
                  return <div className="col-md-12">
                      <div className="mb-3 bg-white p-3">
                        <Skeleton variant="rect" height={54} />
                        <Skeleton variant="text" style={{marginTop: 5}} />
                        <Skeleton variant="text" width={100} />
                        <Skeleton variant="text" width={50} />
                      </div>
                  </div>
              })}
          </div>
          )}

        <List component="nav" aria-label="main mailbox folders">
        {!loading && transactions.length>0 && (
            transactions.map((item)=>{
                return <><ListItem button key={item.id}>
                  <ListItemIcon>
                    {item.type==='deposit' && (
                      <AccountBalanceWalletOutlinedIcon style={{color: 'green'}} />
                    )}
                    {item.type!=='deposit' && (
                      <ReceiptOutlinedIcon style={{color: 'red'}} />
                    )}
                  </ListItemIcon>
                  <ListItemText 
                    primary={"₹ "+(parseInt(item.amount)/100)}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {item.meta.description}
                        </Typography>
                        <br />
                        {item.created_at}
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                          style={{float:'right', color: (item.type==='deposit')?'green':'red'}}
                        >
                          {(item.type==='deposit')?'Credit':'Debit'}
                        </Typography>
                      </React.Fragment>} />
                </ListItem>
                <Divider />
                </>
            })
        )}
        </List>

        {transactions.length===0 && (
          <Typography style={{padding: 10, textAlign: 'center'}} className="ml-2 mt-2 mb-4" color="text-secondary" variant="body1">
            <b>No Wallet Transactions Yet!!!</b>
          </Typography>
        )}


    </div>
  );
}

