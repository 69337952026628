import React, {useState, useEffect} from "react";

import Typography from '@material-ui/core/Typography';
import {GET_ORDER_DETAIL_URL} from '../../../configs';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Axios from 'axios';
// import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';
import { useParams } from "react-router-dom";
// import Icon from '@material-ui/core/Icon';
// import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import AddIcCallOutlinedIcon from '@material-ui/icons/AddIcCallOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';

export default function OrderDetails(params){

  const history = useHistory();

  const {order_id} = useParams()

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({});
  const [restaurant, setRestaurant] = useState({});
  const [couponDetails, setCouponDetails] = useState({});

  useEffect(()=>{
      let user = JSON.parse(localStorage.getItem('user'))
      // setAccount(user)

      Axios.post(GET_ORDER_DETAIL_URL, {token:user.auth_token, user_id: user.id, order_id: order_id})
      .then(res=>{
        console.log(res.data)
        setOrder(res.data.order);
        setRestaurant(res.data.resturant_details)
        setCouponDetails(res.data.coupon_details)
        setLoading(false);
      })
      .catch(e=>console.log(e));
  }, [order_id]);

  const reOrder=()=>{
    history.push(`/restaurantDetail/${restaurant.slug}`)
  }

  const subTotal = () => {
    return order.orderitems.reduce((a, c)=>a+(parseInt(c.price) * c.quantity),0)
  }

  const calculateDiscount=()=>{
    if(couponDetails === null) return 0;

    if(couponDetails.discount_type==="PERCENTAGE"){
      let t = subTotal();

      let dis = Math.round((t*parseInt(couponDetails.discount))/100);
      
      if (dis > parseInt(couponDetails.upto_amount)) {
        return parseInt(couponDetails.upto_amount);
      }
      
      return dis;
    }
    if(couponDetails.discount_type==="AMOUNT"){
      // let t = subTotal();
      let dis = parseInt(couponDetails.discount);
      if (dis > parseInt(couponDetails.upto_amount)) {
        return parseInt(couponDetails.upto_amount);
      }
      return dis;
    }
  }

  return (
    <div className="container-fluid bg-app">

        {loading && (
        <div className="row">
          <div className="col-md-12">
              <div className="mb-3 bg-white p-3">
                <Skeleton variant="rect" height={102} />
                <Skeleton variant="text" style={{marginTop: 5}} />
                <Skeleton variant="text" width={100} />
                <Skeleton variant="text" width={50} />
              </div>
          </div>
        </div>
        )}

        {!loading && (
          <div className="row bg-white" style={{borderBottom: '1px solid #d0d0d0',paddingBottom: 10}}>
            <div className="col-12">
              <IconButton onClick={()=>history.goBack()} aria-label="back">
                <KeyboardBackspaceIcon style={{marginRight: 5}} />
              </IconButton>
              <Typography className="ml-2" variant="body1" component="span">
                <b>ORDER # {order.unique_order_id} </b>
              </Typography>
              <br />
              <Typography style={{marginLeft:'4rem'}} color="text-secondary" variant="caption" component="span">
                {order.created_at} | {order.orderitems.length} Items, Rs {order.total}
              </Typography>
            </div>
          </div>
        )}

        {!loading && (
          <div className="row bg-white">
            <div className="col-12">
              <div className="p-3">
                <div className="row">
                  <div className="col-2">
                    <RoomOutlinedIcon className="mr-2" />
                  </div>
                  <div className="col-10" style={{borderLeft: '1px dashed'}}>
                    <Typography variant="h6">{restaurant.name}</Typography>
                    <Typography variant="body1">{restaurant.address}</Typography>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-3 mb-1">
            </div>

            <div className="col-9 mb-1">
                {/* <Button size="small" endIcon={<CloudDownloadOutlinedIcon />} variant="outlined" className="mb-3 mr-2" style={{borderRadius:0}}>Invoice</Button> */}
                <Button onClick={()=>{window.location = (`tel:${restaurant.contact_number}`)}} aria-label="call" size="small" endIcon={<AddIcCallOutlinedIcon />} variant="outlined" className="mb-3" style={{borderRadius:0}}>Call Now</Button>
            </div>

            <div className="col-12">
              <div className="p-3" style={{borderBottom: '1px solid #d0d0d0',paddingBottom: 10}}>
                <div className="row">
                  <div className="col-2">
                    <HomeOutlinedIcon className="mr-2"/>
                  </div>
                  <div className="col-10" style={{borderLeft: '1px dashed'}}>
                    <Typography variant="h6">Home</Typography>
                    <Typography variant="body1">{order.address}</Typography>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-12 mb-3">
              <div className="p-3" >
                <Typography variant="body2">Order {order.delivery_type === 2?'picked up':'delivered'} at {new Date(order.updated_at).toLocaleString()}</Typography>
              </div>
            </div>

            <div className="col-12">
              <Typography variant="body1"><b>BILL DETAILD</b></Typography>
              
              <div className="row" style={{borderTop: '1px solid grey', borderBottom: '1px solid grey', paddingTop: 10}}>
              {order?.orderitems?.map(i => (
                <>
                  <div className="col-8">
                    <Typography className="pb-3" variant="body1">{i?.quantity} x {i?.name}</Typography>
                  </div>
                  <div className="col-4">
                    <Typography className=" float-right" variant="body1">₹ {i?.quantity * i?.price}</Typography>
                  </div>
                </>
              ))}
              </div>

              <div className="row">
                
                <div className="col-8">
                  <Typography className="mt-3" variant="body1">Subtotal</Typography>
                </div>
                <div className="col-4">
                  <Typography className="mt-3 float-right" variant="body1">₹ {subTotal()}</Typography>
                </div>

                <div className="col-8">
                  <Typography className=" text-secondary" variant="body1">Restaurant Charges</Typography>
                </div>
                <div className="col-4">
                  <Typography className=" text-secondary float-right" variant="body1">₹ {Math.round(subTotal() * (order.restaurant_charge / 100) * 100) / 100}</Typography>
                </div>

                <div className="col-8">
                  <Typography className=" text-secondary" variant="body1">Delivery Charge</Typography>
                </div>
                <div className="col-4">
                  <Typography className=" float-right text-secondary" variant="body1">₹ {order.delivery_charge}</Typography>
                </div>

                <div className="col-8">
                  <Typography className=" text-secondary" variant="body1">TAX</Typography>
                </div>
                <div className="col-4">
                  <Typography className=" text-secondary float-right" variant="body1">₹ {Math.round(subTotal() * (order.tax / 100) * 100) / 100}</Typography>
                </div>

                {order.driver_tip_amount !== "" && (
                <><div className="col-8" style={Styles.bbs}>
                  <Typography className=" text-secondary" variant="body1">Delivery Tip</Typography>
                </div>
                <div className="col-4" style={Styles.bbs}>
                  <Typography className="pb-2 text-secondary float-right" variant="body1">₹ {order.driver_tip_amount}</Typography>
                </div></>
                )}

                <div className="m-0 col-12 row" style={{background: '#cfffcf', padding: 8, border: '1px solid #00ab00'}}>
                  <div className="col-8">
                    <Typography className=" text-secondary" variant="body1">Total Discount</Typography>
                  </div>
                  <div className="col-4">
                    <Typography className=" text-secondary float-right" variant="body1">- ₹ {calculateDiscount()}</Typography>
                  </div>
                </div>

                <div className="col-8">
                  <Typography className="p-2" variant="h6"><b>Total</b></Typography>
                </div>
                <div className="col-4 text-right">
                  <Typography className="p-2" variant="h6"><b>₹ {order.total}</b></Typography>
                </div>

                <div className="col-12 mb-4">
                  <Typography className="p-2" variant="body1"><b>Paid via {order.payment_mode}</b></Typography>
                </div>


                <div className="col-12 mb-1">
                    <Button onClick={()=>reOrder()} variant="contained" className="mb-3" style={{height:45, borderRadius:0,width: '100%', backgroundColor:'blue', color: 'white'}}>RE ORDER</Button>
                    <Button variant="contained" className="mb-3" style={{height:45, borderRadius:0,width: '100%', backgroundColor:'orange', color: 'white'}}><a rel="noopener noreferrer" href="https://wa.me/918777630556?text=Hi%20i%20need%20help%20at%20pureeats%20please%20connect%20me%20to%20a%20care%20specialist" target="_blank" >NEED HELP ?</a></Button>
                </div>


              </div>
            </div>

          </div>
        )}

        <div className="row">

            
        </div>

        <div className="row" style={{backgroundColor: '#dedede'}}>
          <div className="col-12">
          <br />
          </div>
        </div>

    </div>
  );
}

const Styles = {
  vegIcon: {
    width: '16px',
    height: '16px',
    position: 'absolute',
    left: '10px',
    top: '10px',
    background: 'white',
  },
  bts:{
    borderTop: '1px solid #ababab',
  },
  btd:{
    borderTop: '1px dashed',
  },
  bbs:{
    borderBottom: '1px solid #ababab',
  },
  bbd:{
    borderBottom: '1px dashed',
  },
}
