import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import IconButton from '@material-ui/core/IconButton';
import RoomOutlined from '@material-ui/icons/RoomOutlined';
import MapMarkerIcon from '@material-ui/icons/MyLocation';
import { useHistory, Redirect } from "react-router-dom";
// import Icon from '@material-ui/core/Icon';
import Flip from "react-reveal/Flip";
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
// import GoogleMap from "../../../components/GeoLocation/Map";
import {GET_ADDRESS_FROM_COORDINATES} from '../../../configs';
import Axios from 'axios';
import Footer from '../../../components/Layouts/Footer';
import Slider from "react-slick";
import LoginDrawer from '../../../components/Auth/LoginDrawer';
import SignupDrawer from '../../../components/Auth/SignupDrawer';

const SliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows:true,
  centerMode: true,
  className: "center",
  centerPadding: "10px",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Landing() {
  const classes = useStyles();
  const history = useHistory();

  // const [google_script_loaded, setgoogle_script_loaded] = useState(true);
  const [address, setAddress] = useState('');
  // const [selectedAddress, setSelectedAddress] = useState('');
  const [gpsLoading, setGpsLoading] = useState(false);


  // useEffect(()=>{
  //   const existingScript = document.getElementById("googleMaps");
  //   if (!existingScript) {
  //     const script = document.createElement("script");
  //     script.src =
  //       "https://maps.googleapis.com/maps/api/js?key=AIzaSyCt_14My2CYghVw6eZFSYFlFPBOK29lkww&libraries=places";
  //     script.id = "googleMaps";
  //     document.body.appendChild(script);
  //     script.onload = () => {
  //       setgoogle_script_loaded(true)
  //     };
  //   }
  // },[])

  const getMyLocation = () => {
    const location = navigator && navigator.geolocation;
    console.log("LOCATION", location);
    setGpsLoading(true);
    if (location) {
      location.getCurrentPosition(
        position => {
          reverseLookup(position.coords.latitude, position.coords.longitude);
        },
        error => {
          setGpsLoading(false);
          console.log(error);
          // this.setState({locationModal: true});
          // alert(localStorage.getItem("gpsAccessNotGrantedMsg"));
        }
      );
    }
  };

  const reverseLookup = (lat, lng) => {
    Axios.post(GET_ADDRESS_FROM_COORDINATES, {
      lat: lat,
      lng: lng
    })
      .then(response => {
        // alert(response.data);
        const myLocation = [
          {
            formatted_address: response.data,
            geometry: {
              location: {
                lat: lat,
                lng: lng
              }
            }
          }
        ];
        console.log(myLocation)
        setGpsLoading(false);
        localStorage.setItem("userLat", lat);
        localStorage.setItem("userLng", lng);
        localStorage.setItem("address", response.data);
        history.push('/restaurants');
        // this.handleGeoLocationClick(myLocation);
      })
      .catch(function(error) {
        console.warn(error.response.data);
      });
  };

  const handleChange = address => {
    setAddress(address); 
  };
 
  const handleSelect = address => {
    setAddress(address);
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        console.log('Success', latLng)
        localStorage.setItem("userLat", latLng.lat);
        localStorage.setItem("userLng", latLng.lng);
        localStorage.setItem("address", address);
        history.push('/restaurants');
      })
      .catch(error => console.error('Error', error));
  };

  const findFood=()=>{
    if(localStorage.getItem("userLat") !== null){
      history.push('/restaurants');
    }
  }

  if(localStorage.getItem('userLng') !== null ){
    return(<Redirect to="restaurants" />)
  }

  return (
        <div className="container-fluid p-0">
            <div className="row">
                <div className="col-sm-12 col-md-12">
                  <div style={{marginLeft: 60, padding: 30}} className={classes.root}>
                    <a href="/" >
                      <img style={{width: '21%', height: 'auto'}} src="/assets/img/logos/logo.png" alt="PureEats" className="footer-logo mr-2" />
                    </a>
                    
                    <SignupDrawer />
                    <LoginDrawer />
                  </div>

                  <div className="text-center" style={{marginLeft: 60, padding: 30}}>
                    <Typography style={{fontSize: '3rem', marginBottom: 0, lineHeight: '42px'}} variant="h6" gutterBottom>
                      Your taste - our passion
                    </Typography>
                    <Typography style={{fontSize: '1.5rem'}} variant="body1" gutterBottom>
                      {' '}Order food from favourite restaurants near you.
                    </Typography>
                  </div>

                  <div className="text-center" style={{marginLeft: 90}}>

                      <PlacesAutocomplete
                        onChange={(val)=>handleChange(val)}
                        onSelect={(val)=>handleSelect(val)}
                        value={address}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <TextField
                              {...getInputProps({
                                placeholder: 'Search Places ...',
                              })}
                              className="c-input"
                              style={{borderTopWidth: 1, backgroundColor: 'white', borderColor: 'black', width: '50%'}}
                              label="Search for area, street name... "
                              id="search"
                              size="small"
                            />

                            <Button 
                              startIcon={<MapMarkerIcon />}
                              className="locate-me-btn"
                              onClick={()=>getMyLocation()} style={{height: 45, width: 102, borderRadius: 0, fontSize: 11}}>
                              {(gpsLoading)?'...':'Locate Me'}
                            </Button>
                            <Button onClick={()=>findFood()} style={{fontSize: 11, height: 45, width: 93, backgroundColor: '#bb1229', borderRadius: 0, color: 'white'}} variant="contained" color="primary">
                              FIND FOOD
                            </Button>

                            <div style={{padding: 1}} className="autocomplete-dropdown-container">
                              <div className="MuiPaper-root MuiCard-root jss178 MuiPaper-elevation1 MuiPaper-rounded" style={{background: 'white', position: 'absolute', zIndex: 9999, padding: 5}}>
                                {loading && 
                                  <div>
                                    <img
                                      src="/assets/img/various/spinner.svg"
                                      className="location-loading-spinner"
                                      alt="loading"
                                    />
                                  </div>
                                }

                                {suggestions.map((suggestion, index) => {
                                  const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                  const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <Flip top delay={index * 50} key={suggestion.id}>
                                        <div
                                          className="location-suggestion"
                                          style={{padding: 10, borderBottomWidth: 1, borderBottom: 'daashed'}}
                                        >
                                          <RoomOutlined style={{marginRight: 5}} />
                                          <span className="location-main-name">
                                            {suggestion.description}
                                          </span>
                                        </div>
                                      </Flip>
                                    </div>
                                  );
                                })}
                              </div>

                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                  </div>

                  <div className="text-center" style={{marginLeft: 60, padding: 30}}>
                    <Typography variant="caption" style={{fontWeight: 'bold', fontSize: '0.9rem'}} gutterBottom>
                      POPULAR LOCATIONS IN KOLKATA
                    </Typography>
                    <br />
                    <Typography style={{color: '#9a9a9a', fontSize: 14}} variant="caption" gutterBottom>
                      Howrah, South Dumdum, Rajarhat, New Town, Chhapna, Nature Park & more.
                    </Typography>
                  </div>
                </div>

                <div className="col-md-12 p-3">
                  <Slider {...SliderSettings}>
                      <div>
                        <img style={{width: '100%',height: 320, borderRadius: 10, padding: 15}} src="https://shortysplacediner.com/wp-content/uploads/2017/10/pizza-background.jpg" alt="slide" />
                      </div>
                      <div>
                        <img style={{width: '100%',height: 320, borderRadius: 10, padding: 15}} src="https://static.toiimg.com/photo/74984407.cms" alt="slide" />
                      </div>
                      <div>
                        <img style={{width: '100%',height: 320, borderRadius: 10, padding: 15}} src="https://data.tibettravel.org/assets/images/Tibet-bhutan-tour/indian-food-in-Lhasa.webp" alt="slide" />
                      </div>
                      <div>
                        <img style={{width: '100%',height: 320, borderRadius: 10, padding: 15}} src="https://cdn.tasteatlas.com/images/toplistarticles/d0e6a0a79d5f4197a51f4ca065393ffe.jpg?w=600&h=450" alt="slide" />
                      </div>
                      <div>
                        <img style={{width: '100%',height: 320, borderRadius: 10, padding: 15}} src="https://img1.rapidleaks.com/2018/09/best-indian-food-1280x720.jpg" alt="slide" />
                      </div>
                  </Slider>
                </div>
            </div>


            <div className="row" style={{padding: 15,  backgroundColor: '#bb1229'}}>
              <div className="col-sm-12 col-md-4">
                <div style={{textAlign: 'center', padding: 45}}>
                  <img style={{marginBottom: '1rem', width: 54}} src="/assets/img/android/group_payment.png" alt="PureEats" className="mr-2" />
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    Top Restaurants
                  </Typography>
                  <Typography variant="body2" style={{color: 'white'}} gutterBottom>
                    Find the best restaurants near you
                    and get the best quality food.
                  </Typography>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div style={{textAlign: 'center', padding: 45}}>
                  <img style={{marginBottom: '1rem', width: 54}} src="/assets/img/android/slip.png" alt="PureEats" className="mr-2" />
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    5 Click Order
                  </Typography>
                  <Typography variant="body2" style={{color: 'white'}} gutterBottom>
                    With our super fast website
                    you can order in seconds.
                  </Typography>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div style={{textAlign: 'center', padding: 45}}>
                  <img style={{marginBottom: '1rem', width: 54}} src="/assets/img/android/scooter.png" alt="PureEats" className="mr-2" />
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    On Time Delivery
                  </Typography>
                   <Typography variant="body2" style={{color: 'white'}} gutterBottom>
                    With our super fast delivery
                    we gurintee delivery to you in time. 
                  </Typography> 
                </div>
              </div>
            </div>

            <Footer />

        </div>
    
  );
}

