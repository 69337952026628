import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {ALL_COUPONS_URL} from '../../../../configs';
import Axios from 'axios';
// import { useHistory } from "react-router-dom";
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import { useSnackbar } from 'notistack';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function CouponsDrawer(props) {
  const classes = useStyles();
  // const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();


  const [drawer, setDrawer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [coupons, setCoupons] = useState(null);

  useEffect(()=>{
      let resturant = JSON.parse(localStorage.getItem("cartRestaurant"));

      Axios.post(ALL_COUPONS_URL, {restaurant_id: resturant.id})
      .then(res=>{
          console.log(res.data)
          setCoupons(res.data)
          setLoading(false)
      })
      .catch((e)=>console.log(e))
  }, []);

  const applyCoupon=(item)=>{
    let total = 0;
    JSON.parse(localStorage.getItem('cart')).map(i=>total+=parseInt(i.price * i.quantity));

    if (total < Number(item.min_order_amount)) {
      enqueueSnackbar('Coupon applicable on minimum order of '+item.min_order_amount, {variant: 'error'});
      return;
    }

    localStorage.setItem('applyedCoupon', JSON.stringify(item));
    enqueueSnackbar('Coupon applied successfully! ', {variant: 'success'});
    setDrawer(false)
  }

  const removeCoupon=()=>{
    setCoupons(null)
    localStorage.removeItem('applyedCoupon');
    
    // enqueueSnackbar('Coupon removed! ', {variant: 'error'});
  }

  return (
        <>
          {props.coupon===null && (
            <div className="row" style={{borderBottom: '1px solid'}}>
                <div className="col-9 p-3" onClick={()=>setDrawer(true)}>
                  <Typography variant="body1"><b>APPLY COUPON</b></Typography>
                </div>
                <div className="col-3">
                  <IconButton className="float-right" onClick={()=>setDrawer(true)} aria-label="back">
                    <ChevronRightOutlinedIcon style={{marginRight: 5}} />
                  </IconButton>
                </div>
            </div>
          )}

          {props.coupon!==null && (
            <div className="row" style={{borderBottom: '1px solid', backgroundColor: '#00a000 '}}>
                <div className="col-9 p-3" onClick={()=>setDrawer(true)}>
                  <Typography style={{color: 'white'}} className="text-center" variant="body1"><b>{props.coupon.code} Coupon Applied</b></Typography>
                </div>
                <div className="col-3">
                  <IconButton className="float-right" onClick={()=>removeCoupon()} aria-label="back">
                    <CloseIcon style={{marginRight: 5, color: 'white'}} />
                  </IconButton>
                </div>
            </div>
          )}

          <Drawer anchor={'right'} open={drawer} onClose={()=>setDrawer(false)}>
            <div style={{padding: 1}}>
              <IconButton onClick={()=>setDrawer(false)} aria-label="close" className={classes.margin}>
                <CloseIcon />
              </IconButton>
            </div>

            <div style={{padding: 25}}>
              {loading && (
              <div className="row">
                  {[...Array(4)].map((i)=>{
                      return <div className="col-12">
                          <div className="mb-3">
                            <Skeleton variant="rect" height={70} />
                            <Skeleton variant="text" style={{marginTop: 5}} />
                            <Skeleton variant="text" width={100} />
                            <Skeleton variant="text" width={50} />
                          </div>
                      </div>
                  })}
              </div>
              )}

              {coupons!==null && (
              <div className="row">
                {coupons.map((i=>{
                  return <div className="col-12">
                    <Card gutterBottom className="mb-4">
                      <CardContent>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                          <b>{i.name}</b>
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {i.description}
                        </Typography>
                      </CardContent>
                      <div className="pl-3 pb-3 pr-3">
                        <Button variant="contained" disabled >{i.code}</Button>
                        <Button onClick={()=>applyCoupon(i)} style={{background: 'forestgreen',color: 'white'}} className="float-right" variant="contained" >Apply</Button>
                      </div>
                    </Card>
                  </div>
                }))}
              </div>
              )}
            </div>
          </Drawer>
        </>
    
  );
}

