import React from "react";

import Typography from '@material-ui/core/Typography';
// import {ROOT_URL} from '../../../configs';
import { useHistory } from "react-router-dom";
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// import StarOutlineOutlinedIcon from '@material-ui/icons/StarOutlineOutlined';
// import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

export default function Legal(params){

  const history = useHistory();

  return (
    <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <IconButton onClick={()=>history.goBack()} aria-label="back">
              <KeyboardBackspaceIcon style={{marginRight: 5}} />
            </IconButton>
            <Typography className="ml-2 mb-2" color="text-secondary" variant="h6">
            <b>PURE EATS</b>
            </Typography>
          </div>
        </div>

        
        <div className="row" style={{backgroundColor: '#dedede'}}>
          <div className="col-12">
          <br />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <List component="nav" aria-label="main mailbox folders">
               <ListItem onClick={()=>history.push('/faq')} button>
                <ListItemIcon>
                  <LiveHelpOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="FAQ" />
              </ListItem>
              <ListItem onClick={()=>history.push('/termsAndConditions')} button>
                <ListItemIcon>
                  <InfoOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Terms & Conditions" />
              </ListItem>
              <ListItem onClick={()=>history.push('/termsOfUse')} button>
                <ListItemIcon>
                  <InfoOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Terms Of Use" />
              </ListItem>
              <ListItem onClick={()=>history.push('/privacyPolicy')} button>
                <ListItemIcon>
                  <InfoOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Privacy Policy" />
              </ListItem>
              <ListItem onClick={()=>history.push('/payments')} button>
                <ListItemIcon>
                  <InfoOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Payment Policy" />
              </ListItem>
              <ListItem onClick={()=>history.push('/cancelRefundPolicy')} button>
                <ListItemIcon>
                  <InfoOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Cancellation & Refund Policy" />
              </ListItem>
              <ListItem onClick={()=>history.push('/about')} button>
                <ListItemIcon>
                  <InfoOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="About Us" />
              </ListItem>
              <ListItem onClick={()=>history.push('/contact')} button>
                <ListItemIcon>
                  <InfoOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Contact US" />
              </ListItem>
            </List>
          </div>
        </div>

        <div className="row" style={{backgroundColor: '#dedede'}}>
          <div className="col-12">
          <br />
          </div>
        </div>

    </div>
  );
}

