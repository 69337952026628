import React from "react";

import Typography from '@material-ui/core/Typography';
// import {ROOT_URL} from '../../../configs';
import { useHistory } from "react-router-dom";
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

export default function Payments(params){

  const history = useHistory();

  return (
    <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <IconButton onClick={()=>history.goBack()} aria-label="back">
              <KeyboardBackspaceIcon style={{marginRight: 5}} />
            </IconButton>
            <Typography className="ml-2 mb-2" color="text-secondary" variant="h6">
            <b>Payment Policy</b>
            </Typography>
          </div>
        </div>

        
        <div className="row" style={{backgroundColor: '#dedede'}}>
          <div className="col-12">
          <br />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="mt-3 p-1 bg-white">
              <div style={{padding: 5, textAlign: 'justify'}}>
                <Typography variant="body1">
                1.The user shall be entitled to a refund only if you pre-pay for your order at the time of placing your order
                on the Platform and only in the event of any of the following circumstances:<br /><br />
                (i) The order packaging has been tampered or damaged at the time of delivery;<br />
                (ii)The user cancels the order due to:<br />
                 (a) Your delivery location following outside our designated delivery zones;<br />
                 (b) Failure to contact you by phone or email at the time of confirming the order booking; or<br />
                 (c) Failure to contact you by phone or email at the time of confirming the order booking, or<br />
                 (d) Where the user cancels the order at the time of confirmation due to unavailability of the
                items you ordered for at the time of booking.<br />
                2.The decision on refunds shall be at the sole discretion of the Food Delivery Platform and shall be final and
                binding.<br />
                3.All refund amounts shall be credited to your account within 3-4 business days in accordance with the
                terms that may be stipulated by the bank which has issued the credit/debit card.<br />
                <br /><br />
                In case of payment at the time of delivery, the user shall not be required to pay for:
                a) Orders where the packaging has been tampered or damaged by us;<br />
                b) Wrong order being delivered; or<br />
                c) Items missing from your order at the time of delivery.<br />
                1.The Restaurant acknowledges and agrees that while Pure Eats will use its reasonable efforts to ensure
                that the Pure Eats is not misused by Customers for the placement of wrong Orders, it is the ultimate
                responsibility of the Restaurant to determine whether an Order is wrong or not. In the event of a wrong
                Order, the Restaurant undertakes to report such a wrong Order to Pure Eats through the Device or call
                Pure Eats for Pure Eats action and investigation.<br />
                2. Pure Eats may perform a variety of marketing activities to promote the Restaurant and the
                Restaurant’s menu; provided, however, that all such marketing activities will be
                determined in Pure Eats sole and absolute discretion and the Pure Eats may be modified or updated,
                without notice and from time to time, to reflect any such changes.
                3. The Restaurant acknowledges and agrees that the Pure Eats will provide the following payment
                mechanisms to Customers for the payment of the Order Value:<br />
                (a) Cash on delivery;<br />
                (b) Electronic Payment Mechanism;<br />
                (c) Redemption of vouchers and/or discount promotion (if any) approved by Pure Eats.<br />
                4. In the event that any of the Restaurant or its employees irreparably damages or loses the Device or
                any of its accessories during the subsistence of this Agreement, Pure Eats shall replace the same and the
                Restaurant shall be charged for the cost of replacement.<br />
                5. Pure Eats will settle Net Order Value owed to Restaurant in accordance with the payment terms
                specified on the Enrolment Agreement (“Payments”). Restaurant agrees that Pure Eats shall be entitled
                to set-off any amounts Restaurant owes to Pure Eats hereunder (including the Commission Percentage)
                from the Payments. Restaurant will be responsible for any taxes or charges payable on the Goods
                including collecting, paying and reporting such taxes to the appropriate authorities and providing
                Customers with compliant Tax Invoices where required under local laws for the Goods.<br />
                6.Restaurant acknowledges and agrees that Pure Eats will provide the Restaurant with a monthly
                statement within a period of 7 (seven) business days from the last day of each month setting forth all of
                the Orders during such month and any amounts due to Pure Eats in respect of Orders (including the
                Commission Percentage). Such statements will be sent to the Restaurant using the email address
                specified in the Enrolment Agreement.<br /><br />
                <b>CHARGES, PAYMENTS and COMMISSION</b><br /><br />
                7.In the event that an Order is not delivered to a Customer as a direct result of the negligence or wilful
                misconduct of a Captain, Pure Eats shall waive the Commission Percentage owed to it in relation to
                such Order and Pure Eats shall put forth its best commercial efforts to procure that such Captain re-pay
                Customer or Restaurant the Order Value if such amount was paid by.<br /><br />

                <b>Send the written communication to:-</b><br />
                For users residing in the India: -<br />
                Founded in 2020 by,<br />

                Flat no 307 SAPUIPARA, near Mota shiv Mandir ( Rakhi Factory)<br />
                Bally Howrah , Pin: -711227.<br />
                State: -West Bengal,<br />
                Email: - help@pureeats.in<br />
                Customer support number - 18001238508<br />

                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{backgroundColor: '#dedede'}}>
          <div className="col-12">
          <br />
          </div>
        </div>

    </div>
  );
}

