import React, {useState, useEffect} from "react";

import Typography from '@material-ui/core/Typography';
import {GET_ORDERS_URL,ROOT_URL} from '../../../configs';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Axios from 'axios';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';

export default function Account(params){

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  // const [account, setAccount] = useState({});
  const [orders, setOrders] = useState([]);

  useEffect(()=>{
      let user = JSON.parse(localStorage.getItem('user'))
      // setAccount(user)

      Axios.post(GET_ORDERS_URL, {token:user.auth_token, user_id: user.id})
      .then(res=>{
        console.log(res)
        setOrders(res.data);
        setLoading(false);
      })
      .catch(e=>console.log(e));
  }, []);

  return (
    <div className="container-fluid bg-app">

        <div className="row bg-white mb-3">
          <div className="col-12">
            <IconButton onClick={()=>history.goBack()} aria-label="back">
              <KeyboardBackspaceIcon style={{marginRight: 5}} />
            </IconButton>
            <Typography className="ml-2" color="text-secondary" variant="h6" component="span">
            <b>ORDER SUMMARY</b>
            </Typography>
          </div>
        </div>

        {loading && (
        <div className="row">
            {[...Array(5)].map((i)=>{
                return <div className="col-md-12">
                    <div className="mb-3 bg-white p-3">
                      <Skeleton variant="rect" height={64} />
                      <Skeleton variant="text" style={{marginTop: 5}} />
                      <Skeleton variant="text" width={100} />
                      <Skeleton variant="text" width={50} />
                    </div>
                </div>
            })}
        </div>
        )}

        {!loading && orders.length===0 && (
          <div className="row bg-white">
            <div className="col-12">
              <div className="p-3">
                <img alt="No Data" src="/assets/img/android/error_no_data.png" className="img-fluid" />
              </div>
            </div>
          </div>
        )}

        <div className="row">
          {orders.map((item)=>{
            return <div className="col-12 mb-3">
            <Card>
              <CardContent onClick={()=>history.push('/orderDetails/'+item.id)}>
                <div className="row">
                  <div className="col-3" style={{borderBottom: '1px solid grey'}}>
                    <img alt="restaurant" src={ROOT_URL+item.resturant_details.image} className="img-fluid" />
                  </div>
                  <div className="col-9 p-0" style={{borderBottom: '1px solid grey'}}>
                    <Typography variant="h6" component="h2">
                      {item.resturant_details.name}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {item.resturant_details.address}
                    </Typography>
                  </div>
                </div>

                <br />

                <Typography variant="caption">
                  ITEMS
                </Typography>

                {item.orderitems.map((dish)=>{
                return <div className="row">
                  <div className="col-9">
                    <Typography variant="body2" component="h2">
                      <b>{dish.quantity} X  {dish.name}</b>
                    </Typography>
                  </div>
                  <div className="col-3">
                    <Typography variant="body2" component="h2">
                      <b>₹ {dish.price}</b>
                    </Typography>
                  </div>
                </div>
                })}

                <br />

                <div className="row">
                  <div className="col-6">
                    <Typography variant="caption">
                      ORDERED ON
                    </Typography>
                    <Typography variant="body2">
                      <b>{new Date(item.created_at).toLocaleString()}</b>
                    </Typography>
                  </div>
                  <div className="col-3">
                    <Typography variant="caption">
                      STATUS
                    </Typography>
                    <Typography style={{color: (item.orderstatus_id===6)?'red':'black'}} variant="body2">
                      <b>{(item.orderstatus_id===1)?'Order Placed':(item.orderstatus_id===2)?'Preparing Food':(item.orderstatus_id===3)?'DElivery Agent Assigned':(item.orderstatus_id===4)?'Food is on the way':(item.orderstatus_id===6)?'Canceled':'Order Placed'}</b>
                    </Typography>
                  </div>
                  <div className="col-3">
                    <Typography variant="caption">
                      Order Type
                    </Typography>
                    <Typography variant="caption" component="p">
                      {(item.delivery_type===1)?'Delivery':'Self Pickup'}
                    </Typography>
                  </div>

                  
                </div>

              </CardContent>
              <CardActions>
                {(item.orderstatus_id!==6 && item.orderstatus_id!==5)&& (
                  <Button style={{backgroundColor: 'green', color: 'white'}} variant="contained" onClick={()=>history.push('/liveTrack/'+item.id)} size="small">TRACK</Button>
                )}
                {(item.orderstatus_id===5)&& (
                  <Button disabled={true} variant="outlined" size="small">DELIVERED</Button>
                )}
                {(item.orderstatus_id!==6)&& (
                  <Button onClick={()=>history.push(`/restaurantDetail/${item.resturant_details.slug}`)} size="small">RE-ORDER</Button>
                )}
              </CardActions>
            </Card>
            </div>
          })}
          
            
        </div>

        <div className="row" style={{backgroundColor: '#dedede'}}>
          <div className="col-12">
          <br />
          </div>
        </div>

    </div>
  );
}

