import React, {useState, useEffect} from "react";

// import { connect } from "react-redux";
// import { getSettings } from "../../../services/settings/actions";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';
// import { useHistory } from "react-router-dom";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Axios from 'axios';
import {GET_DELIVERY_RESTAURANTS_URL, ROOT_URL, ALL_COUPONS_URL} from '../../../configs';
import Skeleton from '@material-ui/lab/Skeleton';
import Header from '../../../components/Layouts/Header';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useSnackbar } from 'notistack';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Offers(){

    const classes = useStyles();
    // const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState(0);
    const [restaurants, setRestaurants] = useState([]);
    const [coupons, setCoupons] = useState([]);


    useEffect(()=>{
        Axios.post(GET_DELIVERY_RESTAURANTS_URL, {
            latitude: '22.5726',
            longitude: '88.3639'
        })
        .then(res=>{
            console.log(res.data)
            setRestaurants(res.data)
            setLoading(false);
        })
        .catch((e)=>console.log(e))
    }, []);

    useEffect(()=>{
        Axios.post(ALL_COUPONS_URL)
        .then(res=>{
            console.log(res.data)
            setCoupons(res.data)
        })
        .catch((e)=>console.log(e))
    }, []);

    const handleTabChange = (event, newValue) => {
      setTab(newValue);
    };

    const copyCode=(code)=>{
      navigator.clipboard.writeText(code);
      enqueueSnackbar('Code Copied!', {variant:'success'});
    }

    return (
        <div className="container-fluid p-0">

          <Header />

            <div className="row">
                
                <div className="col-md-12">
                    <div style={{padding: 35}}>

                        <Paper square>
                              <Tabs
                                value={tab}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={handleTabChange}
                                aria-label="disabled tabs example"
                              >
                                <Tab label="Restaurant offers" />
                                <Tab label="Payment offers/Coupons" />
                              </Tabs>
                            </Paper>

                        {loading && (
                        <div className="row" style={{marginTop: '1rem'}}>
                            {[...Array(8)].map((i)=>{
                                return <div className="col-md-3">
                                    <div className="mb-3">
                                      <Skeleton variant="rect" height={150} />
                                      <Skeleton variant="text" style={{marginTop: 5}} />
                                      <Skeleton variant="text" width={100} />
                                    </div>
                                </div>
                            })}
                        </div>
                        )}

                        {tab === 0 && (
                          <div>
                          <h4 style={{marginTop: '2rem', marginBottom: 0}}><b>All offers ({restaurants.length})</b></h4>
                          <Typography variant="caption">All offers and deals, from restaurants near you</Typography>

                          {restaurants.length > 0 && (
                          <div className="row dashed-bottom" style={{marginTop: '2rem'}}>
                              {restaurants.map((item)=>{
                                return <div key={item.id} className="col-md-3">
                                    <div className="mb-3">
                                        <div className={classes.root}>
                                            <CardActionArea>
                                                <img src={ROOT_URL+item.image} alt="PureEats" style={{width: '100%', maxHeight: 180}} />

                                                <CardContent>
                                                  <Typography gutterBottom variant="h5" component="h2">
                                                    {item.name}
                                                  </Typography>
                                                  <Typography variant="body2" color="textSecondary" component="p">
                                                    {item.description}
                                                  </Typography>

                                                  <div className="mt-2">
                                                    <span style={{marginRight: 2, fontSize: 13, backgroundColor: 'green', padding: '3px 6px', borderRadius: 2, color: 'white'}}>
                                                        <StarIcon style={{fontSize: 12}} /> {item.rating}</span>
                                                        <span style={{color:'#6f6f6f', padding: '0 6px'}}>•</span> 
                                                        <span style={{fontSize: 11}}>{item.delivery_time} MINS</span>
                                                        <span style={{color:'#6f6f6f', padding: '0 6px'}}>•</span> 
                                                        <span style={{fontSize: 11}}>{item.price_range} FOR TWO</span>
                                                  </div>

                                                  {(item.coupons !== undefined) && (
                                                    <>
                                                      {item.coupons[0] !== undefined && (
                                                        <div style={{borderTop: '1px solid grey',padding: 5,color: '#c77800',}} className="mt-2">
                                                          <LocalOfferOutlinedIcon style={{marginTop: 5}} />  <span>{item.coupons[0].name} </span>
                                                        </div>
                                                      )}
                                                    </>
                                                  )}


                                                </CardContent>
                                            </CardActionArea>
                                            
                                        </div>
                                    </div>
                                </div>
                              })}
                          </div>
                          )}
                          </div>
                        )}

                        {tab === 1 && (
                          <div>
                          <h4 style={{marginTop: '2rem', marginBottom: 0}}><b>Available Coupons</b></h4>

                          {coupons.length > 0 && (
                          <div className="row" style={{marginTop: '2rem'}}>
                              {coupons.filter((i)=>i.is_active===1).map((item)=>{
                                return <div key={item.id} className="col-md-3">
                                    <div className="mb-3">
                                        <div className={classes.root} style={{border:'1px dashed grey'}}>
                                            <div>
                                                <CardContent style={{height: 214}}>
                                                  <p style={{fontWeight: 'bold', textAlign: 'center', backgroundColor: '#f01d3b', border: '1px solid #f01d3b', padding: 5, fontSize: 18, color: 'white'}}>{item.code}</p>
                                                  <Typography gutterBottom variant="h5" component="h2">
                                                    {item.name}
                                                  </Typography>
                                                  <Typography variant="body2" color="textSecondary" component="p">
                                                    {item.description}
                                                  </Typography>
                                                </CardContent>
                                                <Button onClick={()=>copyCode(item.code)} style={{margin: 5}} color="primary">Copy Code</Button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                              })}
                          </div>
                          )}
                          </div>
                        )}

                    </div>
                </div>
            </div>

            {!loading && restaurants.length === 0 && (
            <div className="row">
                <div className="col-md-12">
                    <div style={{padding: 50, textAlign: 'center'}}>
                        <img alt="slider" style={{width: 345}} src="https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/connection_error_bsppck" />
                        <h4 className={'mb-2'}>Oh! All restaurants are <br /> currently unserviceable</h4>
                        <p className={'text-muted'}>All restaurants are unserviceable, check back in some time.</p>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
}


