import React from "react";

import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";

export default function Footer(){

  // const history = useHistory(); 

    return (
          <><div className="row" style={{backgroundColor: '#46000a'}}>
            <div className="col-sm-12 col-md-1">
            </div>
            <div className="col-sm-12 col-md-4">
              <div style={{padding: '10px 20px'}}>
                <img style={{width: '70%', height: 'auto'}} src="/assets/img/logos/logo_w.png" alt="PureEats" className="mr-2" />

                <div style={{padding: 10}}>
                  <Link to="restaurants" >
                    <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                      About Us
                    </Typography>
                  </Link>
                  <Link to="restaurants" >
                    <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                      Blog
                    </Typography>
                  </Link>
                  <Link to="restaurants" >
                    <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                      Careers
                    </Typography>
                  </Link>
                  <Link to="restaurants" >
                    <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                      Restaurants Sign Up
                    </Typography>
                  </Link>
                  <Link to="restaurants" >
                    <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                      Rider Sign Up
                    </Typography>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-2">
              <div style={{padding: 25, marginTop: 30}}>
                <Typography variant="h6" style={{color: 'white'}} gutterBottom>
                  WE DELIVER TO
                </Typography>
                <Link to="restaurants" >
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    Salkia
                  </Typography>
                </Link>
                <Link to="restaurants" >
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    Chakpara
                  </Typography>
                </Link>
                <Link to="restaurants" >
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    Liluah
                  </Typography>
                </Link>
                <Link to="restaurants" >
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    Nischinda
                  </Typography>
                </Link>
                <Link to="restaurants" >
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    Belur Bazar
                  </Typography>
                </Link>
                <Link to="restaurants" >
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    Uttarpara Shantinagar
                  </Typography>
                </Link>
                  
              </div>
            </div>
            <div className="col-sm-12 col-md-2">
              <div style={{padding: 25, marginTop: 30}}>
                <Typography variant="h6" style={{color: 'white'}} gutterBottom>
                  
                </Typography>
                <br />
                <Link to="restaurants" >
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    Belur Math
                  </Typography>
                </Link>
                <Link to="restaurants" >
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    Belur
                  </Typography>
                </Link>
                <Link to="restaurants" >
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    Bally
                  </Typography>
                </Link>
                <Link to="restaurants" >
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    East Sapuipara
                  </Typography>
                </Link>
                <Link to="restaurants" >
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    Ghoshpara
                  </Typography>
                </Link>
                <Link to="restaurants" >
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    Bhadrakali Uttarpara Municipality
                  </Typography>
                </Link>
                  
              </div>
            </div>
            <div className="col-sm-12 col-md-2">
              <div style={{padding: 25, marginTop: 30}}>
                <Typography variant="h6" style={{color: 'white'}} gutterBottom>
                  
                </Typography>
                <br />
                <Link to="restaurants" >
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    Rajchandrapur
                  </Typography>
                </Link>
                <Link to="restaurants" >
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    Baruapara
                  </Typography>
                </Link>
                <Link to="restaurants" >
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    Anandanagar
                  </Typography>
                </Link>
                <Link to="restaurants" >
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    Bhatta Nagar
                  </Typography>
                </Link>
                <Link to="restaurants" >
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    Belgachia
                  </Typography>
                </Link>
                <Link to="restaurants" >
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    Bally Ghat
                  </Typography>
                </Link>
                <Link to="restaurants" >
                  <Typography variant="body1" style={{color: 'white'}} gutterBottom>
                    Uttarpara Bazar
                  </Typography>
                </Link>
                  
              </div>
            </div>
          </div>

          <div className="row" style={{backgroundColor: '#46000a'}}>
            
            <div className="col-sm-12 col-md-12">
              <div className="border-top-grey">
                <Typography variant="caption" style={{marginRight: 18, float: 'right', color: 'white'}} gutterBottom>
                  © 2020 PureEats
                </Typography>
                <Link to="privacyPolicy" >
                  <Typography variant="body2" style={{marginRight: 18, float: 'right', color: 'white'}} gutterBottom>
                    Privacy
                  </Typography>
                </Link>
                <Link to="termsAndConditions" >
                  <Typography variant="body2" style={{marginRight: 18, float: 'right', color: 'white'}} gutterBottom>
                    Terms and conditions
                  </Typography>
                </Link>
              </div>
            </div>
          </div></>
    );
}


