import { BrowserRouter, Route, Switch } from "react-router-dom";

import React from "react";
import ReactDOM from "react-dom";
import { SnackbarProvider } from 'notistack';

//Desktop
import Home from "./screens/Desktop/Home/Landing";
import Restaurants from "./screens/Desktop/Restaurants/Restaurants";
import RestaurantDetail from "./screens/Desktop/Restaurants/RestaurantDetail";
import Search from "./screens/Desktop/Search";
import Offers from "./screens/Desktop/Offers";
import Help from "./screens/Desktop/Help";
import PrivacyPolicy from "./screens/Desktop/Help/PrivacyPolicy";
import CancelRefundPolicy from "./screens/Desktop/Help/CancelRefundPolicy";
import TermsAndConditions from "./screens/Desktop/Help/TermsAndConditions";
import Payments from "./screens/Desktop/Help/Payments";
import PaymentGateway from "./screens/Desktop/Help/PaymentGateway";
import TermsOfUse from "./screens/Desktop/Help/TermsOfUse";
import Account from "./screens/Desktop/Account/account";
import Cart from "./screens/Desktop/Cart/Cart";

//Mobile
import MLanding from "./screens/Mobile/Landing";
import MRestaurants from "./screens/Mobile/Restaurants/Restaurants";
import MSetLocation from "./screens/Mobile/Location/SetLocation";
import MLogin from "./screens/Mobile/Auth/Login";
import MSignup from "./screens/Mobile/Auth/Signup";
import MRestaurantDetail from "./screens/Mobile/Restaurants/RestaurantDetail";
import MCart from "./screens/Mobile/Cart/Cart";
import MCoupons from "./screens/Mobile/Cart/Coupons";
import MAccount from "./screens/Mobile/Account/Account";
import MyOrders from "./screens/Mobile/Account/MyOrders";
import MNotifications from "./screens/Mobile/Notifications/Notifications";
import MLegal from "./screens/Mobile/Account/Legal";
import MTermsAndConditions from "./screens/Mobile/Help/TermsAndConditions";
import MPrivacyPolicy from "./screens/Mobile/Help/PrivacyPolicy";
import MCancelRefundPolicy from "./screens/Mobile/Help/CancelRefundPolicy";
import MPayments from "./screens/Mobile/Help/Payments";
import MPaymentGateway from "./screens/Mobile/Help/PaymentGateway";
import MTermsOfUse from "./screens/Mobile/Help/TermsOfUse";
import MAboutUs from "./screens/Mobile/Help/AboutUs";
import MContactUs from "./screens/Mobile/Help/ContactUs";
import MFaq from "./screens/Mobile/Help/Faq";
import MCheckout from "./screens/Mobile/Cart/Checkout";
import MLiveTrack from "./screens/Mobile/Cart/LiveTrack";
import MManageAddress from "./screens/Mobile/Account/ManageAddress";
import MEditAccount from "./screens/Mobile/Account/EditAccount";
import MWallet from "./screens/Mobile/Account/Wallet";
import MOrderDetails from "./screens/Mobile/Account/OrderDetails";
import MRestaurantRatings from "./screens/Mobile/Restaurants/RestaurantRatings";


const ScrollToTop = () => {
	window.scrollTo(0, 0);
	return null;
};

ReactDOM.render(
	<BrowserRouter>
		<React.Fragment>
		    <SnackbarProvider anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
				<Route component={ScrollToTop} />

				{window.innerWidth <= 768 ? (
				    <Switch>
				    	{/* <Route exact strict  path="/:url*" render={props => <Redirect to={`${props.location.pathname}/`} />} /> */}
				    	<Route path={"/"} exact component={MLanding} />
				    	<Route path={"/restaurants"} exact component={MRestaurants} />
				    	<Route path={"/restaurantDetail/:resSlug"} exact component={MRestaurantDetail} />
				    	<Route path={"/setLocation"} exact component={MSetLocation} />
				    	<Route path={"/login"} exact component={MLogin} />
				    	<Route path={"/signup/:userMobile"} exact component={MSignup} />
				    	<Route path={"/cart"} exact component={MCart} />
				    	<Route path={"/coupons"} exact component={MCoupons} />
				    	<Route path={"/account"} exact component={MAccount} />
				    	<Route path={"/myOrders"} exact component={MyOrders} />
				    	<Route path={"/notifications"} exact component={MNotifications} />
				    	<Route path={"/legal"} exact component={MLegal} />
				    	<Route path={"/about"} exact component={MAboutUs} />
				    	<Route path={"/checkout"} exact component={MCheckout} />
				    	<Route path={"/liveTrack/:order_id"} exact component={MLiveTrack} />
				    	<Route path={"/manageAddress"} exact component={MManageAddress} />
				    	<Route path={"/editAccount"} exact component={MEditAccount} />
				    	<Route path={"/faq"} exact component={MFaq} />
				    	<Route path={"/wallet"} exact component={MWallet} />
				    	<Route path={"/orderDetails/:order_id"} exact component={MOrderDetails} />
				    	<Route path={"/restaurantRatings/:restaurant_id"} exact component={MRestaurantRatings} />				    	


				    	<Route path={"/help"} exact component={Help} />
				    	<Route path={"/privacyPolicy"} exact component={MPrivacyPolicy} />
				    	<Route path={"/cancelRefundPolicy"} exact component={MCancelRefundPolicy} />
				    	<Route path={"/termsAndConditions"} exact component={MTermsAndConditions} />
				    	<Route path={"/payments"} exact component={MPayments} />
				    	<Route path={"/paymentGateway"} exact component={MPaymentGateway} />
				    	<Route path={"/termsOfUse"} exact component={MTermsOfUse} />
				    	<Route path={"/contact"} exact component={MContactUs} />
				    </Switch>
				) : (
				    <Switch>
				    	{/* <Route exact strict  path="/:url*" render={props => <Redirect to={`${props.location.pathname}/`} />} /> */}
				    	<Route path={"/"} exact component={Home} />
				    	<Route path={"/restaurants"} exact component={Restaurants} />
				    	<Route path={"/search"} exact component={Search} />
				    	<Route path={"/offers"} exact component={Offers} />
				    	<Route path={"/help"} exact component={Help} />
				    	<Route path={"/privacyPolicy"} exact component={PrivacyPolicy} />
				    	<Route path={"/cancelRefundPolicy"} exact component={CancelRefundPolicy} />
				    	<Route path={"/termsAndConditions"} exact component={TermsAndConditions} />
				    	<Route path={"/payments"} exact component={Payments} />
				    	<Route path={"/paymentGateway"} exact component={PaymentGateway} />
				    	<Route path={"/termsOfUse"} exact component={TermsOfUse} />
				    	<Route path={"/restaurantDetail/:resSlug"} exact component={RestaurantDetail} />
				    	<Route path={"/account"} exact component={Account} />
				    	<Route path={"/cart"} exact component={Cart} />
				    </Switch>
				)}

		    </SnackbarProvider>
		</React.Fragment>
	</BrowserRouter>,
	document.getElementById("root")
);

