import React from "react";

import Typography from '@material-ui/core/Typography';
// import {ROOT_URL} from '../../../configs';
import { useHistory } from "react-router-dom";
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

export default function CancelRefundPolicy(params){

  const history = useHistory();

  return (
    <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <IconButton onClick={()=>history.goBack()} aria-label="back">
              <KeyboardBackspaceIcon style={{marginRight: 5}} />
            </IconButton>
            <Typography className="ml-2 mb-2" color="text-secondary" variant="h6">
            <b>Cancellation & Refund Policy</b>
            </Typography>
          </div>
        </div>

        
        <div className="row" style={{backgroundColor: '#dedede'}}>
          <div className="col-12">
          <br />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="mt-3 p-1 bg-white">
              <div style={{padding: 5, textAlign: 'justify'}}>
                <Typography variant="body1">
                Your food refund related request should be initiated within 4 hours of time of delivery, if not then
                you can visit our customer support on 18001238508 or email us at help@pureeats.in or
                alternatively chat support is also available.<br />
                Disclaimer: Please read the Refund & Cancellation Policy of our website.<br />
                You refund will be initiated for the following cases only.<br />
                1). Quality issues.<br />
                2). Didn't receive order.<br />
                3). Wrong food delivered.<br />
                4). Bad food quality.<br />
                5). Quantity was less.<br /><br />
                If you have any problem with this then you can fill your Complaint in the following ways: -
                For this you will have to upload the photos of your food within the 1 hour of your food delivery.
                If your complaint is found to be correct, it will be approved and your money will be refunded
                through your PURE EATS Wallet which can be used for next food delivery (Wallet validity is 30
                days from the day of delivery)<br /><br />
                

                If you have any problem in registering your complaint, then you can call pure eats customer
                care number directly and enter your complaint.<br />
                your Complaint will be resolve by Pure Eats customer service within 24 to 48 hours.
                If you placed any order through Pure Eats, then all the responsibility will be taken by Pure Eats
                company. To get your order delivered, we have delivery boys who will handover you your food.
                If you place any order through PURE EATS, you will receive a message from us. you can track
                your order through our website or application.<br />
                If your given address is incorrect or given mobile number is incorrect while ordering, you can
                update it.<br />
                But once your order is confirmed, it is not possible to refund the balance through our company.<br />
                At the time of delivery our delivery boy will call you and if you didn't pick it call, he will visit
                your address and he will wait you for 30 minutes after that your order will be automatically
                cancelled. After that you will not eligible for any refund.<br />
                (For cash on delivery order) If you placed any order with your account and unable to take that
                order, then your cash on delivery mode will be removed from your account.<br />
                Through our company we promise you to deliver food within 45 minutes.<br />
                But if the delivery boy has arrived within 500 m of your area, then this policy will not be applied
                again.<br />
                Delivery within 60 minutes, you can claim 10% of your amount by claiming it through Pure Eats
                Company.<br />
                (i) online payment method (card, net banking, UPI, etc)<br />
                (ii) Any payment wallets<br />
                (iii) Cash on delivery<br />
                (iv) Pay directly to your delivery person<br />
                l reflects in User’s account based on respective bank policies<br /><br />
                <b>Online payments</b><br />
                1. The User must acknowledge that any cancellation or attempted or purported cancellation of an
                Order shall amount to a breach of User’s unconditional and irrevocable authorization in favour of the
                Food Delivery Platform to place that Order against the Restaurant Partners on User’s behalf (“PURE
                EATS”).<br />
                2. In the event the User commits an Authorization Breach, the User shall be liable to pay the liquidated
                damages of an amount equivalent to the Order Value. The User must undertake to authorize Pure EATS
                to deduct or collect the amount payable as liquidating damages through such means as Food Delivery
                Platform may determine in its discretion, including without limitation, by deducting such amount from
                the payment made towards User’s next order.<br />
                3. The Pure Eats shall not facilitate replacements/ refunds/ or any other resolution without
                Restaurant’s Partner permission. All refunds shall be processed in the same manner as they are
                received, unless refunds have been provided to the User in the form of credits, the refund amount will
                Refund terms of condition for buyers.<br /><br />

                <b>Send the written communication to:-</b><br />
                For users residing in the India: -<br />
                Founded in 2020 by,<br />

                Flat no 307 SAPUIPARA, near Mota shiv Mandir ( Rakhi Factory)<br />
                Bally Howrah , Pin: -711227.<br />
                State: -West Bengal,<br />
                Email: - help@pureeats.in<br />
                Customer support number - 18001238508<br />

                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{backgroundColor: '#dedede'}}>
          <div className="col-12">
          <br />
          </div>
        </div>

    </div>
  );
}

