import React, {useState, useEffect} from "react";
 
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import StarIcon from '@material-ui/icons/Star';
// import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Axios from 'axios';
import {PLACE_ORDER_URL, ROOT_URL} from '../../../configs';
// import Skeleton from '@material-ui/lab/Skeleton';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
import Header from '../../../components/Layouts/Header';
// import { useParams } from "react-router-dom";
// import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxOutlined from '@material-ui/icons/CheckBoxOutlined';
// import FavouriteBorder from '@material-ui/icons/FavoriteBorder';
// import FavoriteOutlined from '@material-ui/icons/FavoriteOutlined';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import RemoveIcon from '@material-ui/icons/Remove';
// import Grow from '@material-ui/core/Grow';
// import TextField from '@material-ui/core/TextField';
import EmptyCart from '../../../components/Loading/EmptyCart'
import { useHistory } from "react-router-dom";
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
// import CloseIcon from '@material-ui/icons/Close';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CouponsDrawer from './Coupons/CouponsDrawer';
import AddressDrawer from './Address/AddressDrawer';

export default function Cart(params){

    // const { resSlug } = useParams();
    const history = useHistory();


    // const topPicks = useRef(null)

    const [loading, setLoading] = useState(true);
    const [restaurant, setRestaurant] = useState({})
    const [cart, setCart] = useState(null);
    const [user, setUSer] = useState(null);

    const [tip, setTip] = useState(0);
    const [coupon, setCoupon] = useState(null);
    const [deliveryCharge, setDeliveryCharge] = useState(0);
    const [outOfRange, setOutOfRange] = useState(false);
    const [deliveryTime, setDeliveryTime] = useState(undefined);

    const [tab, setTab] = useState(0);
    
    const handleTabChange = (event, newValue) => {
      setTab(newValue);
    };

    useEffect(()=>{
      if(localStorage.getItem('cart') !== null){
        setCart(JSON.parse(localStorage.getItem('cart')))
      }
      if(localStorage.getItem('user') !== null && localStorage.getItem('user') !== undefined){
        setUSer(JSON.parse(localStorage.getItem('user')))
      }
    },[])


    const addToCart=(item)=>{
      setCart([...cart, {...item, quantity: 1, cartPrice: item.price}])
    }

    // const removeFromCart=(item)=>{
    //   let newItems = cart.filter((i)=>i.id !== item.id)
    //   setCart([newItems])
    // }

    const incrementCart=(citem)=>{
      console.log(citem)
      let item = cart.filter((i)=>i.id === citem.id)[0]
      let newItems = cart.filter((i)=>i.id !== citem.id)
      item.cartPrice=parseInt(item.price)*(item.quantity)
      item.quantity=item.quantity+1;
      newItems.push(item)
      console.log(item)
      console.log(cart)
      setCart(newItems)
      localStorage.setItem('cart', JSON.stringify(newItems));
    }

    const decrementCart=(citem)=>{
      console.log(citem)
      let item = cart.filter((i)=>i.id === citem.id)[0]
      if (item.quantity === 1) {
        let newItems = cart.filter((i)=>i.id !== citem.id)
        setCart(newItems)
        localStorage.setItem('cart', JSON.stringify(newItems));
        if(newItems.length===0){
          localStorage.removeItem('cart');
        }
      }else{
        let newItems = cart.filter((i)=>i.id !== citem.id)
        item.quantity = item.quantity-1
        item.cartPrice=parseInt(item.price)*(item.quantity)
        newItems.push(item)
        setCart(newItems)
        localStorage.setItem('cart', JSON.stringify(newItems));
      }
    }

    function subTotal(){
      let total = 0;
      cart.map((i)=>total+=parseInt(i.cartPrice))
      // console.log(total)
      return total;
    }


    useEffect(()=>{
      let cartRestaurant = JSON.parse(localStorage.getItem('cartRestaurant'));
      let cartItems = JSON.parse(localStorage.getItem('cart'));
      console.log(cartRestaurant);
      setRestaurant(cartRestaurant);
      setCart(cartItems);

      let cp = localStorage.getItem('applyedCoupon');
      if (cp!==null) {
        setCoupon(JSON.parse(cp));
      }
    }, [])

    useEffect(()=>{
      if(cart!==null){
        calculateFee();
      }
    },[cart])
    
    const calculateDiscount=()=>{
      if(coupon === null) return 0;

      if(coupon.discount_type==="PERCENTAGE"){
        let t = subTotal();
        let dis = Math.round((t*parseInt(coupon.discount))/100);
        if (dis > parseInt(coupon.upto_amount)) {
          return parseInt(coupon.upto_amount);
        }
        return dis;
      }
      if(coupon.discount_type==="AMOUNT"){
        // let t = subTotal();
        let dis = parseInt(coupon.discount);
        if (dis > parseInt(coupon.upto_amount)) {
          return parseInt(coupon.upto_amount);
        }
        return dis;
      }
    }

    const totalPay=()=>{
      let subTotal1 = subTotal();
      return (subTotal1+(Math.round(subTotal1*0.02))+parseInt(deliveryCharge)+taxPay()+tip)-calculateDiscount();
    }

    const taxPay=()=>{
      let subTotal1 = subTotal();
      return parseInt((subTotal1+(subTotal1*0.02))*0.02)
    }

    function calculateFee(){
      let cartRestaurant = JSON.parse(localStorage.getItem('cartRestaurant'));

      console.log(cartRestaurant)
      let baseCharge = parseInt(cartRestaurant.base_delivery_charge)

      if(cartRestaurant.delivery_charge_type === 'DYNAMIC'){
        var target_url = `https://admin.pureeats.in/googleDirectionCall?olat=${localStorage.getItem("userLat")}&olng=${localStorage.getItem("userLng")}&dlat=${cartRestaurant.latitude}&dlng=${cartRestaurant.longitude}`;
        Axios.get(`${target_url}`)
        .then(res=>{
          if(res.data.rows[0].elements[0].distance === undefined){
            setOutOfRange(true);
            return;
          }

          if((res.data.rows[0].elements[0].distance.value/1000) > parseInt(cartRestaurant.delivery_radius)){
            setOutOfRange(true);
            return;
          }

          setDeliveryTime(res.data.rows[0].elements[0].distance.value);

          if ((cartRestaurant.base_delivery_distance*1000) < res.data.rows[0].elements[0].distance.value) {
            let extra = ((res.data.rows[0].elements[0].distance.value - cartRestaurant.base_delivery_distance*1000))/1000;
            

            let finalCharge = baseCharge + (parseInt(cartRestaurant.extra_delivery_charge) * extra);
            console.log(baseCharge)
            console.log(extra)
            console.log(finalCharge)
            baseCharge = finalCharge;
            setDeliveryCharge(Math.round(baseCharge))
          }else{
            setDeliveryCharge(Math.round(baseCharge))
          }

          setLoading(false);
        })
      }else{
        setLoading(false);
        setDeliveryCharge(Math.round(baseCharge))
      }
    }


    const payOnline=async(e)=>{
        // const API_URL = 'https://admin.pureeats.in/'
        // // const orderUrl = `${API_URL}order`;
        // // const response = await Axios.get(orderUrl);
        // // const { data } = response;
        // const options = {
        //   key: 'rzp_live_hoLBNo6OWowo4H',
        //   name: "PureEats",
        //   description: "Place order",
        //   order_id: 'test',
        //   handler: async (response) => {
        //     try {
        //      const paymentId = response.razorpay_payment_id;
        //      const url = `${API_URL}capture/${paymentId}`;
        //      const captureResponse = await Axios.post(url, {})
        //      console.log(captureResponse.data);
        //     } catch (err) {
        //       console.log(err);
        //     }
        //   },
        //   theme: {
        //     color: "#686CFD",
        //   },
        // };
        // const rzp1 = new window.Razorpay(options);
        // rzp1.open();
      let options = {
        "key": "rzp_live_hoLBNo6OWowo4H",
        "amount": totalPay()*100, // 2000 paise = INR 20, amount in paisa
        "name": "PureEats",
        "description": "Pay for your order.",
        "image": "/assets/img/logos/default.png",
        "handler": function (response){
          alert(response.razorpay_payment_id);
        },
        "prefill": {
          "name": JSON.parse(localStorage.getItem('user')).name,
          "email": JSON.parse(localStorage.getItem('user')).email
        },
        "notes": {
          "address": localStorage.getItem('address')
        },
        "theme": {
          "color": "#f90e30"
        }
      };

      let rzp = new window.Razorpay(options);
      rzp.open();
    }

    const placeOrder = (method) => {
      setLoading(true);
      if(user===null) return;

        Axios.post(PLACE_ORDER_URL, {
            token: user.auth_token,
            user: {delivery_details: null, running_order: null, success: true, data: user},
            order: cart,
            coupon: ((localStorage.getItem("applyedCoupon") !== null)?{code:JSON.parse(localStorage.getItem("applyedCoupon")).code}:''),
            location: {address:localStorage.getItem("address"), lat: user.default_address.latitude, lng: user.default_address.longitude, house: '', tag: null},
            order_comment: '',
            total: totalPay(),
            method: 'COD',
            payment_token: '',
            delivery_type: 1,
            partial_wallet: 0
        })
        .then(response => {
            const checkout = response.data;

            if (checkout.success) {
                // dispatch({ type: PLACE_ORDER, payload: checkout });

                // const state = getState();
                // console.log(state);
                // const cartProducts = state.cart.products;
                // const user = state.user.user;
                localStorage.removeItem("orderComment");
                localStorage.removeItem("cart");
                localStorage.removeItem("cartRestaurant");

                history.push('/account');
            }

            setLoading(false);
        })
        .catch(function(error) {
            setLoading(false);
            console.log(error);
        });
    };


    return (
        <div className="container-fluid p-0">

          <Header />

          {cart===null && (
          <div className="row">
            <div className="col-md-12">
              <div className="p-3 text-center">
                <EmptyCart />
                <Typography variant="h6" className="mt-3">Your cart is empty.</Typography>
                <Typography variant="body2">You can go to home page to view more restaurants.</Typography>
                <Button onClick={()=>history.push('/restaurants')} style={{backgroundColor: 'green', color: 'white', borderRadius: 0, marginTop: 15}} variant="contained">SEE RESTAURANTS NEAR YOU</Button>
              </div>
            </div>
          </div>
          )}

          {cart!==null && (
          <div className="row bg-app">
            <div className="col-md-8">
              <div className="row">
                <div className="col-12">
                  <div style={{marginLeft: '4rem'}} className="p-3 mt-4 bg-white">
                    <Typography style={{fontWeight: 'bold', fontSize:28}} className="mb-3" variant="h6">
                      Delivery address
                    </Typography>

                    <div style={{
                      position: 'absolute',
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#282c3f',
                      boxShadow: '0 3px 5px 0 rgba(40,44,63,.4)',
                      top: '35px',
                      left: '38px',
                      zIndex: 99,
                      padding: 9,
                    }}>
                      <RoomOutlinedIcon style={{color: 'white'}} />
                    </div>

                    <div style={{
                        position: 'absolute',
                        borderLeft: '1px dashed #282c3f',
                        top: 55,
                        left: 56,
                        bottom: '-67px'}}></div>

                    <div style={{
                      position: 'absolute',
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#282c3f',
                      boxShadow: '0 3px 5px 0 rgba(40,44,63,.4)',
                      top: '185px',
                      zIndex: 99,
                      left: '38px',
                      padding: 9,
                    }}>
                      <AccountBalanceWalletOutlinedIcon style={{color: 'white'}} />
                    </div>


                    <div className="mt-2">
                      <Typography className="" variant="body1">
                        {(user)?user.default_address.address:localStorage.getItem('address')}
                      </Typography>

                      {deliveryTime && (
                        <Typography className="text-success" variant="caption">
                          Delivery in {Math.round(20+(deliveryTime/60))} mins
                        </Typography>
                      )}


                      <AddressDrawer />

                    </div>

                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div style={{marginLeft: '4rem'}} className="p-3 mt-4 bg-white">
                    <Typography style={{fontWeight: 'bold', fontSize:28}} className={user?"mb-3":"mb-3 text-muted"} variant="h6">
                      Choose payment method
                    </Typography>

                    {user===null && (
                    <Typography className="text-muted" variant="caption">
                      Login / Signup to continue
                    </Typography>
                    )}

                    {user!==null && (
                    <div className="row">
                      <div className="col-md-2 p-0" style={{backgroundColor: 'rgb(226 226 226)'}}>
                        <Tabs
                          value={tab}
                          orientation="vertical"
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          onChange={handleTabChange}
                          aria-label="Help"
                          style={{marginTop: 20}}
                        >
                          <Tab label="Online" />
                          <Tab label="Wallet" />
                          <Tab label="COD" />
                        </Tabs>
                        </div>
                        <div className="col-md-10" style={{backgroundColor: '#fff', padding: 40, minHeight: 540}}>
                          

                          {tab===0 && (
                            <div
                              Style={{padding: 20}}
                              role="tabpanel"
                              id="vertical-tabpanel-0"
                              aria-labelledby="vertical-tabpanel-0"
                            >

                              <div className="row" style={{marginBottom: 75}}>
                                  <div className="col-3 text-center">
                                    <img alt="..." src="/assets/img/android/razorpay.png" className="img-fluid" style={{width: 54}} />
                                  </div>
                                  <div className="col-9">
                                    <Typography variant="caption"><b>PAY ONLINE</b></Typography>
                                    <Typography variant="body2">Razorpay</Typography>
                                    <Typography variant="body2"></Typography>
                                    <Button disabled={(loading || outOfRange)} onClick={()=>payOnline()} variant="contained" color="primary" style={{width: '100%', height: 40, color: 'white', marginTop: 10}}>{(loading)?'Please Wait...':outOfRange?'Not servicable in selected address':'PLACE ORDER'}</Button>
                                  </div>
                              </div>

                            </div>
                          )}

                          {tab===1 && (
                            <div
                              Style={{padding: 20}}
                              role="tabpanel"
                              id="vertical-tabpanel-0"
                              aria-labelledby="vertical-tabpanel-0"
                            >

                              <div className="row" style={{marginBottom: 75}}>
                                  <div className="col-3 text-center">
                                    <img alt="..." src="/assets/img/various/wallet.png" className="img-fluid" style={{width: 54}} />
                                  </div>
                                  <div className="col-9">
                                    <Typography variant="caption"><b>Wallet</b></Typography>
                                    <Typography variant="body2">Your Available Balance: ₹ {(user)?user.wallet_balance:0}</Typography>
                                    <Typography variant="body2"></Typography>
                                    <Button disabled={(loading || outOfRange)} onClick={()=>null} variant="contained" color="primary" style={{width: '100%', height: 40, color: 'white', marginTop: 10}}>{(loading)?'Please Wait...':outOfRange?'Not servicable in selected address':'PLACE ORDER'}</Button>
                                  </div>
                              </div>

                            </div>
                          )}

                          {tab===2 && (
                            <div
                              Style={{padding: 20}}
                              role="tabpanel"
                              id="vertical-tabpanel-0"
                              aria-labelledby="vertical-tabpanel-0"
                            >

                              <div className="row" style={{marginBottom: 75}}>
                                  <div className="col-3 text-center">
                                    <img alt='imagehere' src="/assets/img/android/rupees.png" className="img-fluid" style={{width: 54}} />
                                  </div>
                                  <div className="col-9">
                                    <Typography variant="caption"><b>COD</b></Typography>
                                    <Typography variant="body2">Cash on Delivery is not available</Typography>
                                    <Typography variant="body2">Please pay using online payment method</Typography>
                                    {(!loading && false) && (
                                      <Button disabled={(loading || outOfRange)} onClick={()=>placeOrder('COD')} variant="contained" color="primary" style={{width: '100%', height: 40, color: 'white', marginTop: 10}}>{(loading)?'Please Wait...':outOfRange?'Not servicable in selected address':'PLACE ORDER'}</Button>
                                    )}
                                  </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="p-3 m-3 bg-white">
                <div className="row m-1" style={{borderBottom: '1px solid #ababab'}}>
                    <div className="col-sm-12">
                      <div className="row mb-3">
                        <div className="col-4">
                          <img style={{width: 94}} alt="logo" src={ROOT_URL+restaurant.image} />
                        </div>

                        <div className="col-8 col-md-8 p-0">
                          <div className="p-2">
                            <Typography variant="body2">
                              <b>({restaurant.name})</b>
                            </Typography>
                            <Typography className="text-danger" variant="caption">
                              {restaurant.address}
                            </Typography>

                            
                          </div>
                        </div>
                      </div>
                    </div>
                </div>

                <div className="row">
                  <div className="col-12" style={{maxHeight: 320, overflow: 'scroll'}}>
                    <div className="row dashed-bottom">
                        {cart.map((i)=>{
                          return (<div key={i.id} className="col-12">
                              <div className="p-3">
                                  <div className="row">
                                    <div className="col-8 p-0">
                                      <CardActionArea>
                                      <CardContent className="p-1">
                                        <Typography gutterBottom variant="body1" component="h2">
                                          <b>{i.name}</b>
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                          ₹ {i.price}
                                        </Typography>
                                      </CardContent>
                                      </CardActionArea>
                                    </div>
                                    <div className="col-4">
                                      {cart.length === 0 && (
                                        <Button onClick={()=>addToCart(i)} style={{
                                          width: 70,
                                          marginLeft: 'auto',
                                          position: 'absolute',
                                          bottom: 5,
                                          left: '24%',
                                          background: 'white',
                                          color: 'green',
                                          borderRadius: 0,
                                        }} variant="contained">ADD</Button>
                                      )}
                                      {cart.filter(a => a.id !== i.id).length !== 0 && (
                                        <Button onClick={()=>addToCart(i)} style={{
                                          width: 70,
                                          marginLeft: 'auto',
                                          position: 'absolute',
                                          bottom: 5,
                                          left: '24%',
                                          background: 'white',
                                          color: 'green',
                                          borderRadius: 0,
                                        }} variant="contained">ADD</Button>
                                      )}

                                      {cart.some(a => a.id === i.id) && (
                                      <div className="MuiButton-contained" style={{
                                        width: 70,
                                        marginLeft: 'auto',
                                        position: 'absolute',
                                        bottom: 5,
                                        left: '24%',
                                        background: 'white',
                                        color: 'green',
                                        borderRadius: 0,
                                        padding: 3,
                                      }}>
                                        <IconButton onClick={()=>incrementCart(i)} style={{float: 'right'}} size="small" aria-label="plus">
                                          <AddIcon fontSize="small" />
                                        </IconButton>
                                        <p style={{paddingLeft: 7,marginTop: 3,display: 'inline-block',marginBottom: 0}}>{cart.filter(a=>a.id===i.id)[0].quantity}</p>
                                        <IconButton onClick={()=>decrementCart(i)} style={{float: 'left'}} size="small" aria-label="delete">
                                          <RemoveIcon fontSize="small" />
                                        </IconButton>
                                      </div>
                                      )}
                                    </div>
                                  </div>
                              </div>
                          </div>)
                      })}
                    </div>

                    <div className="row" style={{borderBottom: '1px solid', backgroundColor: '#fff'}}>
                        <div className="col-sm-12">
                          <div className="row p-3">
                            <div className="col-10 p-0 ">
                              <div className="p-2">
                                <Typography variant="body1" className="text-muted">
                                  <b>SUPPORT YOUR RIDER</b>
                                </Typography>
                                <Typography className="text-muted" variant="caption">
                                  Our riders are risking their lives to serve the nation.
                                  While we're doing our best to support them, we'd
                                  request you to tip them generously in this difficult
                                  time, if you can aford it
                                </Typography><br />
                              </div>
                            </div>
                            
                            <div className="col-2 p-0 text-center">
                              {tip>0 && (
                              <><Typography className="mt-4 pt-3 text-muted" variant="h6">
                                <b>₹ {tip}</b>
                              </Typography>
                              <Typography onClick={()=>setTip(0)} className="text-danger text-center" variant="body2">
                                Clear
                              </Typography></>
                              )}
                            </div>

                            <div className="row col-12 mt-4">
                              <div className="col-4">
                                <Button onClick={()=>setTip(tip+5)} className="bg-primary text-white" style={{borderRadius: 8}}>
                                <Typography variant="body1"><b>+ ₹ 5</b></Typography>
                                </Button>
                              </div>
                              <div className="col-4">
                                <Button onClick={()=>setTip(tip+10)} className="bg-primary text-white" style={{borderRadius: 8}}>
                                <Typography variant="body1"><b>+ ₹ 10</b></Typography>
                                </Button>
                              </div>
                              <div className="col-4">
                                <Button onClick={()=>setTip(tip+20)} className="bg-primary text-white" style={{borderRadius: 8}}>
                                <Typography variant="body1"><b>+ ₹ 20</b></Typography>
                                </Button>
                              </div>
                            </div>

                          </div>
                        </div>
                    </div>

                    <CouponsDrawer coupon={coupon} />

                    <div className="row m-1">
                        <div className="col-12 p-3 mt-2 mb-2">
                          <Typography className="text-danger" variant="body2">
                            Orders once placed cannot be cancelled after 2 minutes of 
                            booking and are non-refundable
                          </Typography>
                        </div>
                    </div>

                    <div className="row m-1">
                        <div className="col-12">
                          <Typography variant="body1"><b>BILL DETAILD</b></Typography>
                          <div className="row">
                            <div className="col-8">
                              <Typography className="p-2" variant="body2">Item Total</Typography>
                            </div>
                            <div className="col-4">
                              <Typography className="p-2 float-right" variant="body2">₹ {String(subTotal()).replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")}</Typography>
                            </div>

                            <div className="col-8">
                              <Typography className="p-2" variant="body2">Restaurant Charges (2%)</Typography>
                            </div>
                            <div className="col-4">
                              <Typography className="p-2 float-right" variant="body2">₹ {Math.round(subTotal()*0.02)}</Typography>
                            </div>

                            <div className="col-8">
                              <Typography className="p-2 text-success" variant="body2">Total Discount</Typography>
                            </div>
                            <div className="col-4">
                              <Typography className="p-2 text-success float-right" variant="body2">- ₹ {calculateDiscount()}</Typography>
                            </div>

                            {coupon!==null && (
                              <div className="col-12">
                                <div className="row" style={{border:'1px solid #00ab05', padding: 5, textAlign:'center', backgroundColor: '#e4ffe8'}}>
                                  <div className="col-8">
                                    <Typography style={{color: '#00ab05'}} className="p-2" variant="h5"><b>Your total saving</b></Typography>
                                  </div>
                                  <div className="col-4">
                                    <Typography style={{color: '#00ab05'}} className="p-2 float-right" variant="h5"><b> ₹ {calculateDiscount()}</b></Typography>
                                  </div>
                                </div>
                              </div>
                            )}

                            <div className="col-8" style={{...Styles.bts, ...Styles.bbs}}>
                              <Typography className="p-2" variant="body2">Delivery Fee</Typography>
                            </div>
                            <div className="col-4" style={{...Styles.bts, ...Styles.bbs}}>
                              <Typography className="p-2 float-right" variant="body2">₹ {String(deliveryCharge)}</Typography>
                            </div>

                            <div className="col-8" style={Styles.bbs}>
                              <Typography className="p-2 text-danger" variant="body2">TAX (2%)</Typography>
                            </div>
                            <div className="col-4" style={Styles.bbs}>
                              <Typography className="p-2 text-danger float-right" variant="body2">₹ {taxPay()}</Typography>
                            </div>

                            {tip > 0 && (
                            <><div className="col-8" style={Styles.bbs}>
                              <Typography className="p-2 text-primary" variant="body2">Tip</Typography>
                            </div>
                            <div className="col-4" style={Styles.bbs}>
                              <Typography className="p-2 text-primary float-right" variant="body2">₹ {tip}</Typography>
                            </div></>
                            )}

                          </div>
                        </div>
                    </div>

                  </div> 

                  <div className="col-12" style={{borderTop: 'solid black'}}>
                    <div className="row bg-white">
                      <div className="col-8 mb-4">
                        <Typography className="p-2" variant="h6"><b>To Pay</b></Typography>
                      </div>
                      <div className="col-4">
                        <Typography className="p-2 float-right" variant="h6"><b>₹ {totalPay()}</b></Typography>
                      </div>
                    </div>
                  </div>

                </div>


              </div>
            </div>
          </div>
          )}

        </div>
    );
}

const Styles = {
  vegIcon: {
    width: '16px',
    height: '16px',
    position: 'absolute',
    left: '10px',
    top: '10px',
    background: 'white',
  },
  bts:{
    borderTop: '1px solid #ababab',
  },
  btd:{
    borderTop: '1px dashed',
  },
  bbs:{
    borderBottom: '1px solid #ababab',
  },
  bbd:{
    borderBottom: '1px dashed',
  },
}

