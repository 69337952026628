import React, {useState, useEffect} from "react";

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import {ROOT_URL} from '../../../configs';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {ALL_COUPONS_URL} from '../../../configs';
import Axios from 'axios';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSnackbar } from 'notistack';

export default function Coupons(params){

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
      let resturant = JSON.parse(localStorage.getItem("cartRestaurant"));

      Axios.post(ALL_COUPONS_URL, {restaurant_id: resturant.id})
      .then(res=>{
          console.log(res.data)
          setCoupons(res.data)
          setLoading(false)
      })
      .catch((e)=>console.log(e))
  }, []);

  const applyCoupon=(item)=>{
    let total = 0;
    JSON.parse(localStorage.getItem('cart')).map(i=>total+=parseInt(i.price * i.quantity));

    if (total < Number(item.min_order_amount)) {
      enqueueSnackbar('Coupon applicable on minimum order of '+item.min_order_amount, {variant: 'error'});
      return;
    }

    localStorage.setItem('applyedCoupon', JSON.stringify(item));
    enqueueSnackbar('Coupon applied successfully! ', {variant: 'success'});
    history.goBack()
  }

  return (
    <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <IconButton onClick={()=>history.goBack()} aria-label="back">
              <KeyboardBackspaceIcon style={{marginRight: 5}} />
            </IconButton>
          </div>
        </div>

        {loading && (
        <div className="row">
            {[...Array(4)].map((i)=>{
                return <div className="col-12">
                    <div className="mb-3">
                      <Skeleton variant="rect" height={70} />
                      <Skeleton variant="text" style={{marginTop: 5}} />
                      <Skeleton variant="text" width={100} />
                      <Skeleton variant="text" width={50} />
                    </div>
                </div>
            })}
        </div>
        )}

        <div className="row">
          {coupons.map((i=>{
            return <div className="col-12">
              <Card gutterBottom className="mb-4">
                <CardContent>
                  <Typography variant="body1" color="textSecondary" gutterBottom>
                    <b>{i.name}</b>
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {i.description}
                  </Typography>
                </CardContent>
                <div className="pl-3 pb-3 pr-3">
                  <Button variant="contained" disabled >{i.code}</Button>
                  <Button onClick={()=>applyCoupon(i)} style={{background: 'forestgreen',color: 'white'}} className="float-right" variant="contained" >Apply</Button>
                </div>
              </Card>
            </div>
          }))}
        </div>

    </div>
  );
}

