import React, {useState, useEffect} from "react";

// import { connect } from "react-redux";
import Header from '../../../components/Layouts/Header';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import { useHistory } from "react-router-dom";

import MyOrders from './Orders/MyOrders';
import MyAddress from './Address/MyAddress';
import MyWallet from './Wallet/MyWallet';

export default function Account(){

    // const history = useHistory();

    const [tab, setTab] = useState(0);
    const [account, setAccount] = useState({});

    useEffect(()=>{
      setAccount(JSON.parse(localStorage.getItem('user')));
    },[])

    const handleTabChange = (event, newValue) => {
      setTab(newValue);
    };

    return (
        <div className="container-fluid p-0">

          <Header />
            <div className="row" style={{padding: '40px 50px', backgroundColor: '#eb4b60', color: 'white'}}>
              <div className="col-md-12">
                <h3 style={{color: 'white', marginBottom: 5}}>{account.name}</h3>
                <h6 style={{color: 'white',marginBottom: 0}}>{account.phone} . {account.email}</h6>
              </div>
            </div>

            <div className="row" style={{padding: 50, backgroundColor: '#eb4b60', color: 'white', paddingTop: 0}}>
                
                <div className="col-md-2 p-0" style={{backgroundColor: '#d3eaff'}}>
                  <Tabs
                    value={tab}
                    orientation="vertical"
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    onChange={handleTabChange}
                    aria-label="Help"
                    style={{marginTop: 20}}
                  >
                    <Tab label="Orders" />
                    <Tab label="Address" />
                    <Tab label="Wallet" />
                    <Tab label="Share" />
                  </Tabs>
                  </div>
                  <div className="col-md-10" style={{backgroundColor: 'rgb(245,245,245)', padding: 40, minHeight: 540}}>
                    

                    {tab===0 && (
                      <div
                        Style={{padding: 20}}
                        role="tabpanel"
                        id="vertical-tabpanel-0"
                        aria-labelledby="vertical-tabpanel-0"
                      >
                        <h3>My Orders</h3>
                        <MyOrders />
                      </div>
                    )}

                    {tab===1 && (
                      <div
                        Style={{padding: 20}}
                        role="tabpanel"
                        id="vertical-tabpanel-0"
                        aria-labelledby="vertical-tabpanel-0"
                      >
                        <h3>My Addresses</h3>
                        <MyAddress />
                      </div>
                    )}

                    {tab===2 && (
                      <div
                        Style={{padding: 20}}
                        role="tabpanel"
                        id="vertical-tabpanel-0"
                        aria-labelledby="vertical-tabpanel-0"
                      >
                        <h3>My Wallet</h3>
                        <MyWallet />
                      </div>
                    )}

                </div>
            </div>
        </div>
    );
}


